import {
  CompiereDataGridFilterModel,
  CompiereDataGridGroupModel,
  CompiereDataGridRequestJSON
} from '@compiere-ws/models/compiere-data-json';
import { ViewType } from '@iupics-components/models/view-type.enum';
import { Global } from '@iupics-manager/models/global-var';
import { TabUiComponent } from '@web-desktop/components/menu-top/components/tab-ui/tab-ui.component';
import { IupicsMenuType } from '@web-desktop/models/menu-item-ui';
import { SortModelItem } from 'ag-grid-community';
import * as Big from 'big.js';

export class Utils {
  constructor() {}

  static getBigDecimal(obj: any): any {
    return new Big(obj);
  }

  static getDate(): any {
    return new Date();
  }
  /**
   * @description it allows to change url according to the type of tab
   * @param activeTab active tab
   * @param viewType type of view
   * @param paramsMap process param
   * @param recordId id of the opened record
   */
  static onTabChange(
    activeTab?: any,
    viewType?: ViewType,
    paramsMap?: Map<string, any>,
    dataGridRequest?: CompiereDataGridRequestJSON,
    edits?: { tabId: string; recordId: string }[]
  ) {
    const baseHref = document.getElementsByTagName('base')[0].href;
    if (activeTab instanceof TabUiComponent) {
      // params of url
      let params = '';
      if (viewType) {
        params += '?viewType=' + viewType;
      }
      let isFirst = true;
      // build params of window
      if (edits && edits.length > 1) {
        if (params === '') {
          params = '?';
        } else {
          params += '&';
        }
        // on ajoute les données d'ouverture des editviews chargées à l'écran
        params += `path=`;
        edits.forEach((edit) => {
          if (!isFirst) {
            params += '/' + edit.tabId + '/' + edit.recordId;
          } else {
            isFirst = false;
          }
        });
      }
      // on ajoute les données de filtre
      if (dataGridRequest) {
        if (dataGridRequest.filterModel) {
          params = this.setFilterModelParams(dataGridRequest.filterModel, params);
        }
        if (dataGridRequest.rowGroupCols) {
          params = this.setRowGroupColsParams(dataGridRequest.rowGroupCols, params);
        }
        if (dataGridRequest.sortModel) {
          params = this.setSortModelParams(dataGridRequest.sortModel, params);
        }
        if (dataGridRequest.valueCols) {
          params = this.setValueColsParams(dataGridRequest.valueCols, params);
        }
        if (dataGridRequest.pivotCols) {
          params = this.setPivotColsParams(dataGridRequest.pivotCols, params);
        }
        if (dataGridRequest.pivotMode) {
          params += '&pivotMode=' + dataGridRequest.pivotMode;
        }
      }
      if (
        (activeTab.menu_type === IupicsMenuType.PROCESS || activeTab.menu_type === IupicsMenuType.REPORT) &&
        paramsMap &&
        paramsMap.size > 0
      ) {
        // build params of process
        isFirst = true;
        paramsMap.forEach((value, label) => {
          if (value) {
            if (isFirst) {
              params = '?';
              isFirst = false;
            } else {
              params += '&';
            }
            params += `${label}=${value.id !== undefined ? value.id : encodeURIComponent(value)}`;
          }
        });
      }
      if (edits && edits.length > 0) {
        window.history.pushState(
          {},
          '',
          `${baseHref}workspace/${activeTab.menu_type.toLowerCase()}/${activeTab.action_id}/${edits[0].recordId}${params}`
        );
      } else {
        window.history.pushState(
          {},
          '',
          `${baseHref}workspace/${activeTab.menu_type.toLowerCase()}/${activeTab.action_id}${params}`
        );
      }
    } else {
      window.history.pushState({}, '', `${baseHref}dashboard`);
    }
  }
  static setFilterModelParams(filterModel: { [columnName: string]: CompiereDataGridFilterModel }, params: string): string {
    let i = 0;
    Object.keys(filterModel).forEach((key) => {
      params += '&column_' + i + '=' + key;
      params += '&filterOperator_' + i + '=';
      let filterOperators = '';
      filterModel[key].operators.forEach((operator) => {
        if (filterOperators !== '') {
          filterOperators += '|';
        }
        filterOperators += operator;
      });
      params += filterOperators;

      params += '&filterValue_' + i + '=';
      let filterValues = '';
      filterModel[key].values.forEach((value) => {
        if (filterValues !== '') {
          filterValues += '|';
        }
        filterValues += encodeURIComponent(value);
      });
      params += filterValues;

      params += '&filterConfigs_' + i + '=';
      let filterConfigs = '';
      filterModel[key].configs.forEach((config) => {
        if (filterConfigs !== '') {
          filterConfigs += '|';
        }
        filterConfigs += encodeURIComponent(JSON.stringify(config));
      });
      params += filterConfigs;

      params += '&filterType_' + i + '=';
      const filterType = filterModel[key].filterType;
      params += filterType;
      i++;
    });
    return params;
  }
  static setRowGroupColsParams(rowGroupCols: CompiereDataGridGroupModel[], params: string): string {
    let i = 0;
    rowGroupCols.forEach((rowGroupCol) => {
      params += '&groupAggFunc_' + i + '=' + rowGroupCol.aggFunc;
      params += '&groupDisplayName_' + i + '=' + rowGroupCol.displayName;
      params += '&groupField_' + i + '=' + rowGroupCol.field;
      params += '&groupId_' + i + '=' + rowGroupCol.id;
      i++;
    });
    return params;
  }
  static setSortModelParams(sortModel: SortModelItem[], params: string): string {
    let i = 0;
    sortModel.forEach((sort) => {
      params += '&sortColId_' + i + '=' + sort.colId;
      params += '&sortType_' + i + '=' + sort.sort;
      i++;
    });
    return params;
  }
  static setValueColsParams(valueCols: CompiereDataGridGroupModel[], params: string): string {
    let i = 0;
    valueCols.forEach((valueCol) => {
      params += '&valueAggFunc_' + i + '=' + valueCol.aggFunc;
      params += '&valueDisplayName_' + i + '=' + valueCol.displayName;
      params += '&valueField_' + i + '=' + valueCol.field;
      params += '&valueId_' + i + '=' + valueCol.id;
      i++;
    });
    return params;
  }
  static setPivotColsParams(pivotCols: CompiereDataGridGroupModel[], params: string): string {
    let i = 0;
    pivotCols.forEach((pivotCol) => {
      params += '&pivotDisplayName_' + i + '=' + pivotCol.displayName;
      params += '&pivotField_' + i + '=' + pivotCol.field;
      params += '&pivotId_' + i + '=' + pivotCol.id;
      i++;
    });
    return params;
  }
  static formatUrl(url: string): string {
    return url.replace(/<>/g, '!=');
  }

  /**
   * Remplace les caractères avec accents par leur équivalents sans accent
   * @param {string}str
   */
  static cleanUpSpecialChars(str: string, forRegex: boolean = false): string {
    return forRegex
      ? str
          .toLowerCase()
          .replace(/[àáâãäå]/g, 'a')
          .replace(/[éèêë]/g, 'e')
          .replace(/[ìíîï]/g, 'i')
          .replace(/[òóôöõ]/g, 'o')
          .replace(/[ùúûü]/g, 'u')
          .replace(/[ýÿ]/g, 'y')
          .replace(/[^a-z0-9°'] /gi, '')
          .replace(/\+/g, '\\+')
          .replace(/\*/g, '\\*')
          .replace(/\(/g, '\\(')
          .replace(/\)/g, '\\)')
          .replace(/\[/g, '\\[')
          .replace(/\]/g, '\\]')
          .replace(/%/g, '*')
      : str
          .toLowerCase()
          .replace(/[àáâãäå]/g, 'a')
          .replace(/[éèêë]/g, 'e')
          .replace(/[ìíîï]/g, 'i')
          .replace(/[òóôöõ]/g, 'o')
          .replace(/[ùúûü]/g, 'u')
          .replace(/[ýÿ]/g, 'y')
          .replace(/[^a-z0-9°'] /gi, '');
  }

  static displayLoadingScreen() {
    if (document.querySelector('.indexLoaderMsg')) {
      (document.querySelector('.indexLoaderMsg') as HTMLElement).style.display = 'block';
    }
    if (document.querySelector('.indexLoader')) {
      (document.querySelector('.indexLoader') as HTMLElement).style.display = 'block';
    }
    setTimeout(() => {
      if( !Global.callTokenActive ){
        if (document.getElementById('loadingIndexTooLong')) document.getElementById('loadingIndexTooLong').style.display = 'block';
        if (document.getElementById('loadingTooLong')) document.getElementById('loadingTooLong').style.display = 'block';
        if (document.getElementById('failtogettoken')) document.getElementById('failtogettoken').style.display = 'none';
        if (document.getElementById('btnReloadIndex'))
          document.getElementById('btnReloadIndex').addEventListener('click', () => {
            localStorage.clear();
            window.location.reload();
          });
      }
    }, 10000);
  }

  static hideLoadingScreen() {
    if (document.querySelector('.indexLoaderMsg')) {
      (document.querySelector('.indexLoaderMsg') as HTMLElement).style.display = 'none';
    }
    if (document.querySelector('.indexLoader')) {
      (document.querySelector('.indexLoader') as HTMLElement).style.display = 'none';
    }
  }
}
