import { Injectable } from '@angular/core';
import { CompiereProcess } from '@compiere-ws/models/compiere-process-json';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root'
})
export class CompiereProcessService {
  private url: string;
  private executionUrl: string;

  constructor(private http: ApiService) {}

  getProcess(processId: any): Observable<CompiereProcess> {
    if (this.url === undefined) {
      this.url = environment.config.backend.ws.url + environment.config.backend.ws.paths['process'];
    }
    return this.http.get<CompiereProcess>(`${this.url}/${processId}`);
  }

  executeProcess(params: any): Observable<any> {
    if (this.executionUrl === undefined) {
      this.executionUrl = environment.config.backend.ws.url + environment.config.backend.ws.paths['printReport'];
    }
    return this.http.post<any>(`${this.executionUrl}`, params);
  }
}
