import { formatNumber } from '@angular/common';
import { AfterViewInit, Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { ChartType } from '@iupics-components/models/view-type.enum';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { Global } from '@iupics-manager/models/global-var';
import { TranslateService } from '@ngx-translate/core';
import { ThemeService } from '@web-desktop/controllers/theme.service';
import { IupicsWidget } from '@web-desktop/models/iupics-widget';
import { UIChart } from 'primeng/chart';

@Component({
  selector: 'iu-chart-widget',
  templateUrl: './chart-widget.component.html',
  styleUrls: ['./chart-widget.component.scss']
})
export class ChartWidgetComponent implements OnInit, AfterViewInit {
  @Input()
  widget: IupicsWidget;
  @ViewChild('chart', { read: UIChart })
  chart: UIChart;
  options: any;
  plugins: any;
  multiDataset = false;
  noData = false;
  dataToDisplay = null;
  hasBubble = false;
  hasPoint = false;
  tooltipLabels: any;
  lastLegendDisplayState: boolean;
  lang = 'fr-FR';
  constructor(
    private translateService: TranslateService,
    private themeService: ThemeService,
    private connectorService: SecurityManagerService
  ) {}

  updateChart(chartType: ChartType) {
    if (chartType === 'bubble' || chartType === 'scatter') {
      this.dataToDisplay = this.widget.dataXY;
    } else {
      this.dataToDisplay = this.widget.data;
    }
    if (this.dataToDisplay.datasets.length === 0 || this.dataToDisplay.labels.length === 0) {
      this.noData = true;
    }
    if (!this.noData) {
      if ([ChartType.AREA, ChartType.BAR, ChartType.COLUMN, ChartType.RADAR].includes(this.widget.chartType)) {
        this.dataToDisplay.datasets.forEach((dataset) => (dataset.fill = true));
      } else {
        this.dataToDisplay.datasets.forEach((dataset) => (dataset.fill = false));
      }
      if (this.widget.viewType === 'chart') {
        this.multiDataset =
          chartType === ChartType.COLUMN ||
          chartType === ChartType.BAR ||
          chartType === ChartType.AREA ||
          chartType === ChartType.RADAR ||
          chartType === ChartType.LINE ||
          chartType === ChartType.SCATTER ||
          chartType === ChartType.BUBBLE;

        const palette: any[] = this.themeService.getThemeProperty('colorsPalette');
        if (this.dataToDisplay.datasets.length > 1) {
          this.dataToDisplay.datasets.forEach((dataset: any, i: number) => {
            const color = i < palette.length ? palette[i] : this.getRandomColor();
            const _opacity = Math.round(Math.min(Math.max(0.7 || 1, 0), 1) * 255);
            const backgroundColor = color + _opacity.toString(16).toUpperCase();
            dataset.borderColor = color;
            dataset.pointBorderColor = '#fff';
            dataset.pointBackgroundColor = color;
            dataset.backgroundColor = backgroundColor;
            dataset.hoverBackgroundColor = color;
          });
        } else if (this.dataToDisplay.datasets.length > 0) {
          this.dataToDisplay.labels.forEach((label: string, i: number) => {
            const color = i < palette.length ? palette[i] : this.getRandomColor();
            const _opacity = Math.round(Math.min(Math.max(0.7 || 1, 0), 1) * 255);
            const backgroundColor = color + _opacity.toString(16).toUpperCase();
            this.dataToDisplay.datasets[0].backgroundColor.push(backgroundColor);
            this.dataToDisplay.datasets[0].hoverBackgroundColor.push(color);
            this.dataToDisplay.datasets[0].borderColor = '#fff';
            this.dataToDisplay.datasets[0].pointBorderColor = '#fff';
            this.dataToDisplay.datasets[0].pointBackgroundColor = color;
          });
        }

        this.options = {
          title: {
            display: true,
            fontSize: 12,
            text: this.widget.description
          },
          tooltips: {
            callbacks: {
              title: (item, data) => {
                const label = data.datasets[item[0].datasetIndex].label;
                return label;
              },
              label: (item, data) => {
                let dataSetValue: any = null;
                if ([ChartType.BUBBLE, ChartType.SCATTER].includes(chartType as ChartType)) {
                  dataSetValue = parseFloat(`${data.datasets[item.datasetIndex].data[item.index].x}`);
                } else {
                  dataSetValue = parseFloat(`${data.datasets[item.datasetIndex].data[item.index] as number}`);
                }
                /* const total = data.datasets[item.datasetIndex].data.reduce((a, b) => {
                  if ([ChartType.BUBBLE, ChartType.SCATTER].includes(chartTypeas ChartType)) {
                    return a + parseFloat(ChartType.BUBBLE === chartType? b.r : b.x);
                  } else {
                    return a + parseFloat(b);
                  }
                }, 0);
                const pourcentage = ((dataSetValue / total) * 100).toFixed(2) + '%'; */
                return formatNumber(dataSetValue, this.lang) /*  + (total !== 0 ? ` (${pourcentage})` : '') */;
              }
            }
          },
          legend: {
            display: false
          },
          scales: this.getAxesScale()
        };
      }
      if (chartType === ChartType.BAR) {
        this.options.indexAxis = 'y';
        this.widget.chartType = ChartType.COLUMN;
      } else {
        this.widget.chartType = chartType;
      }

      setTimeout(() => this.chart.reinit(), 0);
    }
  }
  ngOnInit() {
    this.lang = this.connectorService.getIupicsDefaultLanguage().iso_code.replace('_', '-');
    this.hasBubble =
      this.widget?.dataXY?.datasets &&
      this.widget.dataXY.datasets[0] &&
      this.widget.dataXY.datasets[0].data &&
      this.widget.dataXY.datasets[0].data[0] &&
      !isNaN(this.widget.dataXY.datasets[0].data[0].r);
    this.hasPoint = this.widget?.dataXY?.datasets.length > 0 && this.widget.dataXY.labels.length > 0;
    this.updateChart(this.widget.chartType);
    this.lastLegendDisplayState = false;
    this.tooltipLabels = {
      pieChart: this.translateService.instant('chartWidgetUi.pieChart'),
      doughnutChart: this.translateService.instant('chartWidgetUi.doughnutChart'),
      polarChart: this.translateService.instant('chartWidgetUi.polarChart'),
      lineChart: this.translateService.instant('chartWidgetUi.lineChart'),
      barChart: this.translateService.instant('chartWidgetUi.barChart'),
      columnChart: this.translateService.instant('chartWidgetUi.columnChart'),
      radarChart: this.translateService.instant('chartWidgetUi.radarChart'),
      bubbleChart: this.translateService.instant('chartWidgetUi.bubbleChart'),
      scatterChart: this.translateService.instant('chartWidgetUi.scatterChart'),
      areaChart: this.translateService.instant('chartWidgetUi.areaChart'),
      showLegend: this.translateService.instant('chartWidgetUi.showLegend')
    };
  }

  ngAfterViewInit() {
    // check si on est sur mobile ou si le widget est trop petit pour afficher la légende
    if (!this.noData) {
      this.adaptOptionsWithScreen();
    }
  }

  private getRandomColor() {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  swicthToPieChart() {
    this.updateChart(ChartType.PIE);
  }

  swicthToLineChart() {
    this.updateChart(ChartType.LINE);
  }
  swicthToScatterChart() {
    this.updateChart(ChartType.SCATTER);
  }
  swicthToBubbleChart() {
    this.updateChart(ChartType.BUBBLE);
  }
  swicthToAreaChart() {
    this.updateChart(ChartType.AREA);
  }
  swicthToPolarChart() {
    this.updateChart(ChartType.POLARAREA);
  }

  swicthToBarChart() {
    this.updateChart(ChartType.BAR);
  }
  swicthToColumnChart() {
    this.updateChart(ChartType.COLUMN);
  }

  swicthToDoughnutChart() {
    this.updateChart(ChartType.DOUGHNUT);
  }

  swicthToRadarChart() {
    this.updateChart(ChartType.RADAR);
  }
  // Permet d'afficher ou cacher la légende
  changeLegendView() {
    this.options.legend.display = !this.options.legend.display;
    this.lastLegendDisplayState = !this.lastLegendDisplayState;
    this.chart.reinit();
  }
  /**
   * Met le boolean a true ou false selon la largeur de l'écran
   * gère la présence de la légende quand le widget est trop petit ou sur mobile
   * @param {Event}event
   */
  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    event.stopPropagation();
    if (this.chart) {
      this.adaptOptionsWithScreen();
    }
  }

  adaptOptionsWithScreen() {
    if (this.chart.el.nativeElement.children[0].clientWidth <= 400 || Global.getDeviceWidth() <= 640) {
      this.options.title.fontSize = 11;
      this.options.legend.position = 'right';
    } else {
      this.options.title.fontSize = 12;
      this.options.legend.position = 'top';
    }
    if (this.lastLegendDisplayState !== this.options.legend.display) {
      this.lastLegendDisplayState = !this.lastLegendDisplayState;
      this.chart.reinit();
    }
  }

  getAxesScale() {
    if (ChartType.RADAR === this.widget.chartType) {
      return {};
    }

    const axesScale = {};
    if ([ChartType.BAR, ChartType.SCATTER, ChartType.BUBBLE].includes(this.widget.chartType)) {
      // has x axe
      const allDataForXAxe = this.dataToDisplay.datasets.map(({ data: [valueForAxeX] }) => valueForAxeX.x ?? valueForAxeX);
      axesScale['x'] = this.getTicks(allDataForXAxe);
    }

    if ([ChartType.LINE, ChartType.COLUMN, ChartType.SCATTER, ChartType.AREA, ChartType.BUBBLE].includes(this.widget.chartType)) {
      // has y axe
      let allDataForYAxe = [];
      if ([ChartType.LINE, ChartType.AREA].includes(this.widget.chartType)) {
        allDataForYAxe = [].concat(
          ...this.dataToDisplay.datasets.reduce((arr, { data: [x, xx] }) => (xx ? [...arr, x, xx] : [...arr, x]), [])
        );
      } else {
        allDataForYAxe = this.dataToDisplay.datasets.map(({ data }) => {
          if ([ChartType.BUBBLE, ChartType.SCATTER].includes(this.widget.chartType)) {
            return data[0].y;
          } else if (ChartType.COLUMN === this.widget.chartType) {
            return data[0];
          }
          return data[1];
        });
      }

      axesScale['y'] = this.getTicks(allDataForYAxe);
    }

    return axesScale;
  }
  getTicks(data: number[]) {
    const max = Math.max(...data);
    const min = Math.min(...data);
    const [order, mag] = this.getOrderOfMagnitude(Math.abs(max) + Math.abs(min));
    let stepSize = mag;
    if (order > 0 && order < 3) {
      stepSize *= 2.5;
    } else if (order < 4) {
      stepSize /= 4;
    }
    return {
      min: min >= 0 ? 0 : min + (Math.abs(min) % stepSize) - stepSize * 2,
      max: max <= 0 ? 0 : max - (Math.abs(max) % stepSize) + stepSize * 2,
      ticks: {
        stepSize
      }
    };
  }
  getOrderOfMagnitude(n: number) {
    // From: https://stackoverflow.com/questions/23917074/javascript-flooring-number-to-order-of-magnitude
    const order = Math.floor(Math.log(Math.abs(n)) / Math.LN10 + 0.000000001);
    return [order, Math.pow(10, order)];
  }
}
