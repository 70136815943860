<div class="customer-wrapper">
  <!-- Section - customer profile -->
  <section class="customer-profile pd-inline">
    <iu-customer-profile [data]="customer" (editButtonEmitter)="goToEditCustomer()"></iu-customer-profile>
  </section>
  <!-- Section - customer profile -->

  <!-- Section - badge buttons -->
  <section class="badges pd-inline">
    <div class="badges-container">
      <iu-badge-button
        *ngFor="let badge of badges; let i = index"
        [data]="badge"
        (clickEmitter)="badgeButtonClick(i)"
      ></iu-badge-button>
    </div>
    <div *ngIf="lastRefreshData" class="last-refresh">
      <span *ngIf="isSync">{{ 'specificWindow.customer360.lastRefresh' | translate }} {{ lastRefreshData }}</span>
      <span *ngIf="!isSync" class="not-sync-data" (click)="syncData()"
        >{{ 'specificWindow.customer360.dataNotSync' | translate }} <i class="icon-refresh3x" aria-hidden="true"></i>
      </span>
    </div>
  </section>
  <!-- Section - badge buttons -->

  <!-- Section - quick actions -->
  <section class="quick-actions pd-inline">
    <div>
      <iu-quick-action *ngFor="let qa of quickActions" [data]="qa"></iu-quick-action>
    </div>
    <div>
      <iu-quick-action
        *ngFor="let qa of quickActionsProcess"
        [data]="qa"
        (clickEmitter)="doQuickActionProcess($event)"
      ></iu-quick-action>
    </div>
  </section>
  <!-- Section - quick actions -->

  <!-- Section - statistics cards -->
  <section class="statistics">
    <iu-statistics-card *ngFor="let stats of statistics" [data]="stats" [C_BPartner_ID]="C_BPartner_ID"></iu-statistics-card>
  </section>
  <!-- Section - statistics cards -->

  <!-- Section - Text fields -->
  <section class="text-fields pd-inline pd-block">
    <ng-container *ngFor="let textfield of textfields">
      <iu-text-field-ui
        [ngClass]="{ 'col-1': !textfield.field?.data?.isSameLine }"
        [data]="textfield.field"
        [container]="this"
        [parentTab]="parentTab"
        [parentStore]="parentStore"
        [formatter]="textfield.formatter"
        [loadingAnimationTimeout]="1250"
      ></iu-text-field-ui>
    </ng-container>
    <iu-text-field-ui
      [container]="this"
      [label]="calculatedIndicator?.label"
      [value]="calculatedIndicator?.displayValue"
      [withColorIndicator]="true"
      [colorIndicatorCondition]="calculatedIndicator?.value >= 0"
      [loadingAnimationTimeout]="1250"
      #calculatedIndicatorRef
    >
    </iu-text-field-ui>
  </section>
  <!-- Section - Text fields -->
</div>
