import { Component, ElementRef, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UserAccount } from '@iupics-manager/models/user-account';
import { NoteEntry, NoteType } from '../notes.interface';
@Component({
  selector: 'iu-note-ui',
  templateUrl: './note-ui.component.html',
  styleUrls: ['./note-ui.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NoteUiComponent implements OnInit {
  @Input() note: NoteEntry;
  @Input() type: NoteType = 'TEXT';

  /* Users infos */
  me: UserAccount;

  constructor(private el: ElementRef<HTMLElement>, private connectorService: SecurityManagerService) {}

  ngOnInit(): void {
    this.me = this.connectorService.getIupicsUserAccount();
  }
}
