import { Component, ViewChild } from '@angular/core';
import { InputNumberUiComponent } from '@iupics-components/standard/fields/input-number-ui/input-number-ui.component';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-enterprise';

@Component({
  selector: 'iu-number-renderer',
  template: `
    <iu-input-number-ui
      #numberElt
      [fieldValue]="this.fieldValue"
      [cssClass]="'numberRenderer'"
      [isLabelDisplay]="false"
      [data]="this.templates"
      [isStandalone]="true"
      (fieldValueModified)="onFieldModified($event)"
      (enterKey)="onEnter($event)"
    ></iu-input-number-ui>
  `
})
export class NumberRendererComponent implements ICellRendererAngularComp {
  @ViewChild('numberElt', { read: InputNumberUiComponent }) numberElt: InputNumberUiComponent;
  params;
  label: string;
  templates: any;
  fieldValue = null;

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.label = this.params.label || null;
    this.templates = this.params.templates;

    if (params.value !== undefined) {
      this.fieldValue = params.value;
    }
  }

  refresh(params?: ICellRendererParams): boolean {
    return true;
  }

  onFieldModified(value: any) {
    const oldValue = this.params.node.data[this.templates.columnName];
    this.params.node.data[this.templates.columnName] = value;

    if (!this.params.node.isSelected()) {
      this.params.node.setSelected(true); // select row
    }

    if (this.params.onClick instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: value,
        rowData: this.params.node.data,
        columnName: this.params.colDef.field,
        oldValue,
        newValue: value
        // ...something
      };
      this.params.onClick({ ...this.params, ...params });
    }
  }

  onEnter(event: KeyboardEvent) {
    event.stopPropagation();
    this.onFieldModified(this.numberElt.fieldValue);
  }
}
