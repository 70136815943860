/* eslint-disable deprecation/deprecation */
import { Directive, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Directive({
  selector: '[iuLoading]'
})
export class LoadingDirective implements OnInit {
  private ATTRIBUTE_NAME = 'data-loading';

  private _isLoading = true;
  @Input()
  public get isLoading() {
    return this._isLoading;
  }
  public set isLoading(value: boolean) {
    this._isLoading = value;
  }

  private _dataToWatch: any;
  @Input()
  public get dataToWatch() {
    return this._dataToWatch;
  }
  public set dataToWatch(value: any) {
    this._dataToWatch = value;

    if (this.dataToWatch) {
      this._isLoading = this.checkDataToWatch(this.dataToWatch);
    }
    this.toggleLoading(this.isLoading);
  }

  @Input() timeout: number = 10000;

  @Output() loadingEnded: EventEmitter<boolean> = new EventEmitter();

  constructor(private el: ElementRef<HTMLElement>) {}

  ngOnInit(): void {
    if (this.timeout) {
      setTimeout(() => {
        this._isLoading = false;
        this.toggleLoading(false);
      }, this.timeout);
    }
  }

  private toggleLoading(loading: boolean) {
    this.el.nativeElement.setAttribute(this.ATTRIBUTE_NAME, `${loading}`);
    this.loadingEnded.emit(loading);
  }

  private checkDataToWatch(data: any) {
    let dataLoading = false;

    const checkIfDataEmpty = (d: any) => {
      for (const key in d) {
        if (d[key] instanceof Object) {
          checkIfDataEmpty(d[key]);
        } else if (d[key] === null || d[key] === undefined) {
          dataLoading = true;
          break;
        }
      }
    };

    checkIfDataEmpty(data);

    return dataLoading;
  }
}
