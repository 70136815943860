<div [ngClass]="[isFlexDesign ? 'board-alone' : 'board']">
  <div class="board-inner">
    <header class="board-header" [ngStyle]="{ display: kanbanGroup.title !== undefined ? 'block' : 'none' }">
      <h3 class="board-title js-board-handle">
        <span title="{{ kanbanGroup.title }}" class="board-title-text has-tooltip block-truncated">
          {{ kanbanGroup.title }}
        </span>
        <div title="{{ kanbanGroup.datas.length }}" class="issue-count-badge text-secondary">
          <span class="issue-count-badge-count"> {{ kanbanGroup.datas.length }} </span>
        </div>
        <button
          aria-label="New issue"
          title=""
          class="issue-count-badge-add-button btn btn-sm btn-default ml-1 has-tooltip js-no-trigger-collapse"
        >
          +
        </button>
      </h3>
    </header>
    <div [ngClass]="[!this.kanbanGroup.title ? 'all' : '', 'board-list-component']">
      <ul
        #boardList
        data-board="64"
        [ngClass]="[isFlexDesign ? 'board-list-flex' : 'board-list']"
        cdkDropList
        [cdkDropListData]="kanbanGroup.datas"
        (cdkDropListDropped)="changeGroup($event)"
      >
        <cdk-virtual-scroll-viewport
          (scroll)="this.kanbanGroup.isMoreData && onVirtualScroll($event)"
          itemSize="10"
          [ngClass]="[isFlexDesign ? 'cdk-board-list-flex' : 'cdk-board-list']"
        >
          <li
            #cardboard
            *cdkVirtualFor="
              let dataKanban of kanbanGroup.datas;
              trackBy: trackByFn.bind(this);
              let first = first;
              let last = last
            "
            cdkDrag
            [cdkDragData]="dataKanban"
            (cdkDragStarted)="dragstart($event, cardboard, dataKanban)"
            (click)="onClick(dataKanban)"
            [ngClass]="[isFlexDesign ? 'board-card-flex' : 'board-card', 'p-col-12 p-md-6 p-lg-3']"
          >
            <iu-kanban-card-ui [datasKanban]="dataKanban" [tableName]="tableName"></iu-kanban-card-ui>
          </li>
        </cdk-virtual-scroll-viewport>
      </ul>
    </div>
  </div>
</div>
