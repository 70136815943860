<div class="contact-container">
  <div class="contact-title">
    <h4>{{ (content?.greetingData?.Name ?? '') + ' ' }}{{ content?.Name ?? content?.Value }}</h4>
  </div>
  <div class="contact-content">
    <i *ngIf="content?.jobData?.Name" class="icon-user" aria-hidden="true"></i>
    <span *ngIf="content?.jobData?.Name" class="label">{{ content?.jobData?.Name }}</span>
    <i *ngIf="content?.EMail" class="icon-email" aria-hidden="true"></i>
    <span *ngIf="content?.EMail" class="label">{{ content?.EMail }}</span>
    <i *ngIf="content?.Phone" class="icon-phone" aria-hidden="true"></i>
    <span *ngIf="content?.Phone" class="label">{{ content?.Phone }}</span>
    <i *ngIf="content?.Phone2" class="icon-phone" aria-hidden="true"></i>
    <span *ngIf="content?.Phone2" class="label">{{ content?.Phone2 }}</span>
  </div>
</div>
