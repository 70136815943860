import { Directive, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

@Directive()
export abstract class CardContent implements OnDestroy {
  @Input() content: any;

  subcriptions: Subscription[] = [];

  constructor() {}

  ngOnDestroy(): void {
    this.subcriptions.forEach((sub) => sub.unsubscribe());
  }
}
