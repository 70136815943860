<div class="avatar-wrapper">
  <img
    *ngIf="avatarInfos?.pictureURL && avatarInfos?.pictureURL.length > 0 && !displayDefaultAvatar"
    [src]="avatarInfos?.pictureURL"
    (error)="onError($event)"
    alt="profile picture"
  />
  <span *ngIf="(!avatarInfos?.pictureURL || displayDefaultAvatar) && avatarInfos.abbreviation" [title]="avatarInfos.fullname">
    {{ avatarInfos.abbreviation }}
  </span>
</div>
