<div #mainDiv id="chips" [ngClass]="['p-chips ui-widget', styleClass || '']" [ngStyle]="style" (click)="onClick()">
  <ul
    data-cy="chips-list"
    [ngClass]="['p-inputtext ui-state-default ui-corner-all', focus ? 'ui-state-focus' : '', disabled ? 'p-disabled' : '']"
    style="display: flex"
  >
    <div
      #wrapper
      [ngStyle]="
        this.shouldBeSmall && this.focus && inputtext.value.trim() !== ''
          ? { display: 'none' }
          : {
              overflow: 'hidden',
              'max-width.px': maxWidthChipsContainer,
              'white-space': 'nowrap',
              display: '-webkit-inline-box'
            }
      "
      (wheel)="handleWheel($event)"
    >
      <li
        #token
        *ngFor="let item of value; let i = index"
        class="p-chips-token p-highlight ui-corner-all"
        (click)="onItemClick($event, item)"
        data-cy="chip"
      >
        <ng-container *ngTemplateOutlet="itemTemplate; context: { $implicit: item }"></ng-container>
        <span *ngIf="!itemTemplate" class="p-chips-token-label">{{ field ? resolveFieldData(item, field) : item }}</span>
        <span *ngIf="!disabled" class="p-chips-token-icon pi pi-times" (click)="removeItem($event, i)"></span>
      </li>
    </div>
    <li class="p-chips-input-token" style="max-width: 50%">
      <input
        #inputtext
        type="text"
        [ngStyle]="this.focus && (!this.shouldBeSmall || inputtext.value.trim() !== '') ? { width: '100%' } : {}"
        [attr.id]="inputId"
        [attr.placeholder]="value && value.length ? null : placeholder"
        [attr.tabindex]="tabindex"
        (keydown)="onKeydown($event)"
        (input)="onInput()"
        (paste)="onPaste($event)"
        [attr.aria-labelledby]="ariaLabelledBy"
        (focus)="!disabled ? onInputFocus($event) : null"
        [tabindex]="disabled ? '-1' : null"
        (blur)="onInputBlur($event)"
        [readonly]="disabled || preventInput"
        [ngStyle]="inputStyle"
        [class]="inputStyleClass"
        [ngClass]="['prime-chips-input', value?.length ? 'prime-chips-input-small' : '', inputStyleClass || '']"
        autocomplete="no"
        data-cy="input-chips"
        [attr.data-cy-columnName]="columnName"
      />
    </li>
  </ul>
</div>
