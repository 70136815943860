import { Component, OnInit } from '@angular/core';
import { CompiereDataGridFilterType, DataStoreRequest } from '@compiere-ws/models/compiere-data-json';
import { CompiereProcessService } from '@compiere-ws/services/compiere-process/compiere-process.service';
import { ProcessInProgressService } from '@compiere-ws/services/process-in-progress/process-in-progress.service';
import { SocketService } from '@compiere-ws/services/socket/socket.service';
import { OperatorFilterType } from '@iupics-components/models/universal-filter';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { WindowFactoryService } from '@iupics-manager/managers/ui-creator/window-factory/window-factory.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SpecificWindowUiComponent } from '../specific-window-ui/specific-window-ui.component';

@Component({
  selector: 'iu-pos-ui',
  templateUrl: './pos-ui.component.html',
  styleUrls: ['./pos-ui.component.scss']
})
export class PosUiComponent extends SpecificWindowUiComponent implements OnInit {
  products$: Observable<{}[]>;
  prices = {};
  orderLines = [];
  grandtotal = 0;

  constructor(
    windowFactory: WindowFactoryService,
    uiCreator: UICreatorService,
    store: DataStoreService,
    processService: CompiereProcessService,
    protected translateService: TranslateService,
    socketService: SocketService,
    private securityManager: SecurityManagerService,
    connectorService: SecurityManagerService,
    progressService: ProcessInProgressService
  ) {
    super(windowFactory, uiCreator, store, processService, socketService, connectorService, progressService, translateService);
    this.isModal = false;
  }

  ngOnInit() {
    super.ngOnInit();
    const productRequest: DataStoreRequest = {
      windowId: this.formId,
      parent_constraint: '',
      compiereRequest: {
        startRow: 0,
        endRow: 15,
        tableName: 'M_Product',
        filterModel: {
          AD_CLIENT_ID: {
            filterType: CompiereDataGridFilterType.SET,
            values: [this.securityManager.getIupicsUserContext()['#AD_Client_ID']],
            operators: [OperatorFilterType.EQUALS]
          }
        }
      }
    };
    this.products$ = this.store.getDataGrid(productRequest).pipe(
      map((productResult) => {
        return productResult.data;
      })
    );
  }

  getPrice(product_id: number) {
    if (!this.prices[product_id]) {
      this.prices[product_id] = Math.floor(Math.random() * (1000 - 1) + 1);
    }
    return this.prices[product_id];
  }

  addToBasket(product) {
    this.orderLines.push({ product: product, price: this.prices[product['M_PRODUCT_ID']] });
    this.grandtotal += this.prices[product['M_PRODUCT_ID']];
  }

  validateOrder() {
    this.orderLines = [];
    this.grandtotal = 0;
  }
}
