import { DynamicComponent } from '@iupics-manager/models/dynamic-component';

export enum IupicsTypeEvent {
  addBreadcrumbItem,
  removeBreadcrumbItem,
  clickBreadCrumbItem,
  closeBreadCrumbItem,
  showGridView,
  showEditView,
  showSpecificEditView,
  showAdditionalInfo,
  showNotesDetail,
  showProcess,
  showProductAttributeView,
  removeChildEvent,
  refreshChildEvent,
  addFieldEvent,
  collapseEvent,
  expandEvent,
  selectDataChange,
  selectZoomChange,
  showSpecificWindow,
  checkBeforeChange,
  triggerAfterChange,
  updatedActiveItemBreadcrumb,
  ADD_PIN_WINDOW,
  DELETE_SHORTCUT,
  DELETE_WIDGET,
  DELETE_PIN_WINDOW,
  FOCUS_WINDOW,
  UNFOCUS_PIN_WINDOW,
  UPDATE_NB_NOTIF,
  RESET_NB_NOTIF,
  INIT_CLOSE_WINDOW,
  CLOSE_WINDOW,
  RESIZE_WINDOW,
  UPDATE_POSITION,
  HIDE_MENU_UI,
  DRAG_START_SHORTCUT,
  DRAG_START_WIDGET,
  SHOW_POP_UP,
  MISC,
  CUSTOMIZE_SHORTCUT,
  UNIVERSAL_SEARCH,
  REFRESH_GRID,
  UPDATE_ROWSAVED
  // showLocationPanel
}

export interface IupicsEvent {
  type: IupicsTypeEvent;
  item: DynamicComponent;
  isNew?: Boolean;
}
