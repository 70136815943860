import { Renderer2 } from '@angular/core';

export class DragDropGroup<T> {
  isDragMode = false;
  activeDiv: HTMLDivElement;
  protected activeDude: any;
  protected emptyDiv: HTMLDivElement;
  protected indexOfPreview: number;
  protected mouseupListener: Function;
  protected mousemoveListener: Function;
  protected windowFocusListener: Function;

  protected _currentItemList: any[];
  itemsList: { [parentId: number]: T[] } = {};

  constructor(protected renderer: Renderer2) {}

  //#region draggable v2
  protected createEmptyDiv(cssClass: string) {
    this.emptyDiv = this.renderer.createElement('div');
    this.renderer.addClass(this.emptyDiv, 'drag-item');
    this.renderer.addClass(this.emptyDiv, 'empty');
    for (const _cssClass of cssClass.trim().split(' ')) {
      if (_cssClass) {
        this.renderer.addClass(this.emptyDiv, _cssClass);
      }
    }
    this.renderer.appendChild(this.emptyDiv, this.renderer.createText('empty'));
  }

  handleDragStart($event: MouseEvent, dragElem: HTMLDivElement, item: any) {
    this.isDragMode = true;
    this.renderer.insertBefore(dragElem.parentElement, this.emptyDiv, dragElem);
    this.renderer.addClass(dragElem, 'drag-mode');
    this.renderer.setStyle(dragElem, 'top', `${this.getY($event, dragElem) - 35}px`);
    this.renderer.setStyle(dragElem, 'left', `${this.getX($event, dragElem)}px`);

    this.mouseupListener = this.renderer.listen(document.documentElement, 'mouseup', (event: MouseEvent) => {
      this.handleDrop(event, dragElem);
    });

    this.mousemoveListener = this.renderer.listen(document.documentElement, 'mousemove', (event: MouseEvent) => {
      this.handleDrag(event, dragElem);
    });

    this.windowFocusListener = this.renderer.listen(window, 'blur', (event) => {
      this.handleDrop(event, dragElem);
      this.windowFocusListener();
    });
    this.activeDiv = dragElem;
    this.activeDude = item;
    this.indexOfPreview = Array.from(this.activeDiv.parentElement.children).indexOf(this.emptyDiv);
  }

  protected handleDrag($event: MouseEvent, dragElem: HTMLDivElement) {
    // top = $event.pageY / left = $event.pageX
    this.renderer.setStyle(dragElem, 'top', `${this.getY($event, dragElem) - 35}px`);
    this.renderer.setStyle(dragElem, 'left', `${this.getX($event, dragElem)}px`);
  }

  protected handleDrop($event: MouseEvent, dragElem: HTMLDivElement) {
    $event.stopPropagation();
    this.isDragMode = false;
    this.renderer.removeChild(this.emptyDiv.parentElement, this.emptyDiv);
    this.renderer.removeClass(dragElem, 'drag-mode');
    this.renderer.removeStyle(dragElem, 'top');
    this.renderer.removeStyle(dragElem, 'left');
    this.mouseupListener();
    this.mousemoveListener();
    this.windowFocusListener();

    // changement de place dans le tableau
    this._currentItemList.splice(this._currentItemList.indexOf(this.activeDude), 1);
    this._currentItemList.splice(this.indexOfPreview, 0, this.activeDude);

    this.saveItemPos();
  }

  protected getY($event: MouseEvent, dragElem: HTMLDivElement) {
    return $event.pageY + dragElem.clientHeight + 2 > document.documentElement.clientHeight
      ? document.documentElement.clientHeight - (dragElem.clientHeight + 2)
      : $event.pageY < 0
      ? 0
      : $event.pageY;
  }

  protected getX($event: MouseEvent, dragElem: HTMLDivElement) {
    return $event.pageX + dragElem.clientWidth + 2 > document.documentElement.clientWidth
      ? document.documentElement.clientWidth - (dragElem.clientWidth + 2)
      : $event.pageX < 0
      ? 0
      : $event.pageX;
  }

  handleMouseOver($event: MouseEvent, overElem: HTMLDivElement) {
    $event.stopPropagation();
    const elems = Array.from(overElem.parentElement.children).filter((elem) => elem !== this.activeDiv);
    const indexOfOver = Array.from(elems).indexOf(overElem);
    const isLast = indexOfOver === elems.length - 1;
    this.indexOfPreview > indexOfOver
      ? isLast
        ? this.renderer.appendChild(overElem.parentElement, this.emptyDiv)
        : this.renderer.insertBefore(overElem.parentElement, this.emptyDiv, overElem)
      : isLast
      ? this.renderer.appendChild(overElem.parentElement, this.emptyDiv)
      : this.renderer.insertBefore(overElem.parentElement, this.emptyDiv, overElem.nextSibling);
    this.indexOfPreview = Array.from(this.emptyDiv.parentElement.children)
      .filter((elem) => elem !== this.activeDiv)
      .indexOf(this.emptyDiv);
  }
  //#endregion

  protected saveItemPos() {
    throw new Error('Method not implemented.');
  }
}
