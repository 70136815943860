import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IupicsComponentsModule } from '@iupics-components/iupics-components.module';
import { IupicsManagerModule } from '@iupics-manager/iupics-manager.module';
import { IupicsUtilModule } from '@iupics-util/iupics-util.module';
import { MenuTopComponent } from '@web-desktop/components/menu-top/components/menu-top-ui/menu-top-ui.component';
import { MenuUiComponent } from '@web-desktop/components/menu-top/components/menu-ui/menu-ui.component';
import { NotificationCenterUiComponent } from '@web-desktop/components/menu-top/components/notification-center-ui/notification-center-ui.component';
import { USearchUiComponent } from '@web-desktop/components/menu-top/components/u-search-ui/u-search-ui.component';
import { USearchContainerComponent } from '@web-desktop/components/menu-top/components/usearch-container/usearch-container.component';
import { UserProfileComponent } from '@web-desktop/components/menu-top/components/user-profile/user-profile.component';
import { DesktopComponent } from '@web-desktop/components/workspace/components/desktop-ui/desktop-ui.component';
import { WorkspaceComponent } from '@web-desktop/components/workspace/components/workspace-ui/workspace-ui.component';
import { WebDesktopService } from '@web-desktop/controllers/web-desktop.service';
import { AngularSplitModule } from 'angular-split';
import { IupicsWidgetsModule } from '../iupics-widgets/iupics-widgets.module';
import { AppVersionPanelComponent } from './components/menu-top/components/app-version-panel/app-version-panel.component';
import { GroupByCategoryPipe } from './components/menu-top/components/menu-ui/group-by-category.pipe';
import { TabGroupUiComponent } from './components/menu-top/components/tab-group-ui/tab-group-ui.component';
import { TabUiComponent } from './components/menu-top/components/tab-ui/tab-ui.component';
import { MenuUiManagerService } from './components/menu-top/controllers/menu-ui-manager/menu-ui-manager.service';
import { DashboardGroupTabUiComponent } from './components/workspace/components/dashboard/components/dashboard-group-tab-ui/dashboard-group-tab-ui.component';
import { DashboardGroupUiComponent } from './components/workspace/components/dashboard/components/dashboard-group-ui/dashboard-group-ui.component';
import { FavoriteGroupUiComponent } from './components/workspace/components/dashboard/components/favorite-group-ui/favorite-group-ui.component';
import { FavoritesUiComponent } from './components/workspace/components/dashboard/components/favorites-ui/favorites-ui.component';
import { WidgetGroupUiComponent } from './components/workspace/components/dashboard/components/widget-group-ui/widget-group-ui.component';
import { WidgetUiComponent } from './components/workspace/components/dashboard/components/widget-ui/widget-ui.component';
import { DashboardComponent } from './components/workspace/components/dashboard/dashboard.component';
import { DashboardGroupNamePipe } from './components/workspace/components/dashboard/pipes/dashboard-group-name/dashboard-group-name.pipe';
import { FavoriteFilterPipe } from './components/workspace/components/dashboard/pipes/favorite-filter/favorite-filter.pipe';
import { TabSplitViewContentUiComponent } from './components/workspace/components/tab-split-view-content-ui/tab-split-view-content-ui.component';
import { DashboardManagerService } from './components/workspace/controllers/dashboard-manager/dashboard-manager.service';
import { TabSplitViewUiComponent } from './components/workspace/tab-split-view-ui/tab-split-view-ui.component';

@NgModule({
  declarations: [
    WorkspaceComponent,
    DesktopComponent,
    UserProfileComponent,
    USearchUiComponent,
    NotificationCenterUiComponent,
    MenuUiComponent,
    MenuTopComponent,
    USearchContainerComponent,
    TabGroupUiComponent,
    TabUiComponent,
    TabSplitViewUiComponent,
    TabSplitViewContentUiComponent,
    DashboardComponent,
    DashboardGroupUiComponent,
    FavoritesUiComponent,
    WidgetUiComponent,
    FavoriteGroupUiComponent,
    WidgetGroupUiComponent,
    FavoriteFilterPipe,
    DashboardGroupNamePipe,
    GroupByCategoryPipe,
    DashboardGroupTabUiComponent,
    AppVersionPanelComponent
  ],
  providers: [WebDesktopService, MenuUiManagerService, DashboardManagerService],
  imports: [CommonModule, IupicsUtilModule, IupicsComponentsModule, AngularSplitModule, IupicsWidgetsModule, IupicsManagerModule],
  exports: [DesktopComponent, AppVersionPanelComponent]
})
export class DesktopModule {}
