<div class="tag-list-item-wrapper">
  <iu-avatar-ui *ngIf="avatarInfos" [avatarInfos]="avatarInfos"></iu-avatar-ui>
  <span *ngIf="!noMatch; else noMatchTemplate" class="tag-list-item-value">
    {{ value }}
  </span>
</div>

<ng-template #noMatchTemplate>
  <span class="tag-list-item-value"> {{ 'inputMention.tagListItem.noMatch' | translate }} {{ value }} </span>
</ng-template>
