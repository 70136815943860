/* eslint-disable deprecation/deprecation */
import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';

@Directive({
  selector: '[iuNumberAnimation]'
})
export class NumberAnimationDirective implements OnInit, AfterViewInit, OnChanges {
  @Input() duration: number = 1500;
  @Input() data: number | string;
  @Input() dataBinding: number | string;
  @Input() playAnimation: boolean = null;
  @Input() outputText: boolean = true;
  @Input() formatter: Intl.NumberFormat = null;

  @Output() numberEmitter: EventEmitter<number> = new EventEmitter();

  number: number;
  postNumberStr: string;

  constructor(private el: ElementRef<HTMLElement>, private connectorService: SecurityManagerService) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    if (this.playAnimation === null) {
      if (!this.data) {
        this.number = 0;
      }
      this.animateValue();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.playAnimation || changes.data) {
      this.animateValue();
    }
  }

  animateValue() {
    this.number = parseInt(`${this.data}`, 10);

    if (this.number === 0) {
      this.setText(this.number);
      return;
    }

    let startTimestamp = null;
    const step = (timestamp) => {
      if (!startTimestamp) startTimestamp = timestamp;
      const progress = Math.min((timestamp - startTimestamp) / this.duration, 1);
      const number = Math.floor(progress * (this.number ?? 0));

      this.setText(number);
      if (progress < 1) {
        window.requestAnimationFrame(step);
      }
    };

    window.requestAnimationFrame(step);
  }

  setText(number: number) {
    if (this.outputText) {
      const formatter =
        this.formatter ??
        Intl.NumberFormat(this.connectorService.getIupicsUserContext()['#AD_Language'].slice(0, 2), {
          notation: 'compact'
        });

      this.el.nativeElement.innerHTML = formatter.format(number);
    }

    this.numberEmitter.emit(number);
  }
}
