<div [ngClass]="['statistics-card-container', stats?.type === 0 ? 'row-2' : 'row-3']">
  <h4 class="statistics-card-title" iuLoading [dataToWatch]="stats?.label">
    {{ stats?.label }}
  </h4>
  <span
    *ngIf="stats?.type === 0 && stats?.amount >= 0"
    class="statistics-card-total"
    iuNumberAnimation
    [data]="stats?.amount"
    [formatter]="formatter"
    [playAnimation]="animateData"
  ></span>
  <div class="statistics-card-pourcentage">
    <div class="pourcentage" iuLoading [dataToWatch]="stats" (loadingEnded)="setAnimateDataState($event)">
      <span class="pourcentage-operator">{{ stats?.indicator }}</span>
      <span
        class="pourcentage-value"
        iuNumberAnimation
        [data]="stats?.pct"
        [playAnimation]="animateData"
        (numberEmitter)="bindAnimatedValue($event, 'pct')"
      ></span>
      <span class="pourcentage-unit">%</span>
    </div>
    <i
      [ngClass]="{
        indicator: true,
        'pourcentage-fill': true,
        'icon-arrow-up': stats?.indicator === '+',
        'icon-arrow-down': stats?.indicator === '-'
      }"
      style="--value: {{ animatedData?.pct }};"
      aria-hidden="true"
    ></i>
  </div>
  <div *ngIf="stats?.type === 1" class="statistics-card-additionnals">
    <div class="additionnal">
      <h5 class="additionnal-label">{{ 'statisticsCard.countLabel' | translate }}</h5>
      <span class="additionnal-value" iuNumberAnimation [data]="stats?.count" [playAnimation]="animateData"></span>
    </div>
    <div class="additionnal">
      <h5 class="additionnal-label">{{ 'statisticsCard.conversionRate' | translate }}</h5>
      <div class="additionnal-value-wrapper">
        <span class="additionnal-value" iuNumberAnimation [data]="stats?.amount" [playAnimation]="animateData"> </span>
        <span class="additionnal-value"> % </span>
      </div>
    </div>
  </div>
</div>
