export class VFormatValidator {
  public static check(f: string, c: string) {
    switch (f) {
      case 'c': {
        return { valid: this.isLetter(c) || this.isDigit(c) || this.isSpace(c), convert: c };
      }
      case 'C': {
        return { valid: this.isLetter(c) || this.isDigit(c) || this.isSpace(c), convert: c.toUpperCase() };
      }
      case 'a': {
        return { valid: this.isLetter(c) || this.isDigit(c), convert: c };
      }
      case 'A': {
        return { valid: this.isLetter(c) || this.isDigit(c), convert: c.toUpperCase() };
      }
      case 'l': {
        return { valid: this.isLetter(c), convert: c };
      }
      case 'L': {
        return { valid: this.isLetter(c), convert: c.toUpperCase() };
      }
      case 'o': {
        return { valid: this.isLetter(c) || this.isSpace(c), convert: c };
      }
      case 'O': {
        return { valid: this.isLetter(c) || this.isSpace(c), convert: c.toUpperCase() };
      }
      case '9': {
        return { valid: this.isDigit(c) || this.isSpace(c), convert: c };
      }
      case '0': {
        return { valid: this.isDigit(c), convert: c };
      }
      case ' ': {
        return { valid: true, convert: c };
      }
    }
  }

  public static isLetter(c: string): boolean {
    return c.match(/[a-zA-Z]/i) != null;
  }
  public static isDigit(c: string): boolean {
    return c.match(/[0-9]/i) != null;
  }
  public static isSpace(c: string): boolean {
    return c.match(/\s/i) != null;
  }
}
