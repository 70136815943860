import { AfterViewInit, Component, OnInit } from '@angular/core';
import { CompiereProcessService } from '@compiere-ws/services/compiere-process/compiere-process.service';
import { ProcessInProgressService } from '@compiere-ws/services/process-in-progress/process-in-progress.service';
import { SocketService } from '@compiere-ws/services/socket/socket.service';
import { CustomDesignItemType } from '@iupics-components/models/custom-design';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { WindowFactoryService } from '@iupics-manager/managers/ui-creator/window-factory/window-factory.service';
import { TranslateService } from '@ngx-translate/core';
import { SpecificWindowUiComponent } from '../specific-window-ui/specific-window-ui.component';
import { DynamicComponent } from '@iupics-manager/models/dynamic-component';
import { IupicsTypeEvent } from '@iupics-manager/models/iupics-event';

@Component({
  selector: 'iu-charge-code-ui',
  templateUrl: './charge-code-ui.component.html',
  styleUrls: ['./charge-code-ui.component.scss']
})
export class ChargeCodeUiComponent extends SpecificWindowUiComponent implements OnInit, AfterViewInit {
 
  lastRowSelected: any;
  constructor(
    windowFactory: WindowFactoryService,
    uiCreator: UICreatorService,
    store: DataStoreService,
    processService: CompiereProcessService,
    protected translateService: TranslateService,
    private uiCreatorService: UICreatorService,
    socketService: SocketService,
    connectorService: SecurityManagerService,
    progressService: ProcessInProgressService
  ) {
    super(windowFactory, uiCreator, store, processService, socketService, connectorService, progressService, translateService);
    
    this.isModal = false;
    this.customDesignArray.push(
      {
        vcr: 'vcrLeft',
        type: CustomDesignItemType.FIELD,
        columnName: 'AD_OrgOnly_ID',
        cssClass: 'p-col-12'
      },
      {
        vcr: 'vcrMiddle',
        type: CustomDesignItemType.FIELD,
        columnName: 'User3_ID',
        cssClass: 'p-col-12'
      },
      {
        vcr: 'vcrRight',
        type: CustomDesignItemType.FIELD,
        columnName: 'AD_Org_ID',
        cssClass: 'p-col-12'
      },
      {
        vcr: 'vcrBot',
        type: CustomDesignItemType.GRID,
        tableName: 'Code Imputation - Table',
        cssClass: 'p-col-12',
        rowSelection: 'single',
        shouldSelectFirst: false
      }
    );
  }   
  
  ngOnInit() {
    if (!this.dataStore) {
      this.dataStore = this.store.newSpecificWindowData(this.formId);
    }
    const item: DynamicComponent = {
      container: this,
      DOMParentComponent: this,
      component: 'SpecificWindowUiComponent',
      cssClass: 'p-col-12',
      isCssOnComponent: false,
      tabId: this.formId,
      gridPaginator: false
    };
    this.windowFactory.newEventHandler({
      type: IupicsTypeEvent.showSpecificWindow,
      item: item
    });
  }
}
