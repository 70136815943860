import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { Global } from '@iupics-manager/models/global-var';
import { DomHandler } from 'primeng/dom';

@Component({
  selector: 'iu-modal-ui',
  templateUrl: './modal-ui.component.html',
  styleUrls: ['./modal-ui.component.scss'],
})
export class ModalUiComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() title: string;
  @Input() hasCloseBtn = false;
  @Input() hasFooter = false;
  @Input() closeBtnLabel = 'generic.close';
  @Input() angularStyle: any;
  @Input() isSearch = false;
  @Input() isModalDisplay = true;
  @Input() modalClass = 'p-col-10 p-md-10 p-lg-10';
  @Input() isLoading = false;
  @Input() appendTo = null;
  @ViewChild('container', { static: true }) containerViewChild: ElementRef;
  @Output() closeModalEmitter = new EventEmitter<any>();
  @Input() contentType: ModalContentType;

  isCloseInit = false;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {}
  ngAfterViewInit() {
    if (this.appendTo) {
      if (this.appendTo === 'body') {
        Global.activeModals.push(this);
        document.body.appendChild(this.containerViewChild.nativeElement);
      } else {
        DomHandler.appendChild(this.containerViewChild.nativeElement, this.appendTo);
      }
    }
  }
  ngOnDestroy() {
    if (Global.activeModals && Global.activeModals.length > 0) {
      const index = Global.activeModals.indexOf(this);
      if (index > -1) {
        Global.activeModals.splice(index, 1);
      }
    }
    if (this.isModalDisplay) {
      this.isModalDisplay = false;
      this.closeModalEmitter.emit();
    }

    if (this.appendTo) {
      this.el.nativeElement.appendChild(this.containerViewChild.nativeElement);
    }
  }
  close($event: MouseEvent = null, forceClose = false) {
    if (forceClose || !$event || ($event.target as any).classList.contains('iu-modal-background') === true) {
      if ($event) {
        $event.stopPropagation();
      }
      if (this.isModalDisplay) {
        this.isModalDisplay = false;
      }
      this.closeModalEmitter.emit();
    }
  }

  initClose($event: MouseEvent) {
    if (($event.target as any).classList.contains('iu-modal-background') === true) {
      this.isCloseInit = true;
      const a = this.renderer.listen(document.documentElement, 'mouseup', ($e: MouseEvent) => {
        this.close($e);
        this.isCloseInit = false;
        a();
      });
    }
  }
}
export enum ModalContentType {
  WINDOW = 'window',
  PROCESS = 'process',
  FORM = 'form',
  JOINFILE = 'joinFiles',
}
