import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {
  CompiereDataGridFilterType,
  CompiereDataGridRequestJSON,
  CompiereDataGridType,
  DataStoreKey,
  DataStoreRequest
} from '@compiere-ws/models/compiere-data-json';
import { Calendar, CalendarOptions, EventClickArg, EventDropArg, ViewApi } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin, { EventDragStartArg } from '@fullcalendar/interaction';
import { OperatorFilterType } from '@iupics-components/models/universal-filter';
import { BladeUiComponent } from '@iupics-components/standard/layouts/blade-ui/blade-ui.component';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { AbstractDynamicComponent } from '@iupics-manager/models/abstract-dynamic-component';
import { IupicsEvent } from '@iupics-manager/models/iupics-event';
import { SplitManagerService } from '@web-desktop/controllers/split-manager/split-manager.service';
import * as moment from 'moment';
import { tap } from 'rxjs/operators';
import { GridViewUiComponent } from '../../grid-view-ui/grid-view-ui.component';
import { DayGridType } from '../calendar-toolbar-ui/calendar-toolbar-ui.component';

@Component({
  selector: 'iu-calendar-view-ui',
  templateUrl: './calendar-view-ui.component.html',
  styleUrls: ['./calendar-view-ui.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CalendarViewUiComponent extends AbstractDynamicComponent implements OnInit, AfterViewInit, OnDestroy {
  context: {
    dateStart: Date;
    defaultView: string;
  };

  @ViewChild('fc', { static: true })
  fc: ElementRef;

  @Input()
  datas: any[] = [];
  @Input()
  defaultColumn: { name: any };

  @Input()
  filter: CompiereDataGridRequestJSON;
  @Output()
  setFilterEmitter = new EventEmitter<CompiereDataGridRequestJSON>();

  @Input()
  style: any;
  @Input()
  initRequest: CompiereDataGridRequestJSON;

  @Input()
  styleClass: string;

  initialized: boolean;

  calendar: Calendar;

  config: CalendarOptions;

  events: any[] = [];

  title: string;

  selectedStartDateField: string;
  selectedEndDateField: string;
  columnNameDateImpacted: string;
  displayColumns: string[] = ['DocumentNo', 'C_BPartner_ID', 'AD_User_ID'];
  @Output()
  clickEmitter = new EventEmitter<any>();

  private isGetDatagridInProgress = false;

  constructor(
    private store: DataStoreService,
    private connectorService: SecurityManagerService,
    private uiCreator: UICreatorService,
    private splitManager: SplitManagerService,
    private el: ElementRef,
    private renderer: Renderer2
  ) {
    super();
  }

  ngOnInit() {
    this.renderer.setStyle(this.container.scrollableElt.nativeElement, 'background-color', '#fff');
    this.config = {
      plugins: [dayGridPlugin, interactionPlugin]
    };
    this.subscriptions.push(
      this.splitManager.dragGutterEmitter.subscribe(() => {
        this.handleWindowResize(this.calendar);
      })
    );
  }

  initData(): void {
    if (this.isGetDatagridInProgress) {
      return;
    }
    if (this.container) {
      this.subscriptions.push(
        this.uiCreator.getColumnInfos(this.tabId).subscribe((columnInfos) => {
          this.defaultColumn = columnInfos.find((c) => c.fieldEntity.field.Name === 'Created') ? { name: 'Created' } : null;
          if (!this.defaultColumn) {
            this.defaultColumn = columnInfos
              .filter(
                (ci) =>
                  ((ci.fieldEntity.field.Name !== null &&
                    ci.fieldEntity.field.Name !== undefined &&
                    ci.fieldEntity.field.Name.trim() !== '') ||
                    (ci.fieldEntity.field.name !== null &&
                      ci.fieldEntity.field.name !== undefined &&
                      ci.fieldEntity.field.name.trim() !== '')) &&
                  (ci.fieldEntity.field.IsDisplayed || ci.fieldEntity.field.IsKey)
              )
              .filter(
                (item) =>
                  (item.fieldEntity.field.AD_Reference_ID === 15 || item.fieldEntity.field.AD_Reference_ID === 16) &&
                  ((item.fieldEntity.field.MRSeqNo !== 0 && item.fieldEntity.field.MRSeqNo !== 99999) ||
                    item.fieldEntity.field.IsDisplayed)
              )
              .map((item) => ({ name: item.fieldEntity.field.ColumnName }))[0];
          }

          // appliquer des querys venant de l'url
          if (
            !this.filter ||
            (this.initRequest &&
              (Object.keys(this.initRequest.filterModel).length > 0 ||
                this.initRequest.sortModel.length > 0 ||
                this.initRequest.rowGroupCols.length > 0))
          ) {
            this.filter = {};
            if (this.initRequest.filterModel) {
              this.filter.filterModel = { ...this.filter.filterModel, ...this.initRequest.filterModel };
            }
            if (this.initRequest.sortModel) {
              this.filter.sortModel = [...this.initRequest.sortModel];
            }
            if (this.initRequest.rowGroupCols) {
              this.filter.rowGroupCols = [...this.initRequest.rowGroupCols];
            }
            this.initRequest = undefined;
          }
          this.getDateFilter();

          this.setFilterEmitter.emit(this.filter);
        })
      );
    }
  }

  private initFilter(): void {
    if (!this.defaultColumn) {
      return;
    }
    this.filter = {
      startRow: 0,
      endRow: 0,
      entityId: this.tabId,
      windowType: CompiereDataGridType.WINDOW,
      filterModel: this.filter && this.filter.filterModel ? this.filter.filterModel : {},
      sortModel: this.filter && this.filter.sortModel ? this.filter.sortModel : [],
      rowGroupCols: this.filter && this.filter.rowGroupCols ? this.filter.rowGroupCols : [],
      valueCols: this.filter && this.filter.valueCols ? this.filter.valueCols : []
    };
    const calendarDateStart = this.calendar.getCurrentData().dateProfile.activeRange.start;
    const calendarDateEnd = this.calendar.getCurrentData().dateProfile.activeRange.end;
    this.filter = {
      ...this.filter,
      filterModel: {
        ...this.filter.filterModel,
        [this.defaultColumn.name]: {
          filterType: CompiereDataGridFilterType.DATE,
          values: [
            moment(calendarDateStart).format('YYYY-MM-DDTHH:mm:ss.SSS').substring(0, 26) +
              '' +
              moment(calendarDateStart)
                .format('YYYY-MM-DDTHH:mm:ss.SSS')
                .substring(27, moment(calendarDateStart).format('YYYY-MM-DDTHH:mm:ss.SSS').length),
            moment(calendarDateEnd).format('YYYY-MM-DDTHH:mm:ss.SSS').substring(0, 26) +
              '' +
              moment(calendarDateEnd)
                .format('YYYY-MM-DDTHH:mm:ss.SSS')
                .substring(27, moment(calendarDateEnd).format('YYYY-MM-DDTHH:mm:ss.SSS').length)
          ],
          operators: [OperatorFilterType.BETWEEN, OperatorFilterType.BETWEEN]
        }
      }
    };
  }

  /**
   * Initialise les options du fullcalendar
   */
  private initCalendarOptions() {
    this.config.initialView = this.context && this.context.defaultView ? this.context.defaultView : 'dayGridMonth';
    this.config.initialDate =
      this.context && this.context.dateStart
        ? moment(this.context.dateStart).format('YYYY-MM-DDTHH:mm:ss.SSS')
        : moment().format('YYYY-MM-DDTHH:mm:ss.SSS');
    this.config.headerToolbar = false;
    this.config.locale = this.connectorService.getIupicsDefaultLanguage().iso_code.replace(/_/g, '-');
    this.config.height = this.getRemainingSpaceheight();
    this.config.handleWindowResize = true;
    this.config.windowResize = (arg: { view: ViewApi }) => this.handleWindowResize(arg);
    this.config.editable = true;
    this.config.droppable = true;
    this.config.eventClick = (info: EventClickArg) => this.handleClickEvent(info);
    this.config.eventDragStart = (info: EventDragStartArg) => this.handleDragStartEvent(info);
    this.config.eventDrop = (info: EventDropArg) => this.handleDropEvent(info);
    this.config.dayMaxEventRows = true;
    this.config.firstDay = 1;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.initData();
    }, 100);

    if (!this.initialized && this.fc.nativeElement.offsetParent) {
      this.initialize();
    }
    this.title = this.calendar.view.title;
  }

  showNextPeriod() {
    this.calendar.next();
    this.title = this.calendar.view.title;
    this.changeFilter();
    this.setFilterEmitter.emit(this.filter);
  }

  showPrevPeriod() {
    this.calendar.prev();
    this.title = this.calendar.view.title;
    this.changeFilter(true);
    this.setFilterEmitter.emit(this.filter);
  }

  showToday() {
    this.calendar.today();
    this.title = this.calendar.view.title;
  }

  changeView(view: DayGridType) {
    this.calendar.changeView(view);
    this.title = this.calendar.view.title;
  }

  private getRemainingSpaceheight() {
    return (<HTMLElement>this.el.nativeElement).parentElement.clientHeight - 36 - 32;
  }

  /**
   * Filtre les datas reçues afin de les afficher correctement.
   * L'id de l'event est le JSON.stringify de la datastoreKey de l'enregistrement.
   * Il est stringify car l'id de l'event n'accepte que des string PAS d'objets.
   */
  private updateDisplayDate(columnFilter: string) {
    const dataUUIDs = this.store.getDataUUIDFromTabID(this.tabId);
    this.events = this.datas.map((data) => {
      let title = '';
      let found = false;
      for (let i = 0; i < this.displayColumns.length && !found; i++) {
        if (data[this.displayColumns[i]]) {
          found = true;
          title =
            data[this.displayColumns[i]] instanceof Object
              ? data[this.displayColumns[i]].displayValue
              : data[this.displayColumns[i]];
        }
      }
      let id = '';
      dataUUIDs.forEach((dataUUID: string) => {
        if (data[dataUUID] && id != '') {
          id += ',';
        }
        id += dataUUID + ',' + data[dataUUID];
      });
      const windowId = this.data.AD_window_ID;
      const dataStoreKey = this.store.generateDataStoreKey(windowId, this.tabId, data['Data_UUID'], null);
      const dateStart = data[columnFilter]
        ? (<string>data[columnFilter]).slice(0, data[columnFilter].length - 2)
        : '' + ':' + data[columnFilter]
        ? (<string>data[columnFilter]).slice(data[columnFilter].length - 2, data[columnFilter].length)
        : '';
      return {
        id: JSON.stringify(dataStoreKey),
        title: title,
        start: dateStart && dateStart.length > 10 ? dateStart.slice(0, 10) : dateStart,
        end: dateStart && dateStart.length > 10 ? dateStart.slice(0, 10) : dateStart
      };
    });
    if (this.events && this.calendar) {
      this.calendar.removeAllEventSources();
      this.calendar.addEventSource(this.events);
    }
  }

  /**
   * Gère l'évenement du redimensionnement de la fenêtre
   * @param {ViewApi}view La vue actuellement affichée
   */
  handleWindowResize(arg: { view: ViewApi }) {
    this.calendar.setOption('height', this.getRemainingSpaceheight());
  }

  /**
   * Gère l'évenement d'un click sur un enregistrement dans le calendrier
   * @param {EventClickArg}info
   */
  handleClickEvent(info: EventClickArg) {
    const datastoreKey: DataStoreKey = JSON.parse(info.event.id);
    this.clickEmitter.emit(datastoreKey.recordId);
  }

  /**
   * Gère l'évenement du commencement du drag d'un enregistrement dans le calendrier
   * @param {EventDragStartArg}info
   */
  handleDragStartEvent(info: EventDragStartArg) {}

  /**
   * Gère l'évenement du drop dans une autre case que celle de départ.
   * @param {EventDropArg}info
   */
  handleDropEvent(info: EventDropArg) {
    const datastoreKey: DataStoreKey = JSON.parse(info.event.id);
    if (info.event.start) {
      this.saveData(
        moment(info.event.start).format('YYYY-MM-DDTHH:mm:ss.SSS').substring(0, 26) +
          '' +
          moment(info.event.start)
            .format('YYYY-MM-DDTHH:mm:ss.SSS')
            .substring(27, moment(info.event.start).format('YYYY-MM-DDTHH:mm:ss.SSS').length),
        moment(info.oldEvent.start).format('YYYY-MM-DDTHH:mm:ss.SSS').substring(0, 26) +
          '' +
          moment(info.oldEvent.start)
            .format('YYYY-MM-DDTHH:mm:ss.SSS')
            .substring(27, moment(info.oldEvent.start).format('YYYY-MM-DDTHH:mm:ss.SSS').length),
        datastoreKey,
        this.columnNameDateImpacted,
        info.revert
      );
    }
    if (info.event.end) {
      this.saveData(
        moment(info.event.start).format('YYYY-MM-DDTHH:mm:ss.SSS').substring(0, 26) +
          '' +
          moment(info.event.start)
            .format('YYYY-MM-DDTHH:mm:ss.SSS')
            .substring(27, moment(info.event.start).format('YYYY-MM-DDTHH:mm:ss.SSS').length),
        moment(info.oldEvent.start).format('YYYY-MM-DDTHH:mm:ss.SSS').substring(0, 26) +
          '' +
          moment(info.oldEvent.start)
            .format('YYYY-MM-DDTHH:mm:ss.SSS')
            .substring(27, moment(info.oldEvent.start).format('YYYY-MM-DDTHH:mm:ss.SSS').length),
        datastoreKey,
        this.columnNameDateImpacted,
        info.revert
      );
    }
  }

  /**
   * Permet le detachement du calendar lors du detachement de la split-view
   */
  handleDetach() {
    if (this.calendar && this.calendar.view) {
      this.context = {
        // dateStart: this.calendar.view.dateProfile.currentRange.start,
        dateStart: this.calendar.view.currentStart,
        defaultView: this.calendar.view.type
      };
    }
    this.calendar.destroy();
    this.initialized = false;
    this.renderer.removeStyle(this.container.scrollableElt.nativeElement, 'background-color');
  }

  /**
   * Permet l'insertion du calendar lors de l'insertion de la split-view
   */
  handleInsert() {
    setTimeout(() => {
      this.initialize();
    }, 50);
  }

  /**
   * Enregistre la date en db
   * @param {string}date
   * @param {string}prevDate
   * @param {DataStoreKey}dsKey
   * @param {string}field
   * @param {Function}revert
   */
  saveData(date: string, prevDate: string, dsKey: DataStoreKey, field: string, revert: Function): void {
    const request: DataStoreRequest = {
      windowId: dsKey.windowId,
      record_id: dsKey.recordId,
      parent_constraint: dsKey.parentId,
      compiereRequest: {
        windowType: CompiereDataGridType.WINDOW,
        entityId: dsKey.tabId,
        startRow: 0,
        endRow: 1,
        windowCtx: this.getCurrentContext(),
        validation: this.getTabWhereclause()
      }
    };
    this.subscriptions.push(
      this.store.getWindowSingleData(request).subscribe((response) => {
        const fieldsChanged = {};
        fieldsChanged[field] = date;
        this.store.syncDataChanges(response, fieldsChanged, true);
        this.subscriptions.push(
          this.store.saveWindowData([response.key]).subscribe({
            next: (res) => {
              if (res === null) {
                revert();
              }
            },
            error: (err) => {
              revert();
            }
          })
        );
      })
    );
  }

  onChildUpdate(event: IupicsEvent): void {}
  onSiblingUpdate(event: IupicsEvent): void {}
  onRemoveComponent(event: IupicsEvent): void {}

  /**
   * Initialize full-calendar
   */
  private initialize(): void {
    this.initCalendarOptions();
    this.calendar = new Calendar(this.fc.nativeElement, this.config);
    this.calendar.render();
    this.initialized = true;

    if (this.events) {
      this.calendar.removeAllEventSources();
      this.calendar.addEventSource(this.events);
    }
  }

  /**
   * Retourne le calendar
   * @returns {Calendar} calendar
   */
  getCalendar(): Calendar {
    return this.calendar;
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.calendar) {
      this.calendar.destroy();
      this.initialized = false;
      this.calendar = null;
    }

    this.renderer.removeStyle(this.container.scrollableElt.nativeElement, 'background-color');
  }

  changeFilter(isPrevious = false) {
    const filterDateFound = this.getDateFilter();
    const calendarDateStart = this.calendar.getCurrentData().dateProfile.activeRange.start;
    const calendarDateEnd = this.calendar.getCurrentData().dateProfile.activeRange.end;
    let indexOfFirstBetween = filterDateFound.operators.indexOf(OperatorFilterType.BETWEEN);
    const filterDateStart = new Date(filterDateFound.values[indexOfFirstBetween]);
    const filterDateEnd = new Date(filterDateFound.values[indexOfFirstBetween + 1]);
    if (calendarDateStart < filterDateStart || calendarDateEnd > filterDateEnd) {
      let filterChanged = false;
      if (this.calendar.getCurrentData().currentViewType === 'dayGridMonth') {
        if (isPrevious) {
          filterDateStart.setMonth(filterDateStart.getMonth() - 1);
          filterDateEnd.setMonth(filterDateEnd.getMonth());
          filterChanged = true;
        } else {
          filterDateStart.setMonth(filterDateStart.getMonth());
          filterDateEnd.setMonth(filterDateEnd.getMonth() + 1);
          filterChanged = true;
        }
      } else if (this.calendar.getCurrentData().currentViewType === 'dayGridWeek') {
        if (isPrevious) {
          if (filterDateStart.getMonth() > calendarDateEnd.getMonth()) {
            filterDateStart.setMonth(calendarDateEnd.getMonth());
            filterDateStart.setDate(1);
            filterDateEnd.setMonth(calendarDateEnd.getMonth() + 2);
            filterDateEnd.setDate(1);
            filterDateEnd.setDate(filterDateEnd.getDate() - 1);
            filterChanged = true;
          } else if (filterDateStart.getMonth() > calendarDateStart.getMonth()) {
            filterDateStart.setMonth(calendarDateStart.getMonth());
            filterDateStart.setDate(1);
            filterDateEnd.setMonth(calendarDateStart.getMonth() + 2);
            filterDateEnd.setDate(1);
            filterDateEnd.setDate(filterDateEnd.getDate() - 1);
            filterChanged = true;
          }
        } else {
          if (filterDateEnd.getMonth() < calendarDateEnd.getMonth()) {
            filterDateStart.setMonth(calendarDateEnd.getMonth() - 1);
            filterDateStart.setDate(1);
            filterDateEnd.setMonth(calendarDateEnd.getMonth() + 1);
            filterDateEnd.setDate(1);
            filterDateEnd.setDate(filterDateEnd.getDate() - 1);
            filterChanged = true;
          } else if (filterDateEnd.getMonth() < calendarDateStart.getMonth()) {
            filterDateStart.setMonth(calendarDateStart.getMonth() - 1);
            filterDateStart.setDate(1);
            filterDateEnd.setMonth(calendarDateStart.getMonth() + 1);
            filterDateEnd.setDate(1);
            filterDateEnd.setDate(filterDateEnd.getDate() - 1);
            filterChanged = true;
          }
        }
      } else if (this.calendar.getCurrentData().currentViewType === 'dayGridDay') {
        if (isPrevious) {
          if (filterDateStart.getMonth() > calendarDateEnd.getMonth()) {
            filterDateStart.setMonth(calendarDateEnd.getMonth());
            filterDateStart.setDate(1);
            filterDateEnd.setMonth(calendarDateEnd.getMonth() + 2);
            filterDateEnd.setDate(1);
            filterDateEnd.setDate(filterDateEnd.getDate() - 1);
            filterChanged = true;
          } else if (filterDateStart.getMonth() > calendarDateStart.getMonth()) {
            filterDateStart.setMonth(calendarDateStart.getMonth());
            filterDateStart.setDate(1);
            filterDateEnd.setMonth(calendarDateStart.getMonth() + 2);
            filterDateEnd.setDate(1);
            filterDateEnd.setDate(filterDateEnd.getDate() - 1);
            filterChanged = true;
          }
        } else {
          if (filterDateEnd.getMonth() < calendarDateEnd.getMonth()) {
            filterDateStart.setMonth(calendarDateEnd.getMonth() - 1);
            filterDateStart.setDate(1);
            filterDateEnd.setMonth(calendarDateEnd.getMonth() + 1);
            filterDateEnd.setDate(1);
            filterDateEnd.setDate(filterDateEnd.getDate() - 1);
            filterChanged = true;
          } else if (filterDateEnd.getMonth() < calendarDateStart.getMonth()) {
            filterDateStart.setMonth(calendarDateStart.getMonth() - 1);
            filterDateStart.setDate(1);
            filterDateEnd.setMonth(calendarDateStart.getMonth() + 1);
            filterDateEnd.setDate(1);
            filterDateEnd.setDate(filterDateEnd.getDate() - 1);
            filterChanged = true;
          }
        }
      }

      if (filterChanged) {
        indexOfFirstBetween = filterDateFound.operators.indexOf(OperatorFilterType.BETWEEN);
        filterDateFound.values[indexOfFirstBetween] = moment(filterDateStart).format('YYYY-MM-DD');
        filterDateFound.values[indexOfFirstBetween + 1] = moment(filterDateEnd).format('YYYY-MM-DD');
        // TODO REMOVE WHEN FILTER COMPONENT ACCEPT FILTER FROM COMPONENT
        // this.applyFilter(this.filter);
      }
    }
  }

  applyFilter(filter: CompiereDataGridRequestJSON) {
    this.filter = filter;
    this.queryDataStore(this.filter);
  }

  queryDataStore(filter?: any) {
    const dataStoreRequest: DataStoreRequest = {
      windowId: (<BladeUiComponent>this.container).infoComponent.windowId,
      compiereRequest: {
        windowType: CompiereDataGridType.WINDOW,
        entityId: this.tabId,
        startRow: 0,
        endRow: 0,
        windowCtx: this.getCurrentContext(),
        validation: this.getTabWhereclause()
      }
    };
    if (filter) {
      dataStoreRequest.compiereRequest.filterModel = this.filter.filterModel;
      dataStoreRequest.compiereRequest.rowGroupCols = [];
      dataStoreRequest.compiereRequest.sortModel = this.filter.sortModel;
    }

    this.isGetDatagridInProgress = true;
    this.subscriptions.push(
      this.store
        .getDataGrid(dataStoreRequest)
        .pipe(tap((_) => (this.isGetDatagridInProgress = false)))
        .subscribe((response) => {
          if (response && response.data) {
            this.datas = response.data;
            if (Object.keys(filter.filterModel).length > 0) {
              this.updateDisplayDate(Object.keys(filter.filterModel)[0].replace(/"/g, ''));
            }
          }
        })
    );
  }
  getDateFilter() {
    let filterDateFound = null;
    if (this.filter && this.filter.filterModel) {
      Object.keys(this.filter.filterModel).forEach((key) => {
        if (this.filter.filterModel[key].filterType == CompiereDataGridFilterType.DATE) {
          if (!this.filter.filterModel[key].operators.find((operator) => operator === OperatorFilterType.BETWEEN)) {
            this.filter = {
              startRow: 0,
              endRow: 0,
              entityId: this.tabId,
              windowType: CompiereDataGridType.WINDOW,
              filterModel: this.filter && this.filter.filterModel ? this.filter.filterModel : {},
              sortModel: this.filter && this.filter.sortModel ? this.filter.sortModel : [],
              rowGroupCols: this.filter && this.filter.rowGroupCols ? this.filter.rowGroupCols : [],
              valueCols: this.filter && this.filter.valueCols ? this.filter.valueCols : []
            };
            const calendarDateStart = this.calendar.getCurrentData().dateProfile.activeRange.start;
            const calendarDateEnd = this.calendar.getCurrentData().dateProfile.activeRange.end;
            this.filter.filterModel[key].operators.push(...[OperatorFilterType.BETWEEN, OperatorFilterType.BETWEEN]);
            this.filter.filterModel[key].values.push(
              ...[
                moment(calendarDateStart).format('YYYY-MM-DDTHH:mm:ss.SSS').substring(0, 26) +
                  '' +
                  moment(calendarDateStart)
                    .format('YYYY-MM-DDTHH:mm:ss.SSS')
                    .substring(27, moment(calendarDateStart).format('YYYY-MM-DDTHH:mm:ss.SSS').length),
                moment(calendarDateEnd).format('YYYY-MM-DDTHH:mm:ss.SSS').substring(0, 26) +
                  '' +
                  moment(calendarDateEnd)
                    .format('YYYY-MM-DDTHH:mm:ss.SSS')
                    .substring(27, moment(calendarDateEnd).format('YYYY-MM-DDTHH:mm:ss.SSS').length)
              ]
            );
          }
          filterDateFound = this.filter.filterModel[key];
          this.columnNameDateImpacted = key;
          return;
        }
      });
    }
    if (!filterDateFound && this.defaultColumn) {
      this.initFilter();
      filterDateFound = this.filter.filterModel[this.defaultColumn.name];
      this.columnNameDateImpacted = this.defaultColumn.name;
    }
    return filterDateFound;
  }
  getCurrentContext() {
    if (this.DOMParentComponent) {
      return (<GridViewUiComponent>this.DOMParentComponent).getCurrentContext();
    } else return null;
  }
  getTabWhereclause() {
    if (this.DOMParentComponent) {
      return (<GridViewUiComponent>this.DOMParentComponent).getTabWhereclause();
    } else return null;
  }
}
