import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { ViewType } from '@iupics-components/models/view-type.enum';
import { ProcessUiComponent } from '@iupics-components/specific/window/process-ui/process-ui.component';
import { SpecificWindowUiComponent } from '@iupics-components/specific/window/specific-window-ui/specific-window-ui.component';
import { GridViewUiComponent } from '@iupics-components/standard/grid/grid-view-ui/grid-view-ui.component';
import { BladeUiComponent } from '@iupics-components/standard/layouts/blade-ui/blade-ui.component';
import { CacheManagerService } from '@iupics-manager/managers/cache-manager/cache-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { Global } from '@iupics-manager/models/global-var';
import { Utils } from '@iupics-util/tools/util';
import { TabUiComponent } from '@web-desktop/components/menu-top/components/tab-ui/tab-ui.component';

@Component({
  selector: 'iu-tab-split-view-content-ui',
  templateUrl: './tab-split-view-content-ui.component.html',
  styleUrls: ['./tab-split-view-content-ui.component.scss']
})
export class TabSplitViewContentUiComponent implements OnInit, AfterViewInit {
  constructor(protected uiCreator: UICreatorService) {}
  @Input()
  tab: TabUiComponent;
  // contents: any[] = [];
  @ViewChild('content', { read: ViewContainerRef, static: true })
  vcrContent: ViewContainerRef;
  @ViewChild('contentParent', { static: true })
  contentParent: ElementRef;
  @Input()
  draggedSplitArea: any;
  @Input()
  elementSwapped: any = null;
  @Input()
  activeTab: any;
  @Output()
  dragStartEmitter: EventEmitter<any> = new EventEmitter();
  @Output()
  dragEndEmitter: EventEmitter<any> = new EventEmitter();
  @Output()
  swapViewEmitter: EventEmitter<any> = new EventEmitter();
  contentRef: any;

  isMobile = Global.isMobile();
  isMobileWidth = Global.isMobileWidth;

  ngOnInit() {
    if (this.tab.menu_type === 'Window') {
      this.createBlade(this.tab);
    } else if (this.tab.menu_type === 'Form') {
      this.createSpecificWindow(this.tab);
    } else {
      this.createProcess(this.tab);
    }
  }

  ngAfterViewInit() {
    if (this.contentRef.instance instanceof BladeUiComponent && this.activeTab.viewType) {
      const gridRef: GridViewUiComponent = <GridViewUiComponent>(
        (<BladeUiComponent>this.contentRef.instance).DOMChildrenComponent.find(
          (compoRef) => compoRef instanceof GridViewUiComponent
        )
      );
      // vérifie si le grid supporte la vue tre sinon grid par défaut
      if (this.activeTab.viewType === ViewType.TREE) {
        if (!gridRef.isTree) {
          this.activeTab.viewType = ViewType.GRID;
        }
      }
      gridRef.viewType = this.activeTab.viewType;
    }
    if (this.contentRef.instance instanceof BladeUiComponent && this.activeTab.openedRecordId) {
      const gridRef: GridViewUiComponent = <GridViewUiComponent>(
        (<BladeUiComponent>this.contentRef.instance).DOMChildrenComponent.find(
          (compoRef) => compoRef instanceof GridViewUiComponent
        )
      );
      // check si l'on veut un nouveau record ou une ouverture d'un record existant
      setTimeout(() => {
        if (this.activeTab.openedRecordId === 'newRecord') {
          gridRef.openNew();
        } else {
          gridRef.forceOpenEditView(this.activeTab.openedRecordId, this.activeTab.zoomInfo);
          gridRef.selectedRecordId = this.activeTab.openedRecordId;
        }
      }, 800);
    }
    this.notifyUrlChange();
  }

  updateBlade() {
    if (this.contentRef.instance instanceof BladeUiComponent) {
      (<BladeUiComponent>this.contentRef.instance).setScroll();
    }
  }

  notifyUrlChange() {
    if (this.contentRef.instance instanceof BladeUiComponent) {
      (<BladeUiComponent>this.contentRef.instance).notifyUrlChange();
    } else if (this.contentRef.instance instanceof ProcessUiComponent) {
      (<ProcessUiComponent>this.contentRef.instance).notifyUrlChange();
    } else {
      Utils.onTabChange(this.activeTab);
    }
  }

  createBlade(item) {
    const componentRef = this.vcrContent.createComponent(BladeUiComponent);
    (<BladeUiComponent>componentRef.instance).infoComponent = {
      name: item.name,
      windowId: item.windowId
    };
    (<BladeUiComponent>componentRef.instance).activeTab = this.activeTab;
    this.contentRef = componentRef;
  }

  createProcess(item) {
    const componentRef = this.vcrContent.createComponent(ProcessUiComponent);
    (<ProcessUiComponent>componentRef.instance).name = item.name;
    (<ProcessUiComponent>componentRef.instance).appName = item.appName;
    (<ProcessUiComponent>componentRef.instance).iconClass = item.iconClass;
    (<ProcessUiComponent>componentRef.instance).componentRef = componentRef;
    (<ProcessUiComponent>componentRef.instance).bounds = this.contentParent.nativeElement;
    (<ProcessUiComponent>componentRef.instance).inBounds = true;
    (<ProcessUiComponent>componentRef.instance).id = item.id;
    (<ProcessUiComponent>componentRef.instance).windowId = item.windowId;
    (<ProcessUiComponent>componentRef.instance).vcrwindow = this.vcrContent;
    (<ProcessUiComponent>componentRef.instance).index = this.vcrContent.length - 1;
    (<ProcessUiComponent>componentRef.instance).activeTab = this.activeTab;
    this.contentRef = componentRef;
  }

  createSpecificWindow(item) {
    let component;
    if (item.angularClass && item.angularClass.length > 0 && item.angularClass !== 'default') {
      component = CacheManagerService.iupics_specific_window.get(item.angularClass);
    }
    if (!component) {
      component = CacheManagerService.iupics_specific_window.get('default');
    }
    this.uiCreator.getSpecificWindow(item.windowId).subscribe((specificWindow) => {
      const componentRef = this.vcrContent.createComponent(component);
      (<SpecificWindowUiComponent>componentRef.instance).specificWindowTitle = specificWindow.name;
      (<SpecificWindowUiComponent>componentRef.instance).name = specificWindow.name;
      (<SpecificWindowUiComponent>componentRef.instance).title = specificWindow.title;
      (<SpecificWindowUiComponent>componentRef.instance).description = specificWindow.description;
      (<SpecificWindowUiComponent>componentRef.instance).help = specificWindow.help;
      (<SpecificWindowUiComponent>componentRef.instance).iconClass = item.iconClass;
      (<SpecificWindowUiComponent>componentRef.instance).componentRef = componentRef;
      (<SpecificWindowUiComponent>componentRef.instance).id = item.id;
      (<SpecificWindowUiComponent>componentRef.instance).formId = item.windowId;
      (<SpecificWindowUiComponent>componentRef.instance).vcrwindow = this.vcrContent;
      (<SpecificWindowUiComponent>componentRef.instance).index = this.vcrContent.length - 1;
      (<SpecificWindowUiComponent>componentRef.instance).activeTab = this.activeTab;
      this.contentRef = componentRef;
    });
  }

  closeInMobile(event: Event) {
    event.stopPropagation();
    this.tab.handleRemove();
  }
}
