import { keyframes } from '@angular/animations';
import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { SmartButtonUiComponent } from '@iupics-components/standard/fields/smart-button-ui/smart-button-ui.component';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { IupicsTypeEvent } from '@iupics-manager/models/iupics-event';

@Component({
  selector: 'iu-menu-smartbuttons-ui',
  templateUrl: './menu-smartbuttons-ui.component.html',
  styleUrls: ['./menu-smartbuttons-ui.component.scss']
})
export class MenuSmartbuttonsUiComponent implements OnInit {
  targetsWindow = [];
  widthAvailable: number;
  isMoreButton = true;
  smartButtons: any[];
  defaultWidthAvailable: number;

  tabId: number;
  recordId: string;
  @ViewChild('smartButtonsVCR', { read: ViewContainerRef, static: true })
  smartButtonsVCR: ViewContainerRef;

  @ViewChild('smartButtonsMoreVCR', { read: ViewContainerRef, static: true })
  smartButtonsMoreVCR: ViewContainerRef;

  @Output()
  smartButtonClick: EventEmitter<any> = new EventEmitter();
  subscription: any;
  constructor(private ref: ElementRef, private uiCreator: UICreatorService) {}

  ngOnInit() {
    this.isMoreButton = false;
  }

  toggleButton(id) {
    const buttonFound = this.smartButtons.find((b) => b.id == id);
    if (buttonFound) {
      buttonFound.isOpened = !buttonFound.isOpened;
    }
  }
  refreshSmartButtons(tabId: number, recordId: string) {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    let selectChanged = false;
    if (tabId != this.tabId || recordId != this.recordId) {
      this.tabId = tabId;
      this.recordId = recordId;
      selectChanged = true;
    }
    this.subscription = this.uiCreator
      .getZoomTarget(tabId, recordId.split(',')[0], parseFloat(recordId.split(',')[1]))
      .subscribe((smartButtons: any[]) => {
        this.smartButtonsVCR.clear();
        if (this.smartButtonsMoreVCR) {
          this.smartButtonsMoreVCR.clear();
        }
        this.isMoreButton = false;
        this.defaultWidthAvailable = this.ref.nativeElement.clientWidth;
        this.widthAvailable = this.defaultWidthAvailable;
        smartButtons.forEach((smartButton: any) => {
          smartButton.isOpened = false;
          this.addSmartButtonToView(smartButton);
        });
        if (this.smartButtons) {
          this.smartButtons
            .filter((sb) => sb.isOpened)
            .forEach((sb2) => {
              let buttonFound = smartButtons.find((sbToFind) => sbToFind.key == sb2.key);
              if (buttonFound) {
                buttonFound.isOpened = true;
                if (selectChanged) {
                  this.smartButtonClick.emit({ key: keyframes, val: buttonFound });
                }
              }
            });
        }
        this.smartButtons = smartButtons;
        this.subscription.unsubscribe();
      });
  }

  addSmartButtonToView(smartButton: any) {
    const componentRef = this.smartButtonsVCR.createComponent(SmartButtonUiComponent);
    (<SmartButtonUiComponent>componentRef.instance).label = smartButton.name;
    (<SmartButtonUiComponent>componentRef.instance).key = smartButton.key;
    (<SmartButtonUiComponent>componentRef.instance).id = smartButton.id;
    (<SmartButtonUiComponent>componentRef.instance).iconClass = smartButton.icon;
    (<SmartButtonUiComponent>componentRef.instance).smartButtonClick.subscribe((val) => {
      this.toggleButton(val.key);
      this.smartButtonClick.emit({ key: keyframes, val: val });
    });
    if (this.widthAvailable - (<SmartButtonUiComponent>componentRef.instance).buttonWidth > 0) {
      this.widthAvailable = this.widthAvailable - (<SmartButtonUiComponent>componentRef.instance).buttonWidth;
    } else {
      this.smartButtonsVCR.detach(this.smartButtonsVCR.indexOf(componentRef.hostView));
      this.smartButtonsMoreVCR.insert(componentRef.hostView);
      this.widthAvailable = 0;
      this.isMoreButton = true;
    }
  }

  resizeSmartButton(type: IupicsTypeEvent) {
    if (type === IupicsTypeEvent.collapseEvent && this.smartButtons) {
      this.widthAvailable = 549;
      this.smartButtonsVCR.clear();
      if (this.smartButtonsMoreVCR) {
        this.smartButtonsMoreVCR.clear();
      }
      this.isMoreButton = false;
      this.smartButtons.forEach((smartButton: any) => {
        this.addSmartButtonToView(smartButton);
      });
    } else if (type === IupicsTypeEvent.expandEvent && this.smartButtons) {
      this.widthAvailable = this.defaultWidthAvailable;
      this.smartButtonsVCR.clear();
      if (this.smartButtonsMoreVCR) {
        this.smartButtonsMoreVCR.clear();
      }
      this.isMoreButton = false;
      this.smartButtons.forEach((smartButton: any) => {
        this.addSmartButtonToView(smartButton);
      });
    }
  }
}
