import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { CompiereProcessService } from '@compiere-ws/services/compiere-process/compiere-process.service';
import { ResourceAccessService } from '@compiere-ws/services/resource-access/resource-access.service';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AccesRessourceGuard implements CanActivateChild {
  component = { id: null, recordId: null, type: null, viewData: null };
  constructor(
    private router: Router,
    private uiCreatorService: UICreatorService,
    private processService: CompiereProcessService,
    private resourceAccessService: ResourceAccessService,
    private store: DataStoreService
  ) {}
  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.checkRes(next);
  }
  /**
   * @param route it contains url params
   * @param state it contains the url
   * @description

check whether the current user has the authorization to access to the specified resource*/
  private checkRes(route: ActivatedRouteSnapshot): Observable<boolean> | boolean {
    const data = route.paramMap;
    let type = 'W';
    if (data.get('windowId')) {
      this.component.id = +data.get('windowId');
    } else if (data.get('processId')) {
      this.component.id = +data.get('processId');
      type = 'P';
    } else if (data.get('formId')) {
      this.component.id = +data.get('formId');
      type = 'X';
    } else if (data.get('reportId')) {
      this.component.id = +data.get('reportId');
      type = 'P';
      //
    } else {
      return true;
    }
    return this.checkResourceAccess(type, this.component.id);
  }

  /**
   * @param id id
   * @description check whether the current user has access to the resource which has the specified id
   */
  private checkResourceAccess(type: string, id: number): Observable<boolean> {
    return new Observable((obs) =>
      this.resourceAccessService.getAccess(type, id).subscribe({
        next: (success) => {
          obs.next(success);
        },
        error: (error) => {
          this.router.navigate(['/accesUnauthorized'], { queryParams: { message: 'pagenotfound.no_ressource' } });
          obs.next(false);
        }
      })
    );
  }
}
