import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewEncapsulation
} from '@angular/core';
import { PoService } from '@compiere-ws/services/po/po.service';
import { CacheManagerService } from '@iupics-manager/managers/cache-manager/cache-manager.service';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { AbstractDataContainer } from '@iupics-manager/models/abstract-datacontainer';
import { IupicsDataField } from '@iupics-manager/models/iupics-data';
import { ContextMenuService } from '@web-desktop/components/workspace/controllers/context-menu/context-menu.service';

@Component({
  selector: 'iu-input-chips-ui',
  templateUrl: './input-chips-ui.component.html',
  styleUrls: ['./input-chips-ui.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InputChipsUiComponent extends AbstractDataContainer implements OnInit, AfterViewInit {
  @Input()
  columnName: string;
  @Output()
  fieldValueChange = new EventEmitter<any>();

  @Input() data: IupicsDataField;
  @Input() inputType = 'text';
  @Input() addOnBlur = false;
  @Input() addOnEnter = true;
  @Input() addOnTab = true;
  @Input() addOnSpace = false;
  @Input() editOnBackspace = false;
  @Input() matchPattern: RegExp = /.*/;
  @Input() inputStyle: { [key: string]: string };
  @Input() inContainer = false;

  constructor(
    public elementRef: ElementRef,
    public store: DataStoreService,
    protected connectorService: SecurityManagerService,
    public uiCreatorService: UICreatorService,
    public cmService: ContextMenuService,
    renderer: Renderer2,
    protected po: PoService,
    protected cacheService: CacheManagerService
  ) {
    super(elementRef, connectorService, cmService, store, uiCreatorService, renderer, po, cacheService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.cssGrid = this.cssClass;
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
  }

  onValueChange(event: any) {
    event.stopPropagation();
    this.fieldValueChange.emit(this.fieldValue);
  }
}
