import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { ChatTag } from '@iupics-components/standard/notes/notes.interface';
import { TranslateService } from '@ngx-translate/core';
import Tagify, { TagData } from '@yaireo/tagify';
import { TagifyService, TagifySettings } from 'ngx-tagify';
import { BehaviorSubject } from 'rxjs';
import { v4 as uuid } from 'uuid';

@Component({
  selector: 'iu-input-mention-ui',
  templateUrl: './input-mention-ui.component.html',
  styleUrls: ['./input-mention-ui.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InputMentionUiComponent implements OnInit, AfterViewInit {
  @Input() appendTarget: HTMLElement;
  @Input() readonly: boolean;
  @Input() tagExpansion: boolean;
  @Input() type: InputMentionType = InputMentionType.INPUT;
  @Input() sendIcon: boolean;
  @Output() enterEmitter = new EventEmitter<MouseEvent>();

  /* Tagify */
  @Input() tags: BehaviorSubject<ChatTag[]>;
  @Input() content: string;
  @Input() tagifyOptions: TagifySettings;
  tagifyId = uuid();
  tagify: Tagify;

  isLoading = true;

  @ViewChild('inputMentionWrapper') inputMentionWrapper: ElementRef<HTMLElement>;

  constructor(private tagifyService: TagifyService, private translate: TranslateService) {}

  ngOnInit(): void {
    this.setDefaultOptionsForType();
    this.tagifyConfiguration();
  }

  ngAfterViewInit(): void {
    this.tagify = this.tagifyService.get(this.tagifyId);
    this.tagify.settings.dropdown.appendTarget = this.inputMentionWrapper.nativeElement;
    this.setListeners();
    if (this.readonly && this.tagifyOptions.mode === 'mix') {
      //! In mix mod, value is not loaded by default
      this.tagify.loadOriginalValues(this.content);
    }
  }

  tagifyConfiguration() {
    this.tagifyOptions = {
      mode: 'mix',
      pattern: /@/,
      enforceWhitelist: true,
      duplicates: true,
      placeholder: this.translate.instant('inputMention.emptyInput'),
      editTags: false,
      createInvalidTags: false,
      tagTextProp: 'displayValue',
      ...this.tagifyOptions, // override first level
      dropdown: {
        enabled: 0,
        position: 'input',
        classname: 'input-mention-tag-list',
        mapValueTo: 'displayValue', // <-- similar to above "tagTextProp" setting, but for the dropdown items
        searchKeys: ['displayValue'],
        highlightFirst: true, // automatically highlights first suggestion item in the dropdown
        includeSelectedTags: true,
        maxItems: 10,
        ...(this.tagifyOptions?.dropdown ?? {}) // override dropdown level
      },
      texts: {
        duplicate: this.translate.instant('inputMention.empty'),
        empty: this.translate.instant('inputMention.exceed'),
        exceed: this.translate.instant('inputMention.pattern'),
        notAllowed: this.translate.instant('inputMention.duplicate'),
        pattern: this.translate.instant('inputMention.notAllowed'),
        ...(this.tagifyOptions?.texts ?? {}) // override text level
      },
      templates: {
        tag: this.tagTemplate.bind(this),
        dropdownItem: this.tagListItemTemplate,
        dropdownItemNoMatch: this.tagListNoMatchTemplate.bind(this),
        dropdownFooter: this.tagListFooterTemplate.bind(this),
        ...(this.tagifyOptions?.templates ?? {}) // override template level
      },
      callbacks: {
        ...(this.tagifyOptions?.callbacks ?? {}) // override callback level
      }
    };
  }

  setDefaultOptionsForType() {
    switch (this.type) {
      case InputMentionType.INPUT:
        this.tagExpansion = false;
        break;

      case InputMentionType.TEXT:
        this.readonly = true;
        this.sendIcon = false;
        this.tagExpansion = true;
        break;
      case InputMentionType.NOTIFICATION:
        this.readonly = true;
        this.sendIcon = false;
        this.tagExpansion = false;
        break;
      default:
        break;
    }
  }

  /* Listeners */
  setListeners() {
    if (!this.readonly) {
      this.tagify.on('keydown', this.onTagifyKeyDown.bind(this));
      return;
    }
  }

  onTagifyKeyDown(e: CustomEvent<Tagify.KeydownEventData>) {
    // TODO: find a way to prevent new line before sending the note
    // if (e.detail.event.shiftKey) {
    //   return;
    // }
    // const tagify = <any>this.tagify;
    // if (
    //   e.detail.event.key == 'Enter' && // "enter" key pressed
    //   !tagify.state.tag &&
    //   !tagify.state.inputText && // assuming user is not in the middle oy adding a tag
    //   !tagify.state.editing // user not editing a tag
    // ) {
    //   this.enterEmitter.emit(null);
    // }
  }

  /* Tag template */
  tagTemplate(tagData: TagData, tagify: Tagify) {
    return `
        <tag
          contenteditable='false'
          spellcheck='false'
          tabIndex="-1"
          class="tagify__tag ${tagData.class ? tagData.class : ''}"
          ${tagify.getAttributes(tagData)}>
            <iu-tag-content-element
              user-id="${tagData.userID}"
              value="${tagData.displayValue}"
              picture-url="${tagData.pictureURL}"
              prefix="${tagData.prefix}"
              tag-expansion="${this.tagExpansion}"
              data-cy="tag-content"
            >
            </iu-tag-content-element>
        </tag>
    `;
  }

  /* Tag list template */
  tagListItemTemplate(tagData: TagData) {
    return `
      <div
        ${(<any>this).getAttributes(tagData)}
        class="tagify__dropdown__item ${tagData.class ? tagData.class : ''}"
        tabindex="0"
        role="option"
        data-cy="input-mention-dropdown"
      >
        <iu-tag-list-item-element
          user-id="${tagData.userID}"
          value="${tagData.mappedValue}"
          picture-url="${tagData?.pictureURL ?? ''}"
          data-cy="tag-list-item"
        >
        </iu-tag-list-item-element>
      </div>
    `;
  }

  tagListNoMatchTemplate(data: { value: string }) {
    // Don't show list if no match
    return '';
    // return `
    //   <div class="${this.tagify.settings.classNames.dropdownItem}" value="noMatch" tabindex="0" role="option">
    //     <iu-tag-list-item-element
    //       no-match="true"
    //       value="${data.value}"
    //     >
    //     </iu-tag-list-item-element>
    //   </div>
    // `;
  }

  tagListFooterTemplate(suggestions: TagData[]) {
    const hasMore = suggestions.length - this.tagifyOptions.dropdown.maxItems;

    if (hasMore > 0) {
      return `
        <footer data-selector='tagify-suggestions-footer' class="${this.tagify.settings.classNames.dropdownFooter}">
          <span>${hasMore ?? '?'} ${this.translate.instant('inputMention.tagListFooter.more')}</span>
        </footer>
      `;
    }

    return ``;
  }

  /* Input content */
  getContent() {
    return this.tagify.getInputValue() ?? '';
  }

  getMentions() {
    return this.tagify.getCleanValue();
  }

  setContent(value?: string) {
    this.tagify.DOM.originalInput.value = value ?? '';
  }
}

export enum InputMentionType {
  INPUT = 'input',
  TEXT = 'text',
  NOTIFICATION = 'notification'
}
