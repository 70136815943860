import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { CompiereDataGridFilterType, CompiereDataGridType } from '@compiere-ws/models/compiere-data-json';
import { OperatorFilterType } from '@iupics-components/models/universal-filter';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { StatisticsCardData, StatisticsCardType } from './statistics-card-data';

@Component({
  selector: 'iu-statistics-card',
  templateUrl: './statistics-card.component.html',
  styleUrls: ['./statistics-card.component.scss']
})
export class StatisticsCardComponent implements OnInit, AfterViewInit {
  @Input() data: any;
  @Input() C_BPartner_ID: number;

  stats: StatisticsCardData = null;
  formatter: Intl.NumberFormat;

  animatedData: any = {};
  animateData = false;

  constructor(private store: DataStoreService, protected connectorService: SecurityManagerService) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.getStatistic();
  }

  getStatistic() {
    if (!this.data?.data?.columnName) {
      return;
    }

    const sub = this.store
      .getDataGrid({
        windowId: undefined,
        parent_constraint: null,
        compiereRequest: {
          windowType: CompiereDataGridType.TABLE,
          tableName: this.data?.data?.columnName,
          filterModel: {
            C_BPartner_ID: {
              filterType: CompiereDataGridFilterType.SET,
              operators: [OperatorFilterType.EQUALS],
              values: [this.C_BPartner_ID]
            }
          }
        }
      })
      .subscribe({
        next: (res) => {
          const data = res?.data?.[0] ?? null;
          this.formatter = Intl.NumberFormat(this.connectorService.getIupicsUserContext()['#AD_Language'].slice(0, 2), {
            notation: 'compact',
            style: 'currency',
            currency: data?.['Currency'] ?? this.connectorService.getIupicsUserContext()['$CurrencyISO']
          });

          let statsType = this.data?.type ?? StatisticsCardType.NORMAL;
          if (data?.['COUNT']) {
            statsType = StatisticsCardType.COUNT;
          }

          this.stats = {
            label: this.data.data.label,
            pct: data?.['Pct'] ?? 0,
            indicator: data?.['Indicator'] ?? '+',
            amount: data?.['Amount'] ?? 0,
            count: data?.['COUNT'] ?? 0
          };

          this.stats.type = statsType;

          sub.unsubscribe();
        }
      });
  }

  setAnimateDataState(event: any) {
    if (!event) {
      this.animateData = true;
    }
  }

  bindAnimatedValue(value: number, columnName: string) {
    this.animatedData[columnName] = value;
  }
}
