import { Component } from '@angular/core';
import { GridViewUiComponent } from '@iupics-components/standard/grid/grid-view-ui/grid-view-ui.component';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-enterprise';

@Component({
  selector: 'iu-button-renderer',
  template: ` <button type="button" (click)="onClick($event)" class="p-button p-button-secondary">{{ label }}</button> `
})
export class ButtonRendererComponent implements ICellRendererAngularComp {
  params: any;
  label: string;
  templates: any;
  activeGridView: GridViewUiComponent;

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.label = this.params.label || this.params.colDef.field;
    this.templates = this.params.templates;
    this.activeGridView = this.params.activeGridView;
  }

  refresh(params?: ICellRendererParams): boolean {
    return true;
  }

  onClick($event: MouseEvent) {
    if (!this.params.node.isSelected()) {
      this.params.node.setSelected(true); // select row
    }

    if (this.params.onSelect instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        rowData: this.params.node.data,
        columnName: this.params.colDef.field
        // ...something
      };
      this.params.onSelect({ ...this.params, ...params });
    }
  }
}
