<div [style.width]="'calc(100% - 3px)'" [style.height]="'100%'">
  <ag-grid-angular
    #agGrid
    style="width: 100%; height: 100%"
    id="myGrid"
    class="ag-theme-balham"
    [pagination]="true"
    [paginationAutoPageSize]="true"
    [animateRows]="false"
    [suppressColumnVirtualisation]="true"
    [columnDefs]="columnsTableHeader"
    [multiSortKey]="'ctrl'"
    (gridReady)="gridReady()"
    [sideBar]="'columns'"
    [rowData]="rowsData"
    [localeText]="localeText"
  ></ag-grid-angular>
</div>
