<div class="mainDiv">
  <div class="content page-404">
    <img src="assets/iupics/img/logo-apiz.svg" alt="Apiz" width="150px" />

    <div></div>
    <div></div>
    <div></div>
    <div>
      <h1 class="message">{{ message | translate }}</h1>
    </div>
    <div>
      <button routerLink="/dashboard" class="p-button p-button-primary">Retour au dashboard</button>
    </div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</div>
