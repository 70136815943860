<div #riListElt class="recent-items">
  <ng-container *ngIf="recentItems$ | async as recentItems; riLoading">
    <div *ngFor="let item of recentItems" class="recent-item" (click)="openTab($event, item)">
      {{ item.name }}
    </div>
  </ng-container>
</div>

<ng-template #riLoading>
  <div class="recent-item">Loading...</div>
</ng-template>
