import { EventEmitter, Injectable } from '@angular/core';
import {
  CompiereNotification,
  CompiereNotificationType,
  CompiereUINotificationWS
} from '@compiere-ws/models/compiere-notification-json';
import { CompiereNotificationService } from '@compiere-ws/services/compiere-notification/compiere-notification.service';
import { PrintReportService } from '@compiere-ws/services/compiere-print-report/print-report.service';
import { SocketService } from '@compiere-ws/services/socket/socket.service';
import { Global } from '@iupics-manager/models/global-var';
import { MessageService } from 'primeng/api';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class NotificationManagerService {
  onChangeRoleChannel = new EventEmitter<any>();
  onRoleChanged = new EventEmitter<any>();
  private syncWaitingNotifications: {
    [key: string]: Subject<CompiereNotification>;
  } = {};

  constructor(
    private printReportService: PrintReportService,
    private messageService: MessageService,
    private compiereNotificationService: CompiereNotificationService,
    private socketService: SocketService
  ) {}

  getNotifications(): Observable<CompiereUINotificationWS> {
    return this.compiereNotificationService.getNotifications();
  }

  handleNotification(notif: CompiereNotification): Observable<CompiereNotification> {
    return this.compiereNotificationService.handleNotification(notif);
  }

  handleAllNotification(): Observable<CompiereNotification> {
    return this.compiereNotificationService.handleAllNotification();
  }

  closeNotification(notif: CompiereNotification): Observable<CompiereNotification> {
    return this.compiereNotificationService.closeNotification(notif);
  }

  closeAllNotification(type: CompiereNotificationType): Observable<CompiereNotification> {
    return this.compiereNotificationService.closeAllNotification(type);
  }

  changeRoleChannel(previous: number, next: number): void {
    this.onChangeRoleChannel.emit({ previousRole: previous, nextRole: next });
  }

  roleChanged(): void {
    this.onRoleChanged.emit();
  }

  popNotification(...notifications: CompiereNotification[]) {
    notifications.forEach((notification) => {
      this.messageService.add({
        summary: notification.title,
        detail: notification.summary,
        data: { notification: notification, type: 'Notification' }
      });
    });
  }

  syncWithNotification(channelId: string): Subject<CompiereNotification> {
    this.syncWaitingNotifications[channelId] = new Subject();
    (async () => {
      for await (const notification of this.socketService.enableRoleNotifications()) {
        const foundChannelId = (<CompiereNotification>notification).processChannelId
          ? (<CompiereNotification>notification).processChannelId
          : (<CompiereNotification>notification).processParams?.channel_id;
        if (foundChannelId === channelId) {
          this.syncWaitingNotifications[channelId].next(notification);
          delete this.syncWaitingNotifications[channelId];
        }
      }
    })();
    return this.syncWaitingNotifications[channelId];
  }

  downloadReport(url: string): void {
    const unSubObj = this.printReportService.downloadReport(url).subscribe((response) =>
      setTimeout(() => {
        Global.downloadFile(response);
        unSubObj.unsubscribe();
      }, 100)
    );
  }
}
