import { Component, ElementRef, OnInit, ViewEncapsulation } from '@angular/core';
import { IHeaderAngularComp } from 'ag-grid-angular';
import { IHeaderParams } from 'ag-grid-enterprise';

interface MyParams extends IHeaderParams {
  selectAll: Function;
  deselectAll: Function;
}
@Component({
  selector: 'iu-select-all-header',
  templateUrl: './select-all-header.component.html',
  styleUrls: ['./select-all-header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SelectAllHeaderComponent implements OnInit, IHeaderAngularComp {
  public params: MyParams;
  public sorted: string;
  private elementRef: ElementRef;
  checked = false;
  constructor(elementRef: ElementRef) {
    this.elementRef = elementRef;
  }

  ngOnInit() {}
  agInit(params: MyParams): void {
    this.params = params;
    const numberOfRows = this.params.api.getModel().isLastRowIndexKnown()
      ? this.params.api.getModel().getRowCount()
      : this.params.api.getModel().getRowCount() - 1;
    const numberOfRowsSelected = this.params.api.getSelectedRows().length;
    this.checked = numberOfRowsSelected >= numberOfRows && numberOfRowsSelected > 0;
  }

  refresh(params: MyParams): boolean {
    this.params = params;
    const numberOfRows = this.params.api.getModel().isLastRowIndexKnown()
      ? this.params.api.getModel().getRowCount()
      : this.params.api.getModel().getRowCount() - 1;
    const numberOfRowsSelected = this.params.api.getSelectedRows().length;
    this.checked = numberOfRowsSelected >= numberOfRows && numberOfRowsSelected > 0;
    return true;
  }

  check(event) {
    if (event && event.stopPropagation) {
      event.stopPropagation();
    }
    if (this.checked) {
      this.params.selectAll(this);
    } else {
      this.params.deselectAll(this);
    }
  }
}
