<div class="section-container" [attr.aria-expanded]="isOpen">
  <div class="section-title-bar" (click)="changeSectionState()">
    <div class="section-title">
      <h3>{{ title }}</h3>
      <!-- <button pButton class="p-button p-button-icon-only section-title-edit-btn" (click)="handleOpenWindow($event)">
        <i class="section-title-edit-btn-icon icon-edit-pencil" aria-hidden="true"></i>
      </button> -->
    </div>
    <button pButton class="p-button p-button-icon-only section-title-arrow">
      <i [ngClass]="[isOpen ? 'icon-up' : 'icon-open']" aria-hidden="true"></i>
    </button>
  </div>
  <div [ngClass]="{ 'section-content': true, opened: isOpen }">
    <ng-content></ng-content>
  </div>
</div>
