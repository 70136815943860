import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { MessageManagerService } from '@iupics-manager/managers/message/message-manager.service';
import { Global } from '@iupics-manager/models/global-var';
import { IupicsMessage } from '@iupics-manager/models/iupics-message';

@Injectable()
export class IuErrorHandler implements ErrorHandler {
  constructor(private messageManager: MessageManagerService) {}

  handleError(error: any): void {
    if (error instanceof HttpErrorResponse) {
      if (Global.activeXHRRequest > 0) {
        Global.activeXHRRequest--;
      }
      console.error(error);
      this.messageManager.newMessage({
        name: `${error.name} - ${error.status}`,
        message: error.error.message,
        stack: `${error.message}\n${error.url}`
      });
    } else if (error instanceof IupicsMessage) {
      if (error.type !== 'success') {
        console.error(error);
      }
      this.messageManager.newMessage(error);
    } else {

      if(Global.callTokenActive && document.getElementById('failtogettoken') && document.getElementById('loadingTooLong')){
        if (document.querySelector('.indexLoader')) {
          (document.querySelector('.indexLoader') as HTMLElement).style.display = 'none';
        }
        document.getElementById('loadingIndexTooLong').style.display = 'none';
        document.getElementById('btnReloadIndex').style.display = 'none'; 
        document.getElementById('failtogettoken').style.display = 'block';
        document.getElementById('loadingTooLong').style.display = 'block';
      }
      console.error(error);
      this.messageManager.newMessage(error);
    }
  }
}
