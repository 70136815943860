<!-- TODO remove after 4.1.0 std -->
<div
  class="notification-wrapper"
  [ngClass]="[
    item?.closed === false && !isStandalone ? 'notification-untreated' : '',
    item.notificationType !== 'C' ? 'border-status ' + (item.isError ? 'border-status-error' : 'border-status-success') : ''
  ]"
  (click)="handleNotificationItSelf($event)"
  data-cy="notification"
>
  <div class="notification-header">
    <h2 class="notification-header-title" [ngClass]="[item.isError ? 'notifTitleError' : 'notifTitle']">
      <span class="notification-header-title-text" (click)="zoomSource()" data-cy="notification-title-zoom">{{
        item.title
      }}</span>
      <!-- <span *ngIf="priority.length > 0" class="{{ 'priorityIcon ' + priorityClass }}">{{ priority }}</span> -->
    </h2>
    <i
      *ngIf="description.length > 0"
      class="help"
      [life]="3000"
      [hideDelay]="250"
      pTooltip="{{ description }}"
      aria-hidden="true"></i>
    <button
      pButton
      *ngIf="!isStandalone"
      icon="icon-close"
      class="close-btn"
      (click)="deleteNotification($event)"
      [disabled]="areButtonsDisabled"
    ></button>
  </div>
  <div class="notification-content">
    <ng-container *ngIf="item.notificationType !== 'C'">
      <a
        *ngIf="item.zoomInfo && item.zoomInfo.zoomUrl?.length > 0"
        class="zoomUrl"
        (click)="goToUrl()"
        [life]="3000"
        [hideDelay]="250"
        pTooltip="{{ 'notification.openLink' | translate }}"
        data-cy="notification-title-url"
      >
        {{ item.zoomInfo.zoomUrl }}
      </a>
      <ng-container *ngIf="item.summary">
        <span
          *ngIf="item.zoomInfo && item.zoomInfo.zoomType"
          innerHTML="{{ item.summary.slice(0, !isExtended ? TEXT_LIMIT : item.summary.length) }}"
          class="zoomEntity"
          [life]="3000"
          [hideDelay]="250"
          pTooltip="{{ 'notification.zoomOnRecords' | translate }}"
          (click)="zoom()"
          data-cy="notification-title-zoomInfo"
        ></span>
        <span
          *ngIf="!item.zoomInfo || !item.zoomInfo.zoomType"
          innerHTML="{{ item.summary | textLimit : (!isExtended ? TEXT_LIMIT : item.summary.length) }}"
        ></span>
        <span *ngIf="item.summary.length > TEXT_LIMIT" class="notification-text-more" (click)="extend()">
          &mdash; {{ (!isExtended ? 'notification.more' : 'notification.less') | translate }}
        </span>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="item.notificationType === 'C'">
      <iu-input-mention-ui
        *ngIf="item.zoomInfo && item.zoomInfo.zoomType"
        [tagifyOptions]="{ pattern: null, enforceWhitelist: false, placeholder: null }"
        class="note-content zoomEntity"
        pTooltip="{{ 'notification.zoomOnRecords' | translate }}"
        (click)="zoom()"
        data-cy="notification-title-zoomInfo"
        [content]="item.summary"
        [tagExpansion]="false"
        [type]="'notification'"
      >
        <span class="note-author" title data-cy="notification-note-author">{{ item.userName }}</span>
      </iu-input-mention-ui>
      <iu-input-mention-ui
        *ngIf="!item.zoomInfo || !item.zoomInfo.zoomType"
        class="note-content"
        [tagifyOptions]="{ pattern: null, enforceWhitelist: false, placeholder: null }"
        [content]="item.summary"
        [tagExpansion]="false"
        [type]="'notification'"
      >
        <span class="note-author" title data-cy="notification-note-author">{{ item.userName }}</span>
      </iu-input-mention-ui>
    </ng-container>
  </div>
  <div *ngIf="item.fileLinks" class="notification-files">
    <ng-container *ngFor="let icon of icons">
      <img
        *ngIf="icon.icon?.includes('doc-server'); else iconTemplate"
        class="notification-icon"
        [src]="icon.icon | docServerUrl | async"
        alt="icone"
        pTooltip="{{ icon.fileName }}"
        tooltipZIndex="8000"
        width="32"
        height="32"
        (click)="dlFile($event, icon.fileLink)"
      />
      <ng-template #iconTemplate>
        <i
          [ngClass]="['notification-icon', icon.icon ?? '']"
          pTooltip="{{ icon.fileName }}"
          tooltipZIndex="8000"
          (click)="dlFile($event, icon.fileLink)"
          aria-hidden="true"
        >
        </i>
      </ng-template>
    </ng-container>

    <i
      *ngIf="item.fileLinks?.length > 1"
      class="fa fa-download notification-icon notification-download-icon"
      (click)="dlFile($event, item.fileLinks)"
      pTooltip="{{ 'notification.downloadAll' | translate }}"
      tooltipZIndex="8000"
      aria-hidden="true"
    ></i>
  </div>
  <div class="notification-footer">
    <a *ngIf="item.notificationType === 'C'" class="notification-answer" (click)="zoomSource()">
      {{ 'detailsNote.answer' | translate }}
    </a>
    <span *ngIf="readAbleDate" class="notification-date">
      <span class="notification-date-text" [innerHTML]="readAbleDate"></span>
      <i
        *ngIf="priority.length > 0"
        [ngClass]="['fa-bell', 'priority-icon', priorityClass, item.closed ? 'far' : 'fas']"
        aria-hidden="true"
      >
      </i>
    </span>
  </div>
</div>
