import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BadgeButtonClickEvent, BadgeButtonData } from './badge-button-data';

@Component({
  selector: 'iu-badge-button',
  templateUrl: './badge-button.component.html',
  styleUrls: ['./badge-button.component.scss']
})
export class BadgeButtonComponent implements OnInit {
  @Input() data: BadgeButtonData;

  @Output() clickEmitter = new EventEmitter<BadgeButtonClickEvent>();

  constructor() {}

  ngOnInit(): void {}

  onClick(event: MouseEvent) {
    if (this.data?.action) {
      return this.data.action();
    }

    this.clickEmitter.emit({ component: this, event });
  }
}
