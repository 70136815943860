<div class="apiz-p-grid" style="height: 100%">
  <ng-container *ngIf="menus$ | async | groupByCategory as groupedMenu" class="apiz-p-grid">
    <!-- #region partie de gauche -->
    <div id="iu_menu_list" [ngClass]="[!isMobile ? 'p-col-9 p-md-8 p-lg-9' : '', 'menuList p-col-12']">
      <div *ngIf="isMobile || isMobileWidth" class="menu-ui-search">
        <div>
          <input
            #searchInput
            class="inputSearch"
            type="search"
            pInputText
            placeholder="{{ 'menu.searchmenu' | translate }}"
            (keyup)="search($event.target.value)"
            (search)="search($event.target.value)"
            [(ngModel)]="searchValue"
            data-cy="menu-input"
          />
          <i class="icon-search" aria-hidden="true"></i>
        </div>
      </div>
      <div *ngFor="let categoryGroup of groupedMenu" class="menu-ui-category-group">
        <iu-prime-fieldset
          #menuFieldset
          *ngIf="categoryGroup.category.id >= 0 && categoryGroup.items.length > 0 && categoryGroup.category.isDisplay === true"
          [legend]="categoryGroup.category.name"
          [toggleable]="true"
          (onAfterToggle)="onFieldsetToggle($event, categoryGroup.category)"
        >
          <div class="menu-ui-category-items">
            <ng-container *ngIf="!categoryCollapsed[categoryGroup.category.name]">
              <ng-container *ngFor="let menu of categoryGroup.items">
                <div
                  id="iu_menu_item_{{ menu.menu_id }}"
                  [ngClass]="[
                    menu.menu_id === selectedMenu?.menu_id &&
                    typeofSelected === 'menu' &&
                    categoryGroup.category.name === selectedMenu?.menu_category?.name
                      ? 'menuItemSelected'
                      : '',
                    'menuItem'
                  ]"
                  (click)="onClick(menu)"
                  data-cy="menu-item"
                >
                  <i
                    [ngClass]="[isMobileWidth ? 'menuIcon-smartphone' : '', 'fa-3x menuIcon', menu.iconClass || '']"
                    aria-hidden="true"
                  ></i>
                  <div [ngClass]="[isMobileWidth ? 'menuIcon-label-smartphone' : '', 'menuIcon-label']">{{ menu.name }}</div>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </iu-prime-fieldset>
      </div>
    </div>
    <!-- #endregion -->
    <!-- #region partie de droite -->
    <div *ngIf="!isMobile" class="menuCategory p-col-12 p-md-4 p-lg-3">
      <div class="menu-ui-search">
        <div>
          <input
            #searchInput
            class="inputSearch ui-corner-all"
            type="search"
            pInputText
            placeholder="{{ 'menu.searchmenu' | translate }}"
            (keyup)="search($event.target.value, $event, groupedMenu)"
            (search)="search($event.target.value, $event, groupedMenu)"
            autofocus
            [(ngModel)]="searchValue"
            data-cy="menu-input"
          />
          <i class="icon-search" aria-hidden="true"></i>
        </div>
      </div>
      <div id="iu_menu_category_list" class="menu-ui-categories">
        <ng-container *ngFor="let group of groupedMenu">
          <span
            id="iu_menu_category_item_{{ group.category.name }}"
            *ngIf="group.items.length > 0 || group.category.id === -1"
            [ngClass]="[
              group.category.isSelected ? 'menuCategoryItemActive' : '',
              group.category.name === selectedCategory?.name && typeofSelected === 'category' ? 'menuCategoryItemSelected' : '',
              'menuCategoryItem',
              'apiz-p-grid'
            ]"
            (click)="changeCategory(group.category)"
          >
            <div *ngIf="group.category.isSelected" class="p-col-1"><i class="icon-next" aria-hidden="true"></i></div>
            <div [ngClass]="[group.category.isSelected ? 'p-col-11' : '']">
              <i [ngClass]="[group.category.icon || '']" aria-hidden="true"></i>
              <a>{{ group.category.name }}</a>
            </div>
          </span>
        </ng-container>
      </div>
    </div>
    <!-- #endregion -->
  </ng-container>

  <ng-container *ngIf="ghosts.length" class="apiz-p-grid">
    <!-- #region partie de gauche -->
    <div id="iu_menu_list" [ngClass]="[!isMobile ? 'p-col-9 p-md-8 p-lg-9' : '', 'menuList p-col-12']">
      <div *ngIf="isMobile || isMobileWidth" class="menu-ui-search">
        <div>
          <div>
            <input
              class="inputSearch ui-corner-all"
              type="search"
              pInputText
              placeholder="{{ 'menu.searchmenu' | translate }}"
              [(ngModel)]="searchValue"
              data-cy="menu-input"
            />
            <i class="icon-search" aria-hidden="true"></i>
          </div>
        </div>
      </div>
      <div class="menu-ui-category-group">
        <iu-prime-fieldset [legend]="'Category 1'" [toggleable]="false">
          <div class="menu-ui-category-items">
            <div *ngFor="let i of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]" class="menuItem ghost-elt">
              <i
                [ngClass]="[isMobileWidth ? 'menuIcon-smartphone' : '', 'fa-3x menuIcon icon-window-2 ghost-rect']"
                aria-hidden="true"
              ></i>
              <div [ngClass]="[isMobileWidth ? 'menuIcon-label-smartphone' : '', 'menuIcon-label ghost label']">Menu {{ i }}</div>
            </div>
          </div>
        </iu-prime-fieldset>
        <iu-prime-fieldset [legend]="'Category 2'" [toggleable]="false">
          <div class="menu-ui-category-items">
            <div *ngFor="let i of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]" class="menuItem ghost-elt">
              <i
                [ngClass]="[isMobileWidth ? 'menuIcon-smartphone' : '', 'fa-3x menuIcon icon-window-2 ghost-rect']"
                aria-hidden="true"
              ></i>
              <div [ngClass]="[isMobileWidth ? 'menuIcon-label-smartphone' : '', 'menuIcon-label ghost label']">Menu {{ i }}</div>
            </div>
          </div>
        </iu-prime-fieldset>
      </div>
    </div>
    <!-- #endregion -->
    <!-- #region partie de droite -->
    <div *ngIf="!isMobile" class="menuCategory p-col-12 p-md-4 p-lg-3">
      <div class="menu-ui-search">
        <div>
          <input
            #searchInput
            class="inputSearch ui-corner-all"
            type="search"
            pInputText
            placeholder="{{ 'menu.searchmenu' | translate }}"
            [(ngModel)]="searchValue"
            data-cy="menu-input "
          />
          <i class="icon-search" aria-hidden="true"></i>
        </div>
      </div>
      <div id="iu_menu_category_list" class="menu-ui-categories">
        <span class="menuCategoryItem ghost-elt">
          <div>
            <i class="icon-process-cogs-3 ghost-round" aria-hidden="true"></i>
            <a class="ghost label">Category 1</a>
          </div>
        </span>
        <span class="menuCategoryItem ghost-elt">
          <div>
            <i class="icon-process-cogs-3 ghost-round" aria-hidden="true"></i>
            <a class="ghost label">Category 2</a>
          </div>
        </span>
        <span class="menuCategoryItem ghost-elt">
          <div>
            <i class="icon-process-cogs-3 ghost-round" aria-hidden="true"></i>
            <a class="ghost label">Category 3</a>
          </div>
        </span>
      </div>
    </div>
    <!-- #endregion -->
  </ng-container>
</div>
