import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, NgZone, OnDestroy, Renderer2 } from '@angular/core';
import { Global } from '@iupics-manager/models/global-var';
import { OverlayService, PrimeNGConfig } from 'primeng/api';
import { OverlayPanel } from 'primeng/overlaypanel';
@Component({
  selector: 'iu-prime-overlay',
  animations: [
    trigger('animation', [
      state(
        'void',
        style({
          transform: 'scaleY(0.8)',
          opacity: 0
        })
      ),
      state(
        'close',
        style({
          opacity: 0
        })
      ),
      state(
        'open',
        style({
          transform: 'translateY(0)',
          opacity: 1
        })
      ),
      transition('void => open', animate('{{showTransitionParams}}')),
      transition('open => close', animate('{{hideTransitionParams}}'))
    ])
  ],
  styleUrls: ['./prime-overlay.component.scss'],
  templateUrl: './prime-overlay.component.html'
})
export class PrimeOverlayComponent extends OverlayPanel implements AfterViewInit, OnDestroy {
  @Input() appendTo: any = 'body';
  @Input() handleAlign: (context: PrimeOverlayComponent) => void;
  @Input() fixedZIndex = false;

  constructor(
    public el: ElementRef,
    public renderer: Renderer2,
    cd: ChangeDetectorRef,
    zone: NgZone,
    public config: PrimeNGConfig,
    public overlayService: OverlayService
  ) {
    super(el, renderer, cd, zone, config, overlayService);
  }
  ngAfterViewInit() {
    if (this.appendTo) {
      if (this.appendTo === 'body') {
        Global.activeOverlays.push(this);
      }
    }

    if (this.fixedZIndex && this.container) {
      this.container.style.zIndex = this.baseZIndex ? `${this.baseZIndex}` : '2500';
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (Global.activeOverlays && Global.activeOverlays.length > 0) {
      const index = Global.activeOverlays.indexOf(this);
      if (index > -1) {
        Global.activeOverlays.splice(index, 1);
      }
    }
  }

  align(): void {
    if (!this.handleAlign) {
      super.align();
      return;
    }

    this.handleAlign(this);
  }
}
