import { Injectable } from '@angular/core';
import {
  CompiereNotification,
  CompiereNotificationType,
  CompiereUINotificationWS
} from '@compiere-ws/models/compiere-notification-json';
import { AppConfig } from '@iupics-config/app.config';
import { Observable } from 'rxjs';
import { ApiService } from '../api/api.service';

@Injectable()
export class CompiereNotificationService {
  private url: string;

  constructor(private http: ApiService, private config: AppConfig) {}

  getNotifications(): Observable<CompiereUINotificationWS> {
    this.url = this.config.getBackendResource('notifications');
    return this.http.get<CompiereUINotificationWS>(this.url);
  }
  sendNotification(notification: CompiereNotification): Observable<CompiereNotification> {
    this.url = this.config.getBackendResource('notifications');
    return this.http.post<CompiereNotification>(this.url, notification);
  }
  handleNotification(notification: CompiereNotification): Observable<CompiereNotification> {
    this.url = this.config.getBackendResource('notifications');
    return this.http.put<CompiereNotification>(this.url + '/' + notification.request_id, {});
  }

  handleAllNotification(): Observable<CompiereNotification> {
    this.url = this.config.getBackendResource('notifications');
    return this.http.put<CompiereNotification>(this.url, {});
  }

  closeNotification(notification: CompiereNotification): Observable<CompiereNotification> {
    this.url = this.config.getBackendResource('notifications');
    return this.http.delete<CompiereNotification>(this.url + '/' + notification.request_id);
  }
  closeAllNotification(type: CompiereNotificationType): Observable<CompiereNotification> {
    this.url = this.config.getBackendResource('notifications');
    return this.http.delete<CompiereNotification>(`${this.url}?type=${type}`);
  }
}
