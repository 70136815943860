import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewEncapsulation
} from '@angular/core';
import { IupicsTypeEvent } from '@iupics-manager/models/iupics-event';
import { ContextMenuService } from '@web-desktop/components/workspace/controllers/context-menu/context-menu.service';
import { IupicsComponentType, IupicsContextMenuComponent } from '@web-desktop/models/iupics-context-menu';
import { MenuItem } from 'primeng/api';
import { Breadcrumb } from 'primeng/breadcrumb';

@Component({
  selector: 'iu-prime-breadcrumb-ui',
  templateUrl: './prime-breadcrumb.component.html',
  styleUrls: ['./prime-breadcrumb.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PrimeBreadcrumbComponent extends Breadcrumb implements OnInit, IupicsContextMenuComponent {
  componentType: IupicsComponentType = IupicsComponentType.BREADCRUMB;
  @ViewChildren('items') itemRefs: QueryList<ElementRef>;
  @ViewChild('list', { read: ElementRef, static: true })
  list: ElementRef;
  @Output()
  breadcrumbEventEmitter = new EventEmitter<any>();
  @Input()
  processItem: { tabId: number; label: string };

  itemSelected: MenuItem;

  constructor(public cmService: ContextMenuService) {
    super();
  }

  ngOnInit() {
    this.model = [];
    if (this.processItem) {
      this.addItem(this.processItem);
    }
  }

  addItem(newItem: { tabId: number; label: string }) {
    const lastItem = { id: '' + newItem.tabId, label: newItem.label };
    this.model.push(lastItem);
    this.changeActiveItem(lastItem);
  }

  removeItem(itemRemoved: { tabId: number; label: string }) {
    this.model = this.model.filter((item) => item.id !== '' + itemRemoved.tabId);
    this.changeActiveItem(this.model[this.model.length - 1]);
  }

  itemClick(event, item: MenuItem) {
    if (this.model.length > 1) {
      this.changeActiveItem(item);
      this.breadcrumbEventEmitter.emit({
        event: IupicsTypeEvent.clickBreadCrumbItem,
        tabId: item.id
      });
    }
  }

  handleEvent(event) {
    this.breadcrumbEventEmitter.emit({
      event: IupicsTypeEvent.closeBreadCrumbItem,
      tabId: this.itemSelected.id
    });
  }

  onRightClick(event, item: MenuItem) {
    if (item.id !== '0') {
      this.itemSelected = item;
      this.onContextMenu(event);
    }
  }

  onContextMenu(event) {
    this.cmService.showContextMenu(this, this.handleEvent.bind(this), event);
  }

  changeActiveItem(activeItem: MenuItem) {
    this.model.forEach((element) => {
      if (element.id === activeItem.id) {
        element.disabled = false;
      } else {
        element.disabled = true;
      }
    });
    this.breadcrumbEventEmitter.emit({
      event: IupicsTypeEvent.updatedActiveItemBreadcrumb,
      tabId: activeItem.id
    });
  }
}
