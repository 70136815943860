import { Component } from '@angular/core';
import { GridViewUiComponent } from '@iupics-components/standard/grid/grid-view-ui/grid-view-ui.component';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-enterprise';
import * as moment from 'moment';

@Component({
  selector: 'iu-calendar-renderer',
  template: `
    <iu-calendar-ui
      [fieldValue]="this.fieldValue"
      [isLabelDisplay]="false"
      [cssClass]="'defaultDateCalendar p-col-12 calendarRenderer'"
      [isStandalone]="true"
      [data]="{ needTime: this.templates.needTime }"
      [isGridRenderer]="true"
      (fieldValueModified)="onFieldModified($event)"
      (checkGridRendererEmitter)="onCalendar($event)"
    ></iu-calendar-ui>
  `
})
export class CalendarRendererComponent implements ICellRendererAngularComp {
  params: any;
  label: string;
  templates: any;
  fieldValue = null;
  activeGridView: GridViewUiComponent;

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.label = this.params.label || null;
    this.templates = this.params.templates;
    this.activeGridView = this.params.activeGridView;

    if (params.value) {
      this.fieldValue = moment(params.value).toDate();
    }
  }

  refresh(params?: ICellRendererParams): boolean {
    return true;
  }

  onFieldModified($event) {
    const oldValue = this.params.node.data[this.templates.columnName];
    let newValue: string;
    if ($event) {
      newValue = moment($event).format('YYYY-MM-DDTHH:mm:ss.SSS');
      newValue = newValue.substring(0, 26) + newValue.substring(27, newValue.length);
    }
    this.params.node.data[this.templates.columnName] = newValue;

    if (!this.params.node.isSelected()) {
      this.params.node.setSelected(true); // select row
    }

    if (this.params.onClick instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        rowData: this.params.node.data,
        columnName: this.params.colDef.field,
        oldValue,
        newValue
        // ...something
      };
      this.params.onClick({ ...this.params, ...params });
    }
  }

  onCalendar(event: any) {
    this.params.onCalendar(event);
  }
}
