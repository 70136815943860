import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { CompiereDataGridRequestJSON } from '@compiere-ws/models/compiere-data-json';
import {
  CompiereNotification,
  CompiereNotificationFileInfo,
  CompiereNotificationPriority
} from '@compiere-ws/models/compiere-notification-json';
import { NotificationManagerService } from '@iupics-manager/managers/notification-manager/notification-manager.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { Global } from '@iupics-manager/models/global-var';
import { TranslateService } from '@ngx-translate/core';
import { IupicsMenuType } from '@web-desktop/models/menu-item-ui';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
@Component({
  selector: 'iu-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NotificationComponent implements OnInit, AfterViewInit {
  TEXT_LIMIT = 120;

  private subscriptions: Subscription[] = [];

  @Input()
  isStandalone = false;
  @Input()
  areButtonsDisabled = false;
  @Input()
  item: CompiereNotification;

  @Output()
  notificationEmitter = new EventEmitter<CompiereNotification>();
  @Output()
  dlFileEmitter = new EventEmitter<string | CompiereNotificationFileInfo[]>();

  isExtended = false;
  readAbleDate: string;
  description = '';
  priority = '';
  priorityClass = 'low';
  icons: { fileLink: string; icon: string; fileName: string }[] = [];

  constructor(
    private uiCreator: UICreatorService,
    private translateService: TranslateService,
    private connectorService: SecurityManagerService,
    private notificationManager: NotificationManagerService
  ) {}

  ngOnInit() {
    if (this.item.fileLinks) {
      this.setIcons();
    }
    if (this.item.created) {
      moment.locale(this.connectorService.getIupicsDefaultLanguage().iso_code);
      let format = 'LT';
      if (!moment(this.item.created).isSame(new Date(), 'day')) {
        format = 'L LT';
      }
      this.readAbleDate = moment(this.item.created).format(format);
    }
    if (this.item.description) {
      this.description = this.item.description;
    }
    if (this.item.priority) {
      switch (this.item.priority) {
        case CompiereNotificationPriority.IMPORTANT:
          this.priority = this.translateService.instant('notification.priority.critical');
          this.priorityClass = 'critical';
          break;
        default:
          break;
      }
    }
  }

  ngAfterViewInit() {
    if (this.subscriptions) {
      this.subscriptions.forEach((sub) => {
        if (sub.unsubscribe) {
          sub.unsubscribe();
        }
      });
    }
  }

  /**
   * Défini l'icon pour les fichiers
   */
  private setIcons() {
    if (this.item.fileLinks) {
      this.icons = this.item.fileLinks.map((fileLink) => ({
        fileLink: fileLink.path,
        icon: this.getFileIcon(fileLink.extension),
        fileName: fileLink.fileName
      }));
    }
  }

  /**
   * Obtiens l'icône par rapport à son extension
   * @param {string}fileLink
   */
  private getFileIcon(fileLink: string) {
    if (!fileLink) {
      return 'icon-file';
    } else if (fileLink.endsWith('pdf')) {
      return 'icon-pdf';
    } else if (fileLink.endsWith('csv')) {
      return 'icon-csv';
    } else if (fileLink.endsWith('txt')) {
      return 'icon-txt';
    } else if (fileLink.endsWith('xls') || fileLink.endsWith('xlsx')) {
      return 'icon-excel';
    } else if (fileLink.endsWith('doc') || fileLink.endsWith('docx')) {
      return 'icon-word';
    } else if (fileLink.endsWith('sql')) {
      return 'icon-sql';
    } else if (fileLink.endsWith('zip')) {
      return 'icon-zip';
    } else if (fileLink.endsWith('html')) {
      return 'icon-html';
    } else {
      return 'icon-file';
    }
  }

  /**
   * Lance un event pour supprimer la notification du notification center
   * @param {MouseEvent}event
   */
  deleteNotification(event: MouseEvent) {
    event?.preventDefault();
    event?.stopPropagation();
    this.notificationEmitter.emit(this.item);
  }

  /**
   * Lance un event pour télécharger le fichier
   * @param {Event}event
   * @param {string}fileLink
   */
  dlFile(event: Event, fileLink: string | CompiereNotificationFileInfo[]) {
    event?.stopPropagation();
    if (fileLink && fileLink.length > 0) {
      if (!this.isStandalone) {
        this.dlFileEmitter.emit(fileLink);
      } else if (typeof fileLink === 'string') {
        this.handleDlFileItSelf(fileLink);
      }
    }
  }
  /**
   * Ouvre l'url indiqué dans la notif
   */
  goToUrl() {
    if (this.item.zoomInfo && this.item.zoomInfo.zoomUrl) {
      window.open(this.item.zoomInfo.zoomUrl, '_blank');
    }
  }
  /**
   * Ouvre la fenêtre qui est a source de la notif
   */
  zoomSource() {
    if (this.item.sourceType && this.item.sourceTypeId) {
      const zoomType = this.item.sourceType ? this.camelize(this.item.sourceType) : null;
      const zoomTypeID = this.item.sourceTypeId;
      const ctx = this.item.processParams;
      if (ctx) {
        if (zoomType === IupicsMenuType.PROCESS) {
          const processParamsMap = new Map<string, any>();
          Object.keys(ctx).forEach((key) => {
            processParamsMap.set(key, ctx[key]);
          });
          Global.workspace.urlParams.processParamsMap = processParamsMap;
        } else if (zoomType === IupicsMenuType.FORM) {
          Global.workspace.urlParams.ctx = ctx;
        }
      }
      const sourceTableNameTargeted = this.item.sourceTableNameTargeted;
      const sourceRecordId = this.item.sourceRecordId;
      if (
        zoomType === IupicsMenuType.WINDOW &&
        sourceRecordId &&
        !isNaN(parseInt(this.item.sourceRecordId, 10)) &&
        sourceTableNameTargeted
      ) {
        const identifiant = this.item.sourceTableNameTargeted + '_ID';
        this.subscriptions.push(
          this.uiCreator
            .zoomAcross(sourceTableNameTargeted, identifiant, parseInt(sourceRecordId, 10), true, zoomTypeID)
            .subscribe((dataWs) => {
              if (dataWs && dataWs.length > 0) {
                this.subscriptions.push(
                  this.uiCreator.getIupicsMenus().subscribe((menus) => {
                    const menuFound = menus.find(
                      (menu) => menu.action_id === parseInt(dataWs[0].Window_ID, 10) && menu.menu_type === IupicsMenuType.WINDOW
                    );
                    if (menuFound) {
                      dataWs[0].Window_ID = menuFound.action_id;
                      const zoomInfo = {
                        // tableName: this.data.detailZoom.tableName,
                        windowId: dataWs[0].Window_ID,
                        dataUUID: dataWs[dataWs.length - 1]['Record_ID'],
                        record_id: sourceRecordId,
                        children: dataWs.length > 1 ? dataWs.splice(0, dataWs.length - 1) : null
                      };
                      Global.workspace.openTargetSearch({
                        zoomInfo: zoomInfo,
                        cat: { id: parseInt(dataWs[0].Window_ID, 10) },
                        source: {
                          id: dataWs[0].Record_ID ? dataWs[0].Record_ID : 'newRecord'
                        } // dataWs[0].Record_ID
                      });
                    }
                  })
                );
              } else {
                throw new Error(this.translateService.instant('notification.accessDenied'));
              }
            })
        );
      } else {
        Global.workspace.openTargetSearch({
          cat: { id: zoomTypeID },
          source: {
            id: sourceRecordId ? sourceRecordId : null,
            menu_type: zoomType
          }
        });
      }
    }
  }
  /**
   * Ouvre une fenetre dont les informations ont été ajouté à la notif
   */
  zoom() {
    if (this.item.zoomInfo) {
      const identifier = this.item.zoomInfo.tableNameTargeted + '_ID';
      const tableName = this.item.zoomInfo.tableNameTargeted;
      const record_id = this.item.zoomInfo.recordId;
      const zoomType = this.item.zoomInfo.zoomType ? this.camelize(this.item.zoomInfo.zoomType) : null;
      const zoomTypeID = this.item.zoomInfo.zoomTypeId;
      const request = this.item.zoomInfo.request;
      const colValues = this.item.zoomInfo.colValues;
      const dataGridRequest: CompiereDataGridRequestJSON = request;
      Global.workspace.urlParams.dataGridRequest = dataGridRequest;
      if (!zoomType) {
        throw new Error(this.translateService.instant('notification.zoomInfoNotValid'));
      }
      if (zoomType === IupicsMenuType.WINDOW && record_id && !isNaN(parseInt(record_id, 10))) {
        if (!tableName || !zoomTypeID) {
          throw new Error(this.translateService.instant('notification.zoomInfoNotValid'));
        }
        this.subscriptions.push(
          this.uiCreator.zoomAcross(tableName, identifier, parseInt(record_id, 10), true, zoomTypeID).subscribe((dataWs) => {
            if (dataWs && dataWs.length > 0) {
              this.subscriptions.push(
                this.uiCreator.getIupicsMenus().subscribe((menus) => {
                  const menuFound = menus.find(
                    (menu) => menu.action_id === parseInt(dataWs[0].Window_ID, 10) && menu.menu_type === IupicsMenuType.WINDOW
                  );
                  if (menuFound) {
                    dataWs[0].Window_ID = menuFound.action_id;
                    const zoomInfo = {
                      // tableName: this.data.detailZoom.tableName,
                      windowId: dataWs[0].Window_ID,
                      dataUUID: dataWs[dataWs.length - 1]['Record_ID'],
                      record_id: record_id,
                      children: dataWs.length > 1 ? dataWs.splice(0, dataWs.length - 1) : null
                    };
                    Global.workspace.openTargetSearch({
                      zoomInfo: zoomInfo,
                      cat: { id: parseInt(dataWs[0].Window_ID, 10) },
                      source: {
                        id: dataWs[0].Record_ID ? dataWs[0].Record_ID : 'newRecord'
                      } // dataWs[0].Record_ID
                    });
                  }
                })
              );
            } else {
              throw new Error(this.translateService.instant('notification.accessDenied'));
            }
          })
        );
      } else {
        if (colValues) {
          if (zoomType === IupicsMenuType.PROCESS) {
            const processParamsMap = new Map<string, any>();
            Object.keys(colValues).forEach((key) => {
              processParamsMap.set(key, colValues[key]);
            });
            Global.workspace.urlParams.processParamsMap = processParamsMap;
          }
          if (zoomType === IupicsMenuType.FORM) {
            Global.workspace.urlParams.ctx = colValues;
          }
        }
        Global.workspace.openTargetSearch({
          cat: { id: zoomTypeID },
          source: {
            id: null,
            menu_type: zoomType
          }
        });
      }
    }
  }

  camelize(str: string) {
    return str
      .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return index === 0 ? word.toUpperCase() : word.toLowerCase();
      })
      .replace(/\s+/g, '');
  }

  /**
   * Change le status d'une notification
   * @param {MouseEvent}event
   */
  handleNotificationItSelf(event?: MouseEvent): void {
    event?.stopPropagation();
    Global.notificationCenter.handleNotification(event, this.item);
  }

  /**
   * Télécharge les fichiers passés en paramètre
   * @param {CompiereNotification}item
   * @param {string}fileLinks
   */
  handleDlFileItSelf(fileLinks: string) {
    fileLinks.split(',').forEach((fileLink) => {
      this.notificationManager.downloadReport(fileLink);
    });
    this.handleNotificationItSelf();
  }

  /**
   * Affiche tout le contenu du sommaire
   */
  extend() {
    this.isExtended = this.item.summary.length > 120 && !this.isExtended;
  }
}
