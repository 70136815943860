export interface StatisticsCardData {
  type?: StatisticsCardType;
  label: string;
  amount: number;
  pct: number;
  count?: number;
  indicator?: '-' | '+';
}

export enum StatisticsCardType {
  NORMAL,
  COUNT
}
