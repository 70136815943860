import { Injectable } from '@angular/core';
import { AppConfig } from '@iupics-config/app.config';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { environment } from 'environments/environment';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  active: any;
  availableThemes: { items: any[] };
  activeTheme$ = new BehaviorSubject<any>(null);

  constructor(private config: AppConfig, protected connectorService: SecurityManagerService) {
    const themes = environment.themes;
    this.availableThemes = { items: [] };
    let themeId = 1;
    Object.keys(themes).forEach((themeKey) => {
      const displayValue = themes[themeKey]['__name'] ?? themeKey;

      this.availableThemes.items.push({
        id: themeId++,
        key: themeKey,
        displayValue,
        data: themes[themeKey]
      });
    });
  }

  getAvailableThemes() {
    return this.availableThemes;
  }

  getActiveTheme() {
    return this.active;
  }

  getThemeProperty(propertyName: string) {
    return this.active['data'][propertyName];
  }

  setActiveTheme(themeKey?: string): void {
    if (!themeKey) {
      themeKey = this.connectorService.getIupicsUserContext()['#UITheme'];
    }

    this.active = this.availableThemes.items.find((theme) => theme['key'] === themeKey);

    // Retrieves theme style
    let variablesStyle = <HTMLStyleElement>document.querySelector('[data-theme]') ?? null;
    if (!variablesStyle) {
      variablesStyle = document.createElement('style');
      document.head.appendChild(variablesStyle);
    }
    // override theme's variables
    variablesStyle.setAttribute('data-theme', themeKey);
    document.querySelectorAll('[data-theme-font]').forEach((fontStyle) => fontStyle.remove());
    variablesStyle.textContent = `:root {\n`;
    const keys = Object.keys(this.active.data);
    const apizKeys = keys.filter((property) => !property.startsWith('ag-'));
    apizKeys.forEach((property) => {
      if (property === '__name') {
        return;
      }

      if (property.startsWith('font-family')) {
        // Retrieves font from google api
        const fontFamily = this.active.data[property].split('"')[1] || '';
        if (
          fontFamily !== '' &&
          // Checks if font isn't already loaded
          ![...(<any>document.querySelectorAll('[data-theme-font]'))].find(
            (el) => el.getAttribute('data-theme-font') === fontFamily
          )
        ) {
          // Creates style element for new font
          const fontStyle = document.createElement('style');
          fontStyle.setAttribute(`data-theme`, themeKey);
          fontStyle.setAttribute(`data-theme-font`, fontFamily);
          fontStyle.textContent = `@import url('https://fonts.googleapis.com/css2?family=${this.active.data[property]
            .split('"')[1]
            .replace(
              / /g,
              '+'
            )}:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');`;
          // Handles error: deletes style element & remove font family variable
          fontStyle.onerror = () => {
            variablesStyle.textContent.replace(`--${property}: ${this.active.data[property]};\n`, '');
            fontStyle.remove();
          };
          document.head.appendChild(fontStyle);
        }
      }

      variablesStyle.textContent += `--${property}: ${this.active.data[property]};\n`;
    });
    variablesStyle.textContent += '}\n';

    const agKeys = keys.filter((property) => property.startsWith('ag-'));
    variablesStyle.textContent += '.ag-theme-balham {\n';
    agKeys.forEach((property) => {
      variablesStyle.textContent += `--${property}: ${this.active.data[property]};\n`;
    });
    variablesStyle.textContent += '}\n';

    this.activeTheme$.next(this.active);
  }
}

export enum ThemeVariableKeys {
  AvatarFtColor = '--avatar-ui-ft-color'
}
