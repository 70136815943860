import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { CustomerProfileData } from './customer-profile-data';

@Component({
  selector: 'iu-customer-profile',
  templateUrl: './customer-profile.component.html',
  styleUrls: ['./customer-profile.component.scss']
})
export class CustomerProfileComponent implements OnInit {
  @Input() data: any;
  CPData: CustomerProfileData;

  @Output() editButtonEmitter = new EventEmitter<MouseEvent>();

  loadingState: boolean;

  constructor(protected uiCreator: UICreatorService) {}

  ngOnInit(): void {}

  editBtnClick(event: MouseEvent) {
    this.editButtonEmitter.emit(event);
  }

  changeLoadingState() {
    this.loadingState = !this.loadingState;
  }
}
