import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'iu-section-ui',
  templateUrl: './section-ui.component.html',
  styleUrls: ['./section-ui.component.scss']
})
export class SectionUiComponent implements OnInit {
  @Input() title: string;
  @Input() link: string;
  @Input() isOpen = false;

  constructor() {}

  ngOnInit(): void {}

  changeSectionState() {
    this.isOpen = !this.isOpen;
  }

  handleOpenWindow(event: MouseEvent) {
    event.stopPropagation();
    // TODO: Open new window
    console.log(this.link ?? 'handleOpenWindow');
  }
}
