<div
  [ngClass]="{ 'input-mention-wrapper': true, hasIcon: sendIcon ? true : false }"
  [attr.role]="type"
  [id]="tagifyId"
  #inputMentionWrapper
  data-cy="input-mention-wrapper"
>
  <ng-content [select]="title"></ng-content>
  <tagify [settings]="tagifyOptions" [whitelist]="tags" [readonly]="readonly" [name]="tagifyId">
    <ng-container #tagsVcr></ng-container>
  </tagify>
  <div class="input-mention-icons">
    <ng-content select="[icons]"></ng-content>
    <i
      *ngIf="sendIcon"
      class="icon-send"
      aria-hidden="true"
      role="button"
      (click)="enterEmitter.emit($event)"
      data-cy="input-mention-send-button"
    ></i>
  </div>
</div>
<ng-container #tagsListVcr></ng-container>
