<div class="container-vertical">
  <p *ngIf="description && description !== '-'" class="title">{{ description }}</p>
  <div *ngIf="help" class="description">
    <span [innerHTML]="isHelpShown ? help : help.substr(0, 200)" (click)="isHelpShown = false"></span>
    <span *ngIf="!isHelpShown && help.length > 200" class="help-more" (click)="isHelpShown = true"> (...) </span>
  </div>
  <div class="container-horizontal mainPanel">
    <div class="container-vertical panelLeft">
      <iu-prime-fieldset
        legend="{{ 'process.parameters' | translate }}"
        [toggleable]="true"
        [collapsed]="collapsed"
        [style.display]="isDisplay"
      >
        <div class="fields apiz-p-grid">
          <div class="p-col-12">
            <ng-template iuDynamicContainer></ng-template>
          </div>
          <ng-container *ngIf="isReport && exportDataOptions as edo">
            <iu-autocomplete-ui
              *ngIf="hasFormat"
              #printFormatsAutocomplete
              (fieldValueModified)="onPrintFormatChange($any($event))"
              [attr.data-cy]="'exportDataOptions-printFormats'"
              [cssClass]="'p-col-12 p-offset-0'"
              [data]="edo.printFormats"
              [isStandalone]="true"
              label="{{ 'exportData.printFormats' | translate }}"
              tooltip="{{ 'exportData.printFormats' | translate }}"
            ></iu-autocomplete-ui>
            <iu-autocomplete-ui
              #fileTypesAutocomplete
              [attr.data-cy]="'exportDataOptions-fileTypes'"
              [cssClass]="'p-col-12 p-offset-0'"
              [data]="edo.fileTypes"
              [fieldValue]="defaultFileType"
              [isStandalone]="true"
              label="{{ 'exportData.fileTypes' | translate }}"
              tooltip="{{ 'exportData.fileTypes' | translate }}"
            ></iu-autocomplete-ui>
          </ng-container>
        </div>
      </iu-prime-fieldset>
      <div class="iu-process-btn-container">
        <button
          pButton
          class="p-button p-button-primary {{ isProcessing ? 'iu-btn-disabled' : '' }}"
          (click)="executeProcess($event)"
          [disabled]="isProcessing"
          [attr.data-cy-channelId]="channel_id"
          [attr.data-cy-processId]="windowId"
          label="{{ 'process.execute' | translate }}"
          icon="fa fa-check"
          data-cy="process-button"
        ></button>
      </div>
    </div>
    <div class="panelRight">
      <iu-prime-steps
        [model]="steps"
        [isIndexNumber]="true"
        [(activeIndex)]="activeIndex"
        styleClass="steps-custom"
        [readonly]="true"
        [showCMChecker]="showCMChecker"
        [cmEventHandler]="cmStepperEventHandler"
      ></iu-prime-steps>
      <iu-process-logs-ui #logContainer></iu-process-logs-ui>
    </div>
  </div>
</div>

<ng-container *ngIf="displaySearch">
  <iu-modal-ui
    data-cy="search-panel"
    [attr.data-cy-columnName]="this.searchLinkedComponent.autoComplete.data.columnName"
    [title]="this.searchLinkedComponent.autoComplete.label"
    [hasCloseBtn]="true"
    (closeModalEmitter)="displaySearch = false"
  >
    <div class="autocomplete-universal-filter">
      <iu-universal-filter-ui
        [tabId]="-1"
        [columns]="searchLinkedComponent.autoComplete.data.searchColumns"
        [gridTab]="gridTab"
      ></iu-universal-filter-ui>
    </div>
    <iu-grid-tab-infinity-scroll-ui
      #gridTab
      (searchEmitter)="
        this.searchLinkedComponent.setSearchSelectItem($event); displaySearch = false; searchPanelValidation = undefined
      "
      [data]="this.searchLinkedComponent.autoComplete.data"
      [container]="this"
      [isSearch]="true"
      [dataStored]="this.searchLinkedComponent.autoComplete.dataStored"
      [validation]="searchPanelValidation"
      [windowType]="windowType"
    >
    </iu-grid-tab-infinity-scroll-ui>
  </iu-modal-ui>
</ng-container>
<div [ngClass]="[!displayFormUI ? 'hidden' : '', 'specificModal']">
  <iu-modal-ui
    #specificModal
    [title]="specificWindowTitle"
    [isModalDisplay]="displayFormUI"
    [angularStyle]="customFormModalBodyCss"
    (closeModalEmitter)="updateModalDisplay({ key: 'displayFormUI', value: false })"
  >
    <ng-template #vcrSpecific></ng-template>
  </iu-modal-ui>
</div>
