import { Injectable, OnDestroy } from '@angular/core';
import { AvatarInfos } from '@iupics-manager/models/user-account';
import { Subscription } from 'rxjs';
import { getAvatarTwoLetters, getColorsForString } from './avatar-utils';

@Injectable()
export class AvatarService implements OnDestroy {
  private subscriptions: Subscription[];
  private avatarInfosMap: Map<number, AvatarInfos> = new Map();

  /* private themeColors: string[]; */
  /* @Output() themeChangeEmitter = new EventEmitter<boolean>(); */

  constructor(/* private themeService: ThemeService */) {
    /* this.subscriptions.push(
      this.themeService.activeTheme$.subscribe(() => {
        this.themeColors = this.themeService.getThemeProperty('colorsPalette');
        this.avatarInfosMap.clear();
        this.themeChangeEmitter.emit(true);
      })
    ); */
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => {
      if (sub) {
        sub.unsubscribe();
      }
    });
  }

  getAvatarInfos(user: AvatarInfos): AvatarInfos {
    if (this.avatarInfosMap.has(user.id)) {
      return this.avatarInfosMap.get(user.id);
    }

    return this.generateAvatarInfos(user);
  }

  private generateAvatarInfos({ id, fullname, pictureURL }: AvatarInfos): AvatarInfos {
    const { bg, fg } = getColorsForString(fullname /* , this.themeColors */);

    const infos: AvatarInfos = {
      id,
      fullname,
      backgroundColor: bg,
      foregroundColor: fg,
      abbreviation: getAvatarTwoLetters(fullname),
      pictureURL
    };

    this.avatarInfosMap.set(id, infos);

    return infos;
  }
}
