import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'docIcon'
})
export class DocIconPipe implements PipeTransform {
  transform(value: string, args?: any): any {
    return this.getFileIcon(value);
  }

  /**
   * Obtiens l'icône par rapport à son extension
   * @param {string}fileLink
   */
  private getFileIcon(fileLink: string) {
    if (!fileLink) {
      return 'icon-file';
    } else if (fileLink.endsWith('pdf')) {
      return 'icon-pdf';
    } else if (fileLink.endsWith('csv')) {
      return 'icon-csv';
    } else if (fileLink.endsWith('txt')) {
      return 'icon-txt';
    } else if (fileLink.endsWith('xls') || fileLink.endsWith('xlsx')) {
      return 'icon-excel';
    } else if (fileLink.endsWith('doc') || fileLink.endsWith('docx')) {
      return 'icon-word';
    } else if (fileLink.endsWith('sql')) {
      return 'icon-sql';
    } else if (fileLink.endsWith('zip')) {
      return 'icon-zip';
    } else if (fileLink.endsWith('html')) {
      return 'icon-html';
    } else {
      return 'icon-file';
    }
  }
}
