import { Component, OnInit } from '@angular/core';
import { CompiereDataGridFilterType, CompiereDataGridType } from '@compiere-ws/models/compiere-data-json';
import { LocationService } from '@compiere-ws/services/compiere-location/location.service';
import { OperatorFilterType } from '@iupics-components/models/universal-filter';
import { CardContent } from '@iupics-components/specific/window/customer-360/components/cards/cards-ui/card-content/card-type';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';

@Component({
  selector: 'iu-address-card',
  templateUrl: './address-card.component.html',
  styleUrls: ['./address-card.component.scss']
})
export class AddressCardComponent extends CardContent implements OnInit {
  displayData: any = {};

  constructor(
    private locationService: LocationService,
    private store: DataStoreService,
    private connectorService: SecurityManagerService
  ) {
    super();
  }

  ngOnInit(): void {
    const ad_language = this.connectorService.getIupicsDefaultLanguage().iso_code;
    this.displayData['name'] = this.content.Name;
    this.displayData['isShipTo'] = this.content.IsShipTo === 'Y';
    this.displayData['isBillTo'] = this.content.IsBillTo === 'Y';
    this.displayData['isRemitTo'] = this.content.IsRemitTo === 'Y';
    this.displayData['isPayFrom'] = this.content.IsPayFrom === 'Y';
    this.subcriptions.push(
      this.locationService.getLocation(this.content.C_Location_ID).subscribe((data) => {
        this.displayData['address'] = data.Address1;
        this.displayData['city'] = data.City;
        this.displayData['postalCode'] = data.Postal;
        this.subcriptions.push(
          this.getCountryDataGrid(data?.C_Country_ID, ad_language).subscribe((country) => {
            this.displayData['country'] = country.data[0]['Name'];
          })
        );
      })
    );
  }

  getCountryDataGrid(C_Country_ID: number, ad_language: string) {
    return this.store.getDataGrid({
      windowId: -1,
      compiereRequest: {
        windowType: CompiereDataGridType.TABLE,
        tableName: 'C_Country',
        ad_language,
        filterModel: {
          C_Country_ID: {
            filterType: CompiereDataGridFilterType.SET,
            operators: [OperatorFilterType.EQUALS],
            values: [C_Country_ID]
          }
        }
      }
    });
  }

  parse() {
    // TODO: Country formating
    return `${this.displayData['address']} ${this.displayData['city']} ${this.displayData['postalCode']} ${this.displayData['country']}`;
  }

  copy() {
    navigator.clipboard.writeText(this.parse());
  }

  openMap() {
    window.open('https://www.google.com/maps?q=' + encodeURI(this.parse()), '_blank');
  }
}
