import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { IupicsMessage } from '@iupics-manager/models/iupics-message';
import { MenuItemUI } from '@web-desktop/models/menu-item-ui';
import { FavoriteGroupUiComponent } from './components/favorite-group-ui/favorite-group-ui.component';
import { WidgetGroupUiComponent } from './components/widget-group-ui/widget-group-ui.component';

@Component({
  selector: 'iu-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  @Output()
  openTabEmitter = new EventEmitter<any>();

  @ViewChild(WidgetGroupUiComponent, { static: true })
  widgetGroupUiComponent: WidgetGroupUiComponent;
  @ViewChild(FavoriteGroupUiComponent, { static: true })
  favoriteGroupUiComponent: FavoriteGroupUiComponent;

  constructor() {}

  ngOnInit() {}

  openTab(favorite: MenuItemUI) {
    this.openTabEmitter.emit(favorite);
  }

  refreshDashboard() {
    this.favoriteGroupUiComponent.refreshFavorites();
    this.widgetGroupUiComponent.refreshWidgets();
  }
}
