import { Injectable } from '@angular/core';
import { CompierePrecisionData } from '@compiere-ws/models/compiere-precision-json';
import { AppConfig } from '@iupics-config/app.config';
import { Observable } from 'rxjs';
import { ApiService } from '../api/api.service';

@Injectable()
export class PrecisionDataService {
  private precisionURL: string;

  constructor(private http: ApiService, private config: AppConfig) {}

  getPrecisionDatas(precisionRequests: CompierePrecisionData[]): Observable<CompierePrecisionData[]> {
    if (this.precisionURL === undefined) {
      this.precisionURL = this.config.getBackendResource('precisionData');
    }
    return this.http.post<CompierePrecisionData[]>(`${this.precisionURL}`, precisionRequests);
  }
}
