<div class="iu-searchpanel">
  <p-progressBar
    *ngIf="isLoading"
    [mode]="'indeterminate'"
    [value]="0"
    [showValue]="false"
    [styleClass]="'loading-progressbar'"></p-progressBar>

  <div class="search-body">
    <div class="search-input">
      <iu-input-text-ui
        [placeHolder]="'usearch.searchLabel' | translate"
        tooltip="{{ 'usearch.informationLabel' | translate }}"
        [fieldValue]="textValue"
        [isStandalone]="true"
        [isContextMenuDisabled]="true"
        (keyup)="search($event)"
        class="search-field"
        #inputSearch
        data-cy="usearch-input">
      </iu-input-text-ui>
      <div class="input-icons">
        <i
          class="icon-folder-search"
          [ngClass]="[onlyDocFieldValue === 'N' ? '' : 'active']"
          title="{{ 'usearch.onlyDoc' | translate }}"
          (click)="changeOnlyDocFieldValue()"
          data-cy="usearch-doc-only-switch"
          aria-hidden="true"></i>
        <i
          class="icon-search-bolder"
          title="{{ 'usearch.searchLabel' | translate }}"
          (click)="search($event)"
          data-cy="usearch-search-button"
          aria-hidden="true"></i>
      </div>
    </div>

    <div class="search-categories">
      <ul>
        <ng-container *ngFor="let category of cats">
          <li
            [ngClass]="[selectedCategory === category.label ? 'active' : '']"
            (click)="selectedCategory = category.label; onClick($event)">
            {{ category.label }}
          </li>
        </ng-container>
      </ul>
    </div>
    <div class="search-best-results">
      <!-- Loading -->
      <ng-container *ngIf="isLoading">
        <h1 class="title loading-title">{{ 'generic.loading' | translate }}...</h1>
      </ng-container>
      <!-- Loading -->

      <!-- Error -->
      <ng-container *ngIf="!isFirstSearch && !isLoading && totResult === 0">
        <h1 class="title no-results-title">{{ 'usearch.noResult' | translate }}</h1>
      </ng-container>
      <!-- Error -->

      <!-- Results -->
      <ng-container *ngIf="!isLoading && totResult > 0">
        <h1 *ngIf="topResults.length > 0" class="search-best-results-title">
          {{ 'usearch.bestSuggestions' | translate }}
        </h1>
        <div *ngIf="topResults.length > 0" class="search-best-results-list">
          <ul>
            <ng-container *ngFor="let item of topResults">
              <li>
                <a (click)="openTarget(item)">
                  <span>
                    {{ item.cat.displayValue }}
                  </span>
                  <span>
                    <i class="fas fa-angle-right" aria-hidden="true"></i>
                  </span>
                  <span class="search-best-results-item-name">
                    {{ item.source.displayValue }}
                  </span>
                </a>
              </li>
            </ng-container>
          </ul>
        </div>
      </ng-container>
      <!-- Results -->
    </div>
    <div *ngIf="!isLoading && totResult > 0" (mouseleave)="cancelPreviewFile()" class="search-other-results">
      <p-scrollPanel>
        <!-- Category Not Selected -->
        <div class="search-other-results-grid" *ngIf="('usearch.allResults' | translate) === selectedCategory">
          <div
            *ngFor="let itemCategory of catsResult; let idxcategory = index"
            (mouseleave)="itemCategory.cat.id !== -999 ? '' : cancelPreviewFile()"
            data-cy="usearch-resultList-container"
            [attr.data-cy-catName]="itemCategory.cat.displayValue"
            [ngClass]="[
              'search-other-results-grid-col',
              (idxcategory + 1) % 3 !== 0 && idxcategory < catsResult.length - 1
                ? 'search-other-results-grid-col-border'
                : '',
              idxcategory % 2 === 1 ? 'search-other-results-grid-col-odd' : ''
            ]">
            <h4 (click)="selectedCategory = itemCategory.cat.displayValue; onClick($event)">
              {{ itemCategory.cat.displayValue }} ({{ itemCategory.sources.length }})
            </h4>
            <ul>
              <li *ngFor="let itemResults of itemCategory.sources.slice(0, 10); let idxResults = index">
                <p>
                  <a
                    (click)="
                      itemCategory.cat.id !== -999
                        ? openTarget({ cat: itemCategory.cat, source: itemResults })
                        : downloadFile(itemResults)
                    "
                    (mouseover)="itemCategory.cat.id === -999 ? previewFile($event, itemResults) : ''"
                    data-cy="usearch-resultList-data"
                    [attr.data-cy-index]="idxResults"
                    [attr.data-cy-filename]="itemResults?.name"
                    class="search-other-results-grid-row">
                    <span *ngIf="!isLoading" class="list-icon">
                      <i
                        [ngClass]="[
                          'fa-1x ',
                          itemResults?.isMenu && itemResults?.iconClass ? itemResults?.iconClass : '',
                          itemResults?.isMenu && itemResults?.iconFileClass ? itemResults?.iconFileClass : ''
                        ]"
                        aria-hidden="true"></i>
                    </span>
                    {{ itemResults.displayValue }}
                  </a>
                </p>
              </li>
              <li class="show-more" *ngIf="itemCategory.sources.length > 10">
                <p>
                  <a (click)="seeMoreDetails($event, itemCategory.cat.displayValue)">
                    &mdash; {{ 'usearch.seeMore' | translate }}
                  </a>
                </p>
              </li>
            </ul>
          </div>
        </div>
        <!-- Category Not Selected -->

        <!-- Category Selected -->
        <div
          class="search-other-results-grid category-selected"
          style="--template-rows-count: {{ templateRowsCountResultsByCategory }};"
          *ngIf="('usearch.allResults' | translate) !== selectedCategory">
          <h4 class="selected">{{ selectedCategory }} ({{ resultsByCategory.length }})</h4>
          <ul>
            <li
              *ngFor="let item of resultsByCategory; let idx = index"
              [ngClass]="[idx + 1 > templateRowsCountResultsByCategory ? 'item-border' : '']">
              <p>
                <a
                  (click)="item.cat.id !== -999 ? openTarget({ cat: item.cat, source: item }) : downloadFile(item)"
                  (mouseover)="item.cat.id === -999 ? previewFile($event, item) : ''">
                  <span *ngIf="!isLoading" class="list-icon">
                    <i
                      [ngClass]="[
                        'fa-1x ',
                        item?.isMenu && item?.iconClass ? item?.iconClass : '',
                        item?.isMenu && item?.iconFileClass ? item?.iconFileClass : ''
                      ]"
                      aria-hidden="true"></i>
                  </span>
                  {{ item.displayValue }}
                </a>
              </p>
            </li>
          </ul>
        </div>
        <!-- Category Selected -->
      </p-scrollPanel>
    </div>
    <div class="search-close-btn">
      <i
        class="fas fa-chevron-up"
        title="{{ 'usearch.closeBtnTitle' | translate }}"
        (click)="emitCloseSearch()"
        aria-hidden="true"></i>
    </div>

    <div #overlayArea class="iu-results-separator"></div>
  </div>

  <iu-preview-doc #previewDoc [z_index]="7200">
    <footer class="preview-footer">
      <!-- download button -->
      <button
        pButton
        class="p-button-secondary"
        (click)="downloadFileFromPreview()"
        label="{{ 'generic.download' | translate }}"></button>

      <!-- close button -->
      <button
        pButton
        class="p-button-primary"
        (click)="previewDoc.modalRef.close()"
        label="{{ 'generic.close' | translate }}"></button>
    </footer>
  </iu-preview-doc>
  <!-- File preview -->
  <iu-prime-overlay #opPreviewDoc [appendTo]="'body'" [handleAlign]="handleAlign.bind(this)">
    <ng-template pTemplate>
      <div class="usearch-file-preview">
        <iu-preview-doc
          #previewDocModal
          [z_index]="7200"
          [fileName]="fileName"
          [urlFile]="urlFile"
          [modal]="false"
          [imgStyle]="{ 'max-width': '100%', height: '100%' }"
          (onPreviewError)="cancelPreviewFile()"></iu-preview-doc>
      </div>
    </ng-template>
  </iu-prime-overlay>
  <!-- File preview -->
</div>
