import { Injectable } from '@angular/core';
import { ApiService } from '@compiere-ws/services/api/api.service';
import { AppConfig } from '@iupics-config/app.config';
import { Observable } from 'rxjs';

@Injectable()
export class ElasticService {
  private wsSearchUrl: string;

  constructor(private http: ApiService, private config: AppConfig) {
    this.wsSearchUrl = this.config.getBackendResource('wsSearch');
  }

  getWsSearch(query: string): Observable<any> {
    const jsonSearch = {
      size: 1000,
      query: {
        query_string: {
          fields: ['p*', 's*', 'q*'],
          query: query
        }
      }
    };
    return this.http.post(`${this.wsSearchUrl}`, jsonSearch);
  }
}
