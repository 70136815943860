<div [ngStyle]="{ display: isOpen ? 'block' : 'none' }" (click)="cancelDialog()" class="iu-info-dialog-background"></div>
<p-toast position="center" [key]="key">
  <ng-template let-message pTemplate="message">
    <div class="info-dialog-content">
      <i
        *ngIf="dialogType === 'confirm' || dialogType === 'confirmyesno'"
        class="fa fa-exclamation-triangle fa-3x"
        aria-hidden="true"
      ></i>
      <i *ngIf="dialogType === 'info'" class="fa fa-info-circle fa-3x" aria-hidden="true"></i>
      <i *ngIf="dialogType === 'error'" class="fa fa-times-circle fa-3x" aria-hidden="true"></i>
      <h3>{{ message.summary }}</h3>
      <p [innerHTML]="message.detail"></p>
    </div>
    <div class="info-dialog-buttons apiz-p-grid ui-fluid">
      <div *ngIf="dialogType === 'confirm'" class="p-col-6">
        <button
          type="button"
          pButton
          (click)="cancelDialog()"
          label="{{ 'infodialog.cancel' | translate }}"
          class="p-button-secondary"
          data-cy="info-dialog-cancel-btn"
          [attr.data-cy-type]="dialogType"
        ></button>
      </div>
      <div *ngIf="dialogType === 'confirm'" class="p-col-6">
        <button
          #confirm
          type="button"
          pButton
          (click)="confirmDialog()"
          label="{{ 'infodialog.ok' | translate }}"
          class="p-button p-button-primary"
          data-cy="info-dialog-confirm-btn"
          [attr.data-cy-type]="dialogType"
        ></button>
      </div>
      <div *ngIf="dialogType === 'confirmyesno'" class="p-col-6">
        <button
          type="button"
          pButton
          (click)="cancelDialog()"
          label="{{ 'infodialog.no' | translate }}"
          class="p-button-secondary"
          data-cy="info-dialog-cancel-btn"
          [attr.data-cy-type]="dialogType"
        ></button>
      </div>
      <div *ngIf="dialogType === 'confirmyesno'" class="p-col-6">
        <button
          #confirmYesNo
          type="button"
          pButton
          (click)="confirmDialog()"
          label="{{ 'infodialog.yes' | translate }}"
          class="p-button p-button-primary"
          data-cy="info-dialog-confirm-btn"
          [attr.data-cy-type]="dialogType"
        ></button>
      </div>
      <div *ngIf="dialogType !== 'confirm' && dialogType !== 'confirmyesno'" class="p-col-12">
        <button
          #confirmOK
          type="button"
          pButton
          (click)="confirmDialog()"
          label="{{ 'infodialog.ok' | translate }}"
          class="p-button p-button-primary"
          data-cy="info-dialog-OK-btn"
          [attr.data-cy-type]="dialogType"
        ></button>
      </div>
    </div>
  </ng-template>
</p-toast>
