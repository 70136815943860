import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { KeyCode } from '@iupics-config/keycode.enum';
import { Global } from '@iupics-manager/models/global-var';
import { ContextMenuService } from '@web-desktop/components/workspace/controllers/context-menu/context-menu.service';
import { IupicsComponentType } from '@web-desktop/models/iupics-context-menu';
import { MenuCategoryUI, MenuItemUI } from '@web-desktop/models/menu-item-ui';
import { has } from 'lodash';
import { Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { MenuUiManagerService } from '../../controllers/menu-ui-manager/menu-ui-manager.service';

export function fadeIn(selector = ':enter', duration = '400ms ease-out') {
  return [
    transition('* => *', [
      query(
        selector,
        [
          style({ opacity: 0, transform: 'translateY(-5px)' }),
          stagger('50ms', [animate(duration, style({ opacity: 1, transform: 'translateY(0px)' }))])
        ],
        { optional: true }
      )
    ])
  ];
}

export function fadeOut(selector = ':leave', duration = '200ms') {
  return [
    transition('* => *', [
      query(selector, [style({ opacity: 1 }), stagger('50ms', [animate(duration, style({ opacity: 0 }))])], { optional: true })
    ])
  ];
}

@Component({
  selector: 'iu-menu-ui',
  templateUrl: './menu-ui.component.html',
  styleUrls: ['./menu-ui.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [trigger('fadeIn', fadeIn()), trigger('fadeOut', fadeOut())]
})
export class MenuUiComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('searchInput')
  searchInput: ElementRef;

  menus$: Observable<MenuItemUI[]>;
  selectedCategory: MenuCategoryUI;
  subscriptions: any[] = [];
  savedItem: any;
  componentType = IupicsComponentType.MENU_UI;
  isMobile = Global.isMobile();
  isMobileWidth = Global.isMobileWidth();
  searchValue: string;

  @Output()
  createTabEmitter: EventEmitter<any> = new EventEmitter();
  @Output()
  closeEmitter: EventEmitter<any> = new EventEmitter();

  @Input()
  opened = false;

  selectedMenu: MenuItemUI;
  typeofSelected: 'menu' | 'category';

  private keyupListener: Function;
  private keydownListener: Function;

  ghosts = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1];

  categoryCollapsed: { [name: string]: boolean } = {};

  constructor(private menuUiManager: MenuUiManagerService, public cmService: ContextMenuService, private renderer: Renderer2) {}

  ngOnInit() {}

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.opened.currentValue) {
      this.keyupListener = this.renderer.listen(document, 'keyup', this.handleKeyup.bind(this));
      this.keydownListener = this.renderer.listen(document, 'keydown', this.handleKeydown.bind(this));
    } else {
      if (this.keyupListener !== undefined) {
        this.keyupListener();
      }
      if (this.keydownListener !== undefined) {
        this.keydownListener();
      }
    }
  }

  initData() {
    if (document.getElementById('iu_menu_list')) {
      document.getElementById('iu_menu_list').scrollTo({ top: 0 });
    }
    this.selectedCategory = undefined;
    this.selectedMenu = undefined;
    this.searchValue = '';
    this.menus$ = this.menuUiManager.loadMenusList().pipe(
      switchMap((menus$) => (this.searchValue ? this.menuUiManager.search(this.searchValue, this.selectedCategory) : of(menus$))),
      tap((_) => (this.ghosts = []))
    );
  }

  onClick(menuClicked: MenuItemUI) {
    if (menuClicked) {
      this.createTabEmitter.emit(menuClicked);
      // emit pour fermer
      this.closeEmitter.emit(false);
    }
  }

  /**
   * @listens document:keyup
   * @param {KeyboardEvent}event
   */
  handleKeyup(event: KeyboardEvent) {
    if (this.opened) {
      // eslint-disable-next-line deprecation/deprecation
      switch (event.keyCode) {
        case KeyCode.ESCAPE:
          this.closeEmitter.emit(false);
          break;
        case KeyCode.ENTER:
          if (this.typeofSelected === 'menu') {
            if (this.selectedMenu !== undefined) {
              this.onClick(this.selectedMenu);
            } else {
              this.subscriptions.push(
                this.menus$.subscribe((menus) => {
                  const firstMenu = menus.find((menu) => menu.menu_category.id !== -1)
                    ? menus.find((menu) => menu.menu_category.id !== -1)[0]
                    : undefined;

                  this.onClick(firstMenu);
                })
              );
            }
          } else if (this.typeofSelected === 'category') {
            this.typeofSelected = 'menu';
          }
          break;
        default:
          break;
      }
      event.stopPropagation();
    }
  }

  /**
   * @listens document:keydown
   * @param {KeyboardEvent}event
   */
  handleKeydown(event: KeyboardEvent) {
    if (this.opened) {
      if (this.searchInput) {
        this.searchInput.nativeElement.focus();
      }
      // eslint-disable-next-line deprecation/deprecation
      switch (event.keyCode) {
        case KeyCode.LEFT_ARROW:
          event.preventDefault();
          break;
        case KeyCode.UP_ARROW:
          event.preventDefault();
          break;
        case KeyCode.RIGHT_ARROW:
          event.preventDefault();
          break;
        case KeyCode.DOWN_ARROW:
          event.preventDefault();
          break;
        case KeyCode.TAB:
          if (document.documentElement.clientWidth > 640) {
            event.preventDefault();
          }
          break;
        case KeyCode.ENTER:
          event.preventDefault();
          break;
        default:
          break;
      }
    }
  }

  display(event: Event) {
    event.stopPropagation();
    this.initData();
  }

  search(searchValue: string, event?: KeyboardEvent, groupedMenu?: { category: MenuCategoryUI; items: MenuItemUI[] }[]) {
    // eslint-disable-next-line deprecation/deprecation
    if (event === undefined || MenuUiManagerService.keyCodeToAvoid.indexOf(event.keyCode) < 0 || event.type === 'search') {
      this.menus$ = this.menuUiManager.search(searchValue, this.selectedCategory);
      this.selectedMenu = undefined;
    } else {
      // eslint-disable-next-line deprecation/deprecation
      if (event !== undefined && event.keyCode !== KeyCode.ESCAPE && groupedMenu !== undefined) {
        // eslint-disable-next-line deprecation/deprecation
        if (event.keyCode === KeyCode.TAB && document.documentElement.clientWidth > 640) {
          this.typeofSelected = this.typeofSelected === 'menu' ? 'category' : 'menu';
        }
        if (document.documentElement.clientWidth <= 640 && this.typeofSelected === 'category') {
          this.typeofSelected = 'menu';
        }
        this.typeofSelected = this.typeofSelected === undefined ? 'menu' : this.typeofSelected;
        // eslint-disable-next-line deprecation/deprecation
        this.switchSelectedItem(event.keyCode, groupedMenu);
      }
    }
  }

  /**
   * Lance la bonne méthode selon le type de switch sélectionné
   * @param {KeyCode}keycode
   * @param {{ category: MenuCategoryUI; items: MenuItemUI[] }[]}groupedMenu
   */
  private switchSelectedItem(keycode: KeyCode, groupedMenu: { category: MenuCategoryUI; items: MenuItemUI[] }[]) {
    if (this.typeofSelected === 'category') {
      this.switchSelectedCategory(keycode, groupedMenu);
    } else {
      this.switchSelectedMenu(
        keycode,
        groupedMenu.filter((gm) => !has(this.categoryCollapsed, gm.category.name) || !this.categoryCollapsed[gm.category.name])
      );
    }
  }

  /**
   * Permet de se déplacer sur l'élément suivant selon la touche entrée
   * @param {KeyCode}keycode
   * @param {{ category: MenuCategoryUI; items: MenuItemUI[] }[]}groupedMenu
   */
  private switchSelectedMenu(keycode: KeyCode, groupedMenu: { category: MenuCategoryUI; items: MenuItemUI[] }[]) {
    // vérification de l'intégrité de groupedMenu
    if (!groupedMenu || groupedMenu.length === 0) {
      return;
    }
    if (this.selectedMenu === undefined) {
      this.selectedMenu = this.menuUiManager.getFirstMenu(groupedMenu, this.selectedCategory);
    } else {
      let offset = 0;
      switch (keycode) {
        case KeyCode.LEFT_ARROW:
          offset = -1;
          break;
        case KeyCode.UP_ARROW:
          offset = document.documentElement.clientWidth > 1024 ? -6 : document.documentElement.clientWidth > 480 ? -4 : -1;
          break;
        case KeyCode.RIGHT_ARROW:
          offset = 1;
          break;
        case KeyCode.DOWN_ARROW:
          offset = document.documentElement.clientWidth > 1024 ? 6 : document.documentElement.clientWidth > 480 ? 4 : 1;
          break;
      }
      this.selectedMenu = this.menuUiManager.getNextMenu(groupedMenu, this.selectedMenu, offset, this.selectedCategory);
    }
    if (this.selectedMenu !== undefined) {
      this.menuUiManager.scrollToItem('iu_menu_item_' + this.selectedMenu.menu_id, 'iu_menu_list');
    }
  }

  /**
   * Change de catégorie selon la touche enfoncée
   * @param {KeyCode}keycode
   * @param {{ category: MenuCategoryUI; items: MenuItemUI[] }[]}groupedMenu
   */
  private switchSelectedCategory(keycode: KeyCode, groupedMenu: { category: MenuCategoryUI; items: MenuItemUI[] }[]) {
    if (this.selectedCategory === undefined) {
      this.selectedCategory = this.menuUiManager.getFirstCategory(groupedMenu);
    } else {
      let offset = 0;
      if (keycode === KeyCode.UP_ARROW || keycode === KeyCode.RIGHT_ARROW) {
        offset = -1;
      } else if (keycode === KeyCode.DOWN_ARROW || keycode === KeyCode.LEFT_ARROW) {
        offset = 1;
      }
      this.selectedCategory = this.menuUiManager.getNextCategory(groupedMenu, this.selectedCategory, offset);
    }
    this.changeCategory(this.selectedCategory);
    if (this.selectedCategory !== undefined) {
      this.menuUiManager.scrollToItem('iu_menu_category_item_' + this.selectedCategory.name, 'iu_menu_category_list');
    }
  }

  /**
   * Change de catégorie
   * @param {MenuCategoryUI}selectedCategory
   */
  changeCategory(selectedCategory: MenuCategoryUI) {
    this.selectedCategory = this.menuUiManager.changeCategory(selectedCategory);
    this.selectedMenu = undefined;
    this.search(this.searchInput.nativeElement.value);
  }

  onFieldsetToggle(event: { originalEvent: MouseEvent; collapsed: boolean }, category: MenuCategoryUI) {
    event.originalEvent.stopPropagation();
    const fn = () => {
      this.categoryCollapsed[category.name] = event.collapsed;
      // Vérification de l'intégrité de selectedMenu
      if (this.selectedMenu && this.selectedMenu.menu_category.name === category.name) {
        this.selectedMenu = undefined;
      }
    };
    // petit hack pour avoir l'animation de collapsed et d'extend du fieldset
    if (event.collapsed) {
      setTimeout(() => {
        fn();
      }, 500);
    } else {
      fn();
    }
  }
}
