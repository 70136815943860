<div class="badge-container" [attr.data-rule]="data.rule" iuLoading [dataToWatch]="data" role="button" (click)="onClick($event)">
  <span *ngIf="data?.label" class="badge-label">{{ data.label }}</span>
  <i
    *ngIf="data?.hasIcon"
    [ngClass]="{
      'badge-value': true,
      'icon-checkmark': data.rule === 1,
      'icon-cross': data.rule === -1
    }"
    aria-hidden="true"
  ></i>
  <span *ngIf="!data?.hasIcon" class="badge-value" iuNumberAnimation [data]="data.value">
    {{ data.value }}
  </span>
</div>
