/* eslint-disable deprecation/deprecation */
import { Directive, ElementRef, Input, OnDestroy, Renderer2 } from '@angular/core';
import { KeybindConfigService } from '@iupics-config/keybind.config.service';
import { KeyCode } from '@iupics-config/keycode.enum';
import { KeybindStateManagerService } from '@iupics-manager/managers/keybind-state-manager/keybind-state-manager.service';

@Directive({
  selector: '[iuKeybinding]'
})
export class KeybindingDirective implements OnDestroy {
  private _isActive = false;
  private altListener: Function;
  private keyupListener: Function;
  private blurListener: Function;
  private spanElement: any;

  @Input('iuKeybinding-active')
  set isActive(value: boolean) {
    this._isActive = value;
    if (value === true) {
      if (this.altListener === undefined) {
        this.altListener = this.renderer.listen(document, 'keydown.alt', this.handleKeydown.bind(this));
      }
      if (this.blurListener === undefined) {
        this.blurListener = this.renderer.listen(window, 'blur', this.handleBlur.bind(this));
      }
    } else {
      if (this.altListener !== undefined) {
        this.altListener();
        this.altListener = undefined;
      }
      if (this.keyupListener !== undefined) {
        this.keyupListener();
        this.keyupListener = undefined;
      }
      if (this.blurListener !== undefined) {
        this.blurListener();
        this.blurListener = undefined;
      }
    }
  }

  get isActive() {
    return this._isActive;
  }
  @Input('iuKeybinding-code') key: string;
  @Input('iuKeybinding-shift') needShift = false;
  @Input('iuKeybinding-focusTab') focusTab: any;
  @Input('iuKeybinding-badge-active') isBadgeActive = true;
  @Input('iuKeybinding-badge-function') badgeFunction = this.showBadge;
  @Input('iuKeybinding-behavior-context') behaviorContext = this;
  @Input('iuKeybinding-behavior-function') behaviorFunction = this.triggerKeybind;
  @Input('iuKeybinding-event-mode') eventMode: 'click' | 'mouseup' | 'mousedown' = 'click';

  constructor(
    private el: ElementRef<HTMLElement>,
    private renderer: Renderer2,
    private keybindingConfig: KeybindConfigService,
    private keybindStateManager: KeybindStateManagerService
  ) {}

  private handleKeydown(event: KeyboardEvent) {
    event.stopPropagation();
    event.preventDefault();
    if (this.isBadgeActive === true && event.altKey && event.keyCode === KeyCode.ALT && this.spanElement === undefined) {
      this.spanElement = this.badgeFunction(this.key);
      if (this.altListener !== undefined) {
        this.altListener();
        this.altListener = undefined;
      }
    }

    if (this.keyupListener === undefined) {
      this.keyupListener = this.renderer.listen(document, 'keyup', this.handleKeyup.bind(this));
    }
  }

  private handleKeyup(event: KeyboardEvent) {
    event.stopPropagation();
    event.preventDefault();
    if (event.keyCode === KeyCode.ALT) {
      this.spanElement = this.removeBadge(this.spanElement);
      if (this.altListener === undefined) {
        this.altListener = this.renderer.listen(document, 'keydown.alt', this.handleKeydown.bind(this));
      }
      if (this.keyupListener !== undefined) {
        this.keyupListener();
        this.keyupListener = undefined;
      }
    }
    if (
      this.isActive &&
      (this.focusTab !== undefined && this.focusTab !== '' ? this.focusTab === this.keybindStateManager.getFocusedTab() : true)
    ) {
      if (event.altKey === true && event.keyCode !== KeyCode.ALT) {
        let keyCode: KeyCode;
        if (this.key !== undefined && this.key !== null && this.key !== '') {
          keyCode = this.keybindingConfig.getKeyCode(this.key);
        }
        if (
          (event.shiftKey === this.needShift &&
            event.keyCode !== KeyCode.SHIFT &&
            keyCode !== undefined &&
            keyCode !== null &&
            keyCode !== KeyCode.NONE &&
            event.keyCode === keyCode) ||
          this.key === 'custom'
        ) {
          this.behaviorFunction.bind(this.behaviorContext)(event, this.key);
        }
      }
    }
  }

  private handleBlur(event: Event) {
    event.stopPropagation();
    this.spanElement = this.removeBadge(this.spanElement);
    if (this.altListener === undefined) {
      this.altListener = this.renderer.listen(document, 'keydown.alt', this.handleKeydown.bind(this));
    }
    if (this.keyupListener !== undefined) {
      this.keyupListener();
      this.keyupListener = undefined;
    }
  }

  /**
   * The default behavior of the keybind is a simple click on the element where the directive is
   * @param {KeyboardEvent}event The event trigger by the keyboard
   * @param {string}key The key path to the reference of the keyCode in keybinding.json
   */
  private triggerKeybind(event: KeyboardEvent, key: string) {
    this.el.nativeElement.focus();

    if (this.eventMode === 'click') {
      this.el.nativeElement.click();
    } else {
      const mouseupEvent = document.createEvent('MouseEvents');
      mouseupEvent.initEvent(this.eventMode, true, true);
      this.el.nativeElement.dispatchEvent(mouseupEvent);
    }
  }

  /**
   * Default behavior of the span element shown by the directive
   * @param {string}key The key path to the reference of the keyCode in keybinding.json
   * @returns {HTMLElement}
   */
  private showBadge(key: string): HTMLElement {
    if (key) {
      const keyCode = KeyCode[this.keybindingConfig.getKeyCode(this.key)];
      if (keyCode !== undefined) {
        const keyStr = keyCode.replace('KEY_', '');
        const spanElement = this.renderer.createElement('span');
        const text = this.renderer.createText(keyStr);
        this.renderer.addClass(spanElement, 'iupics-badge');
        this.renderer.addClass(spanElement, this.needShift ? 'iupics-badge-shift' : 'iupics-badge-not-shift');
        this.renderer.appendChild(spanElement, text);
        this.renderer.insertBefore(this.el.nativeElement, spanElement, this.el.nativeElement.firstChild);
        return spanElement;
      }
      return undefined;
    }
    return undefined;
  }

  /**
   * Removes the spanElement from the DOM and returns undefined
   * @param spanElement The spanElement to remove
   * @returns {undefined}
   */
  private removeBadge(spanElement: HTMLElement): undefined {
    if (spanElement !== undefined) {
      this.renderer.removeChild(this.el.nativeElement, spanElement);
      return undefined;
    }
  }

  ngOnDestroy() {
    if (this.spanElement) {
      this.spanElement = this.removeBadge(this.spanElement);
    }
    if (this.altListener) {
      this.altListener();
      this.altListener = undefined;
    }
    if (this.keyupListener) {
      this.keyupListener();
      this.keyupListener = undefined;
    }
    if (this.blurListener) {
      this.blurListener();
      this.blurListener = undefined;
    }
  }
}
