import { CompiereDataGridFilterType, CompiereDataGridType } from '@compiere-ws/models/compiere-data-json';
import { CustomDesignItemType } from '@iupics-components/models/custom-design';
import { OperatorFilterType } from '@iupics-components/models/universal-filter';
import * as IBAN from 'iban';
export enum InputInfoSource {
  QR = 'QR',
  BVR = 'BVR'
}
const regexPostalCode = new Map();

export class InputInfo {
  IBAN: string;
  QRIBAN: string;
  isSCOR: boolean;
  vendorName: string;
  address1: string;
  address2: string;
  postalCode: string;
  city: string;
  currency: string;
  amountToPay: number;
  xxReference: string;
  bankAccountId: string;
  bvr: string;
  vendorRef: string;
  warningvendorRefQr = false;
  warningQRR = false;
  countryCode: string;
  countryName: string;
  errors = [];
  logicalErrors = 0;
  logicalWarnings = 0;
  isCorrect = false;
  infoSource: InputInfoSource;
  constructor(info: string[]) {
    if (info.filter((l) => l.length > 0).length === 1) {
      this.setInfoFromBVR(info[0].replace(/ /g, ''));
    } else {
      this.setInfoFromQR(info);
    }
    if (this.errors.length === 0) {
      this.isCorrect = true;
    }
    regexPostalCode.set(
      'GB',
      /GIR[ ]?0AA|((AB|AL|B|BA|BB|BD|BH|BL|BN|BR|BS|BT|CA|CB|CF|CH|CM|CO|CR|CT|CV|CW|DA|DD|DE|DG|DH|DL|DN|DT|DY|E|EC|EH|EN|EX|FK|FY|G|GL|GY|GU|HA|HD|HG|HP|HR|HS|HU|HX|IG|IM|IP|IV|JE|KA|KT|KW|KY|L|LA|LD|LE|LL|LN|LS|LU|M|ME|MK|ML|N|NE|NG|NN|NP|NR|NW|OL|OX|PA|PE|PH|PL|PO|PR|RG|RH|RM|S|SA|SE|SG|SK|SL|SM|SN|SO|SP|SR|SS|ST|SW|SY|TA|TD|TF|TN|TQ|TR|TS|TW|UB|W|WA|WC|WD|WF|WN|WR|WS|WV|YO|ZE)(\d[\dA-Z]?[ ]?\d[ABD-HJLN-UW-Z]{2}))|BFPO[ ]?\d{1,4}/
    );
    regexPostalCode.set('JE', /JE\d[\dA-Z]?[ ]?\d[ABD-HJLN-UW-Z]{2}/);
    regexPostalCode.set('GG', /GY\d[\dA-Z]?[ ]?\d[ABD-HJLN-UW-Z]{2}/);
    regexPostalCode.set('IM', /IM\d[\dA-Z]?[ ]?\d[ABD-HJLN-UW-Z]{2}/);
    regexPostalCode.set('US', /\d{5}([ \-]\d{4})?/);
    regexPostalCode.set('CA', /[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ ]?\d[ABCEGHJ-NPRSTV-Z]\d/);
    regexPostalCode.set('DE', /\d{5}/);
    regexPostalCode.set('JP', /\d{3}-\d{4}/);
    regexPostalCode.set('FR', /\d{2}[ ]?\d{3}/);
    regexPostalCode.set('AU', /\d{4}/);
    regexPostalCode.set('IT', /\d{5}/);
    regexPostalCode.set('CH', /\d{4}/);
    regexPostalCode.set('AT', /\d{4}/);
    regexPostalCode.set('ES', /\d{5}/);
    regexPostalCode.set('NL', /\d{4}[ ]?[A-Z]{2}/);
    regexPostalCode.set('BE', /\d{4}/);
    regexPostalCode.set('DK', /\d{4}/);
    regexPostalCode.set('SE', /\d{3}[ ]?\d{2}/);
    regexPostalCode.set('NO', /\d{4}/);
    regexPostalCode.set('BR', /\d{5}[\-]?\d{3}/);
    regexPostalCode.set('PT', /\d{4}([\-]\d{3})?/);
    regexPostalCode.set('FI', /\d{5}/);
    regexPostalCode.set('AX', /22\d{3}/);
    regexPostalCode.set('KR', /\d{3}[\-]\d{3}/);
    regexPostalCode.set('CN', /\d{6}/);
    regexPostalCode.set('TW', /\d{3}(\d{2})?/);
    regexPostalCode.set('SG', /\d{6}/);
    regexPostalCode.set('DZ', /\d{5}/);
    regexPostalCode.set('AD', /AD\d{3}/);
    regexPostalCode.set('AR', /([A-HJ-NP-Z])?\d{4}([A-Z]{3})?/);
    regexPostalCode.set('AM', /(37)?\d{4}/);
    regexPostalCode.set('AZ', /\d{4}/);
    regexPostalCode.set('BH', /((1[0-2]|[2-9])\d{2})?/);
    regexPostalCode.set('BD', /\d{4}/);
    regexPostalCode.set('BB', /(BB\d{5})?/);
    regexPostalCode.set('BY', /\d{6}/);
    regexPostalCode.set('BM', /[A-Z]{2}[ ]?[A-Z0-9]{2}/);
    regexPostalCode.set('BA', /\d{5}/);
    regexPostalCode.set('IO', /BBND 1ZZ/);
    regexPostalCode.set('BN', /[A-Z]{2}[ ]?\d{4}/);
    regexPostalCode.set('BG', /\d{4}/);
    regexPostalCode.set('KH', /\d{5}/);
    regexPostalCode.set('CV', /\d{4}/);
    regexPostalCode.set('CL', /\d{7}/);
    regexPostalCode.set('CR', /\d{4,5}|\d{3}-\d{4}/);
    regexPostalCode.set('HR', /\d{5}/);
    regexPostalCode.set('CY', /\d{4}/);
    regexPostalCode.set('CZ', /\d{3}[ ]?\d{2}/);
    regexPostalCode.set('DO', /\d{5}/);
    regexPostalCode.set('EC', /([A-Z]\d{4}[A-Z]|(?:[A-Z]{2})?\d{6})?/);
    regexPostalCode.set('EG', /\d{5}/);
    regexPostalCode.set('EE', /\d{5}/);
    regexPostalCode.set('FO', /\d{3}/);
    regexPostalCode.set('GE', /\d{4}/);
    regexPostalCode.set('GR', /\d{3}[ ]?\d{2}/);
    regexPostalCode.set('GL', /39\d{2}/);
    regexPostalCode.set('GT', /\d{5}/);
    regexPostalCode.set('HT', /\d{4}/);
    regexPostalCode.set('HN', /(?:\d{5})?/);
    regexPostalCode.set('HU', /\d{4}/);
    regexPostalCode.set('IS', /\d{3}/);
    regexPostalCode.set('IN', /\d{6}/);
    regexPostalCode.set('ID', /\d{5}/);
    regexPostalCode.set('IL', /\d{5}/);
    regexPostalCode.set('JO', /\d{5}/);
    regexPostalCode.set('KZ', /\d{6}/);
    regexPostalCode.set('KE', /\d{5}/);
    regexPostalCode.set('KW', /\d{5}/);
    regexPostalCode.set('LA', /\d{5}/);
    regexPostalCode.set('LV', /\d{4}/);
    regexPostalCode.set('LB', /(\d{4}([ ]?\d{4})?)?/);
    regexPostalCode.set('LI', /(948[5-9])|(949[0-7])/);
    regexPostalCode.set('LT', /\d{5}/);
    regexPostalCode.set('LU', /\d{4}/);
    regexPostalCode.set('MK', /\d{4}/);
    regexPostalCode.set('MY', /\d{5}/);
    regexPostalCode.set('MV', /\d{5}/);
    regexPostalCode.set('MT', /[A-Z]{3}[ ]?\d{2,4}/);
    regexPostalCode.set('MU', /(\d{3}[A-Z]{2}\d{3})?/);
    regexPostalCode.set('MX', /\d{5}/);
    regexPostalCode.set('MD', /\d{4}/);
    regexPostalCode.set('MC', /980\d{2}/);
    regexPostalCode.set('MA', /\d{5}/);
    regexPostalCode.set('NP', /\d{5}/);
    regexPostalCode.set('NZ', /\d{4}/);
    regexPostalCode.set('NI', /((\d{4}-)?\d{3}-\d{3}(-\d{1})?)?/);
    regexPostalCode.set('NG', /(\d{6})?/);
    regexPostalCode.set('OM', /(PC )?\d{3}/);
    regexPostalCode.set('PK', /\d{5}/);
    regexPostalCode.set('PY', /\d{4}/);
    regexPostalCode.set('PH', /\d{4}/);
    regexPostalCode.set('PL', /\d{2}-\d{3}/);
    regexPostalCode.set('PR', /00[679]\d{2}([ \-]\d{4})?/);
    regexPostalCode.set('RO', /\d{6}/);
    regexPostalCode.set('RU', /\d{6}/);
    regexPostalCode.set('SM', /4789\d/);
    regexPostalCode.set('SA', /\d{5}/);
    regexPostalCode.set('SN', /\d{5}/);
    regexPostalCode.set('SK', /\d{3}[ ]?\d{2}/);
    regexPostalCode.set('SI', /\d{4}/);
    regexPostalCode.set('ZA', /\d{4}/);
    regexPostalCode.set('LK', /\d{5}/);
    regexPostalCode.set('TJ', /\d{6}/);
    regexPostalCode.set('TH', /\d{5}/);
    regexPostalCode.set('TN', /\d{4}/);
    regexPostalCode.set('TR', /\d{5}/);
    regexPostalCode.set('TM', /\d{6}/);
    regexPostalCode.set('UA', /\d{5}/);
    regexPostalCode.set('UY', /\d{5}/);
    regexPostalCode.set('UZ', /\d{6}/);
    regexPostalCode.set('VA', /00120/);
    regexPostalCode.set('VE', /\d{4}/);
    regexPostalCode.set('ZM', /\d{5}/);
    regexPostalCode.set('AS', /96799/);
    regexPostalCode.set('CC', /6799/);
    regexPostalCode.set('CK', /\d{4}/);
    regexPostalCode.set('RS', /\d{6}/);
    regexPostalCode.set('ME', /8\d{4}/);
    regexPostalCode.set('CS', /\d{5}/);
    regexPostalCode.set('YU', /\d{5}/);
    regexPostalCode.set('CX', /6798/);
    regexPostalCode.set('ET', /\d{4}/);
    regexPostalCode.set('FK', /FIQQ 1ZZ/);
    regexPostalCode.set('NF', /2899/);
    regexPostalCode.set('FM', /(9694[1-4])([ \-]\d{4})?/);
    regexPostalCode.set('GF', /9[78]3\d{2}/);
    regexPostalCode.set('GN', /\d{3}/);
    regexPostalCode.set('GP', /9[78][01]\d{2}/);
    regexPostalCode.set('GS', /SIQQ 1ZZ/);
    regexPostalCode.set('GU', /969[123]\d([ \-]\d{4})?/);
    regexPostalCode.set('GW', /\d{4}/);
    regexPostalCode.set('HM', /\d{4}/);
    regexPostalCode.set('IQ', /\d{5}/);
    regexPostalCode.set('KG', /\d{6}/);
    regexPostalCode.set('LR', /\d{4}/);
    regexPostalCode.set('LS', /\d{3}/);
    regexPostalCode.set('MG', /\d{3}/);
    regexPostalCode.set('MH', /969[67]\d([ \-]\d{4})?/);
    regexPostalCode.set('MN', /\d{6}/);
    regexPostalCode.set('MP', /9695[012]([ \-]\d{4})?/);
    regexPostalCode.set('MQ', /9[78]2\d{2}/);
    regexPostalCode.set('NC', /988\d{2}/);
    regexPostalCode.set('NE', /\d{4}/);
    regexPostalCode.set('VI', /008(([0-4]\d)|(5[01]))([ \-]\d{4})?/);
    regexPostalCode.set('PF', /987\d{2}/);
    regexPostalCode.set('PG', /\d{3}/);
    regexPostalCode.set('PM', /9[78]5\d{2}/);
    regexPostalCode.set('PN', /PCRN 1ZZ/);
    regexPostalCode.set('PW', /96940/);
    regexPostalCode.set('RE', /9[78]4\d{2}/);
    regexPostalCode.set('SH', /(ASCN|STHL) 1ZZ/);
    regexPostalCode.set('SJ', /\d{4}/);
    regexPostalCode.set('SO', /\d{5}/);
    regexPostalCode.set('SZ', /[HLMS]\d{3}/);
    regexPostalCode.set('TC', /TKCA 1ZZ/);
    regexPostalCode.set('WF', /986\d{2}/);
    regexPostalCode.set('XK', /\d{5}/);
    regexPostalCode.set('YT', /976\d{2}/);
  }
  setInfoFromBVR(info: string) {
    this.infoSource = InputInfoSource.BVR;
    // 2 8 2 1 > 7 19 1 + 9
    // 0100000027051 > 000000100000000000000000011 + 000000001>
    // 2 8 2 1 > 16 + 9 Sans référence
    // 0100000027051 > 0000000000000001 + 010044912>
    // 0100000027051 > 0000000000000001 + 000000001>
    let matches = info.match(/([^>]*)(?=>)/);
    let part1;
    let part2;
    let part3;
    if (matches && matches[0] && matches[0].length > 0) {
      part1 = matches[0];
    }
    matches = info.match(/(>[^\+]*)(?=\+)/);
    if (matches && matches[0] && matches[0].length > 0) {
      part2 = matches[0].substring(1);
    }
    matches = info.match(/(\+[^>]*)(?=>)/);
    if (matches && matches[0] && matches[0].length > 0) {
      part3 = matches[0].substring(1);
    }
    if (part1 && part1.length === 13) {
      const stringAmount = part1.substring(2, part1.length - 3) + '.' + part1.substring(part1.length - 3, part1.length - 1);
      this.amountToPay = stringAmount && stringAmount.replace ? parseFloat(stringAmount.replace(',', '.')) : 0;
    }
    if (part2 && part2.length >= 16) {
      if (part2.length === 27) {
        this.xxReference = part2.substring(0, 7);
      }
      this.bvr = part2;
    }
    if (part3 && part3.length === 9) {
      this.bankAccountId = part3;
    }
    if (this.bvr === undefined || this.bankAccountId === undefined) {
      this.errors.push('bvrCodeInvalid');
    }
    this.currency = 'CHF';
  }
  setInfoFromQR(info: string[]) {
    this.infoSource = InputInfoSource.QR;
    if (info[3] && info[3].trim() !== '') {
      this.IBAN = info[3].trim();
      if (!isIBANValid(this.IBAN)) {
        this.errors.push('ibanInvalid');
      } else {
        const CheckIBanNB = parseInt(this.IBAN.substring(4, 4 + 5), 10);
        if (29999 < CheckIBanNB && CheckIBanNB < 32000) {
          this.QRIBAN = this.IBAN;
          this.IBAN = null;
        }
      }
    } else {
      this.errors.push('iban');
    }
    if (info[5] && info[5].trim() !== '') {
      this.vendorName = info[5].trim();
    } else {
      this.errors.push('vendorName');
    }
    // country
    let regexPC;
    if (info[10] && info[10].trim() !== '') {
      this.countryCode = info[10].trim();
      regexPC = regexPostalCode.get(this.countryCode);
    } else {
      this.errors.push('countryCode');
    }
    let i = 9;
    if (regexPC) {
      let findPostal = false;
      let findCity = false;
      while (i > 6 && (!findPostal || !findCity)) {
        if (info[i] && info[i].trim() !== '') {
          if (!findPostal && info[i].trim().match(regexPC)) {
            this.postalCode = info[i].trim().match(regexPC)[0];
            findPostal = true;
            if (info[i].trim().replace(this.postalCode, '') !== '') {
              this.city = info[i].trim().replace(this.postalCode, '').trim();
              findCity = true;
            }
          } else if (!findCity) {
            this.city = info[i].trim();
            findCity = true;
          }
        }
        i--;
      }
    } else {
      this.errors.push('postalCode');
      this.errors.push('city');
    }
    if (!this.postalCode) {
      this.errors.push('postalCode');
    }
    if (!this.city) {
      this.errors.push('city');
    }
    if (info[6] && info[6].trim() !== '') {
      this.address1 = info[6].trim();
    } else {
      this.address1 = null;
      // this.errors.push('address1');
    }
    // address2
    if (i > 6 && info[7] && info[7].trim() != '') {
      this.address2 = info[7] ? info[7].trim() : null;
    } else {
      this.address2 = null;
    }

    // currency
    if (info[19] && info[19].trim() !== '') {
      this.currency = info[19].trim();
    }
    // amount to pay
    if (info[18] && info[18].trim() !== '') {
      const value = info[18].trim();
      const floatValue = value && value.replace ? parseFloat(value.replace(',', '.')) : 0;
      if (Number.isNaN(floatValue) || floatValue === null) {
        this.amountToPay = 0;
      } else {
        this.amountToPay = floatValue;
      }
    }
    // bvr & vendorRef
    if (this.QRIBAN) {
      if (info[27] && info[27].trim() === 'QRR') {
        if (info[28] && info[28].trim() !== '') {
          this.bvr = info[28].trim();
        }

        if (info[29] && info[29].trim() !== '') {
          this.vendorRef = info[29].trim();
        } else if (info[31] && info[31].trim() !== '') {
          const arrayRef = info[31].trim().split('/');
          if (arrayRef.length > 4 && arrayRef[4] && arrayRef[4].trim() !== '') {
            this.vendorRef = arrayRef[4].trim();
          } else {
            this.vendorRef = info[31];
          }
        } else {
          this.warningvendorRefQr = true;
        }
      } else if (info[27] && info[27].trim() === 'SCOR') {
        this.isSCOR = true;
        if (info[28] && info[28].trim() !== '') {
          this.vendorRef = info[28] ? info[28].trim() : '';
        }
      } else {
        this.isSCOR = false;
        this.warningQRR = true;
      }
    } else if (info[27] && info[27].trim() === 'SCOR') {
      this.isSCOR = true;
      if (info[28] && info[28].trim() !== '') {
        this.vendorRef = info[28] ? info[28].trim() : '';
      }
    } else {
      this.isSCOR = false;
      if (info[28] && info[28].trim() !== '') {
        this.bvr = info[28].trim();
      }
      if (info[31] && info[31].trim() !== '') {
        this.vendorRef = info[31] ? info[31].trim() : '';
      }
    }
  }

  getCompleteAddress() {
    //  completeAddress
    return `${this.address1 ? this.address1 : ''}${this.address2 ? ', ' + this.address2 : ''}, ${
      this.postalCode ? this.postalCode : ''
    } ${this.city ? this.city : ''}${this.countryName ? ', ' + this.countryName : ''}`;
  }
}
//#region DATAGRID REQUEST
export function getPartnerRequest(qrInfo: InputInfo, ad_language: string) {
  return {
    windowId: -1,
    parent_constraint: undefined,
    compiereRequest: {
      windowType: CompiereDataGridType.TABLE,
      tableName: 'C_BPartner',
      ad_language,
      filterModel: {
        Name: {
          filterType: CompiereDataGridFilterType.TEXT,
          operators: [OperatorFilterType.CONTAINS],
          values: [qrInfo.vendorName]
        },
        IsVendor: {
          filterType: CompiereDataGridFilterType.TEXT,
          operators: [OperatorFilterType.EQUALS],
          values: ['Y']
        },
        IsActive: {
          filterType: CompiereDataGridFilterType.TEXT,
          operators: [OperatorFilterType.EQUALS],
          values: ['Y']
        }
      }
    }
  };
}
export function getBankRequest(qrInfo: InputInfo, ad_language: string) {
  const accountIdToSearch = qrInfo.bankAccountId ? qrInfo.bankAccountId.replace(/^0+/, '') : null;

  let bankSelector = "(Select * from C_BP_BankAccount bk WHERE IsActive='Y' and ";
  if (qrInfo.infoSource === InputInfoSource.QR) {
    if (qrInfo.QRIBAN) {
      bankSelector += "LOWER(QR_IBAN) like LOWER('%" + qrInfo.QRIBAN + "%') ";
    } else {
      bankSelector += "LOWER(IBAN) like LOWER('%" + qrInfo.IBAN + "%') ";
    }
  } else {
    bankSelector += "AccountNo in ('" + qrInfo.bankAccountId + "','" + accountIdToSearch + "') ";
  }
  bankSelector +=
    " and 1=(Select 1 from C_BPartner where C_BPartner_ID=bk.C_BPartner_ID and IsVendor='Y' and IsActive='Y') ) bankRequest";

  return {
    windowId: null,
    parent_constraint: '',
    compiereRequest: {
      tableName: bankSelector,
      filterModel: {}
    }
  };
}
export function getCurrencyRequest(qrInfo: InputInfo, ad_language: string) {
  return {
    windowId: -1,
    parent_constraint: undefined,
    compiereRequest: {
      windowType: CompiereDataGridType.TABLE,
      tableName: 'C_Currency',
      ad_language,
      filterModel: {
        ISO_Code: {
          filterType: CompiereDataGridFilterType.SET,
          operators: [OperatorFilterType.EQUALS],
          values: [qrInfo.currency]
        }
      }
    }
  };
}
export function getCountryDataRequest(qrInfo: InputInfo, ad_language: string) {
  const countrySelector =
    "(SELECT country.C_COUNTRY_ID,countryTrl.NAME FROM C_COUNTRY_trl countryTrl INNER JOIN C_COUNTRY country ON countryTrl.C_Country_ID=country.C_Country_ID WHERE LOWER(countryTrl.AD_LANGUAGE) = LOWER('" +
    ad_language +
    "') AND LOWER(country.countrycode) = LOWER('" +
    qrInfo.countryCode +
    "')) CountryRequest";
  return {
    windowId: null,
    parent_constraint: '',
    compiereRequest: {
      startRow: 0,
      endRow: 1,
      tableName: countrySelector,
      filterModel: {}
    }
  };
}
export function getCountryDataRequestByIBAN(codePays: string, ad_language: string) {
  const countrySelector =
    "(SELECT country.C_COUNTRY_ID,countryTrl.NAME FROM C_COUNTRY_trl countryTrl INNER JOIN C_COUNTRY country ON countryTrl.C_Country_ID=country.C_Country_ID WHERE LOWER(countryTrl.AD_LANGUAGE) = LOWER('" +
    ad_language +
    "') AND LOWER(country.countrycode) = LOWER('" +
    codePays +
    "')) CountryRequest";
  return {
    windowId: null,
    parent_constraint: '',
    compiereRequest: {
      startRow: 0,
      endRow: 1,
      tableName: countrySelector,
      filterModel: {}
    }
  };
}
export function getTabDataRequest() {
  return {
    windowId: null,
    parent_constraint: '',
    compiereRequest: {
      startRow: 0,
      endRow: -1,
      tableName: `( select tv.tableName,tv.ad_tab_id,tv.ad_table_id,tv.ad_window_id,tv.taggedcolumns,tv.docfilters,w.value FROM ad_tab_v tv INNER JOIN ad_window w on tv.ad_window_id=w.ad_window_id WHERE (tv.tablename='C_Invoice' AND w.value='Invoice (Vendor)_183') OR (tv.tablename='C_InvoiceLine' AND w.value='Invoice (Vendor)_183') OR (tv.tablename='C_InvoiceTax' AND w.value='Invoice (Vendor)_183')) TabRequest`,
      filterModel: {}
    }
  };
}
export function getInvoiceTaxRequest(c_invoice_id: number, ad_language: string) {
  return {
    windowId: -1,
    parent_constraint: undefined,
    compiereRequest: {
      windowType: CompiereDataGridType.TABLE,
      tableName: 'C_InvoiceTax',
      ad_language,
      filterModel: {
        C_Invoice_ID: {
          filterType: CompiereDataGridFilterType.SET,
          operators: [OperatorFilterType.EQUALS],
          values: [c_invoice_id]
        }
      }
    }
  };
}
export function getInvoiceRequest(c_invoice_id: number, ad_language: string) {
  return {
    windowId: -1,
    parent_constraint: undefined,
    compiereRequest: {
      windowType: CompiereDataGridType.TABLE,
      tableName: 'C_Invoice',
      ad_language,
      filterModel: {
        C_Invoice_ID: {
          filterType: CompiereDataGridFilterType.SET,
          operators: [OperatorFilterType.EQUALS],
          values: [c_invoice_id]
        }
      }
    }
  };
}
export function getInvoiceLineRequest(c_invoice_id: number, ad_language: string) {
  return {
    windowId: -1,
    parent_constraint: undefined,
    compiereRequest: {
      windowType: CompiereDataGridType.TABLE,
      tableName: 'C_InvoiceLine',
      ad_language,
      filterModel: {
        C_Invoice_ID: {
          filterType: CompiereDataGridFilterType.SET,
          operators: [OperatorFilterType.EQUALS],
          values: [c_invoice_id]
        }
      }
    }
  };
}
export function getLogMessageRequest(ad_language: string) {
  return {
    windowId: -1,
    parent_constraint: undefined,
    compiereRequest: {
      windowType: CompiereDataGridType.TABLE,
      tableName: 'AD_MESSAGE',
      ad_language,
      filterModel: {
        value: {
          filterType: CompiereDataGridFilterType.SET,
          operators: [OperatorFilterType.CONTAINS],
          values: ['SZ_VendorInvoice']
        }
      }
    }
  };
}
export function getOrderRequest(qrInfo: InputInfo, data: any, ad_language: string, marginError: number) {
  // set partner id
  const C_BPartner_ID = data['C_BPartner_ID']
    ? data['C_BPartner_ID'].id
      ? data['C_BPartner_ID'].id
      : data['C_BPartner_ID']
    : -1;
  // set C_BPartner_ID
  const IsReturnTrx = data['IsReturnTrx'] ? (data['IsReturnTrx'].id ? data['IsReturnTrx'].id : data['IsReturnTrx']) : -1;
  return {
    windowId: -1,
    parent_constraint: undefined,
    compiereRequest: {
      windowType: CompiereDataGridType.TABLE,
      tableName: 'C_Order',
      ad_language,
      filterModel: {
        GrandTotal: {
          filterType: CompiereDataGridFilterType.SET,
          operators: [OperatorFilterType.BETWEEN, OperatorFilterType.BETWEEN],
          values: [qrInfo.amountToPay - marginError, qrInfo.amountToPay + marginError]
        }
      },
      validation: `C_Order.c_bpartner_id = ${C_BPartner_ID} AND C_Order.issotrx = 'N'  AND C_Order.isreturntrx = '${IsReturnTrx}' AND C_Order.c_order_id IN ( SELECT ol.c_order_id FROM c_orderline ol INNER JOIN c_order oo ON oo.c_order_id = ol.c_order_id LEFT OUTER JOIN m_matchpo m ON ( ol.c_orderline_id=m.c_orderline_id) where m.m_inoutline_id IS NOT NULL AND ( ol.isdropship='N' OR '${IsReturnTrx}'= 'N') AND oo.c_bpartner_id = ${C_BPartner_ID} GROUP BY ol.c_order_id, ol.c_orderline_id, ol.qtyordered HAVING ol.qtyordered > SUM(m.qty) UNION SELECT ol.C_Order_ID FROM C_OrderLine ol INNER JOIN C_Order oo ON oo.C_Order_ID = ol.C_Order_ID LEFT OUTER JOIN M_MatchPO m ON (ol.C_OrderLine_ID=m.C_OrderLine_ID) where m.m_inoutline_id IS NULL AND ( ol.isdropship='N' OR '${IsReturnTrx}'= 'N') AND oo.c_bpartner_id = ${C_BPartner_ID} GROUP BY ol.C_Order_ID, ol.C_OrderLine_ID, ol.QtyOrdered) AND C_Order.docstatus IN ( 'CL', 'CO' ) AND not exists (select 1 from C_BlanketOrder bo where bo.C_BlanketOrder_ID = C_Order.C_BlanketOrder_ID AND bo.IsConsigned ='Y' )`
    }
  };
}
export function getChargeDescRequest(c_charge_id: number) {
  return {
    windowId: null,
    parent_constraint: null,
    compiereRequest: {
      startRow: 0,
      tableName: 'C_Charge',
      filterModel: {
        C_Charge_ID: {
          filterType: CompiereDataGridFilterType.SET,
          values: [c_charge_id],
          operators: [OperatorFilterType.EQUALS]
        }
      }
    }
  };
}
export function getProductDescRequest(m_product_id: number) {
  return {
    windowId: null,
    parent_constraint: null,
    compiereRequest: {
      startRow: 0,
      tableName: 'M_Product',
      filterModel: {
        M_Product_ID: {
          filterType: CompiereDataGridFilterType.SET,
          values: [m_product_id],
          operators: [OperatorFilterType.EQUALS]
        }
      }
    }
  };
}

export function getUserRoleRequest(ad_user_id: number, ad_client_id: number, ad_language: string) {
  return {
    windowId: -1,
    parent_constraint: undefined,
    compiereRequest: {
      windowType: CompiereDataGridType.TABLE,
      tableName: 'AD_User_Roles',
      ad_language,
      filterModel: {
        AD_Client_ID: {
          filterType: CompiereDataGridFilterType.SET,
          values: [ad_client_id],
          operators: [OperatorFilterType.EQUALS]
        },
        AD_User_ID: {
          filterType: CompiereDataGridFilterType.SET,
          values: [ad_user_id],
          operators: [OperatorFilterType.EQUALS]
        }
      }
    }
  };
}
export function getPartnerLocationRequest(qrInfo: InputInfo) {
  let connecteur = 'WHERE ';
  let whereClause = '';
  if (qrInfo.address1 && qrInfo.address1.length > 0) {
    whereClause += connecteur + "cl.address1 LIKE '%" + qrInfo.address1.replace("'", "''") + "%' ";
    connecteur = 'AND ';
  }
  if (qrInfo.postalCode && qrInfo.postalCode.length > 0) {
    whereClause += connecteur + "cl.postal LIKE '%" + qrInfo.postalCode + "%' ";
    connecteur = 'AND ';
  }
  if (qrInfo.city && qrInfo.city.length > 0) {
    whereClause +=
      connecteur +
      "( EXISTS(SELECT C_CITY_ID FROM C_CITY city WHERE LOWER(city.name) = LOWER('" +
      qrInfo.city.replace("'", "''") +
      "') AND cl.C_CITY_ID=city.C_CITY_ID ) OR LOWER(cl.city)=LOWER('" +
      qrInfo.city.replace("'", "''") +
      "') )";
    connecteur = 'AND ';
  }
  if (qrInfo.countryCode && qrInfo.countryCode.length > 0) {
    whereClause +=
      connecteur +
      "EXISTS(SELECT 1 FROM C_COUNTRY country WHERE LOWER(country.countrycode) = LOWER('" +
      qrInfo.countryCode +
      "') ) ";
    connecteur = 'AND ';
  }
  whereClause += connecteur + " 1=(Select 1 from C_BPartner where C_BPartner_ID=cbl.C_BPartner_ID and IsVendor='Y') ";
  return {
    windowId: null,
    parent_constraint: '',
    compiereRequest: {
      startRow: 0,
      endRow: 1,
      tableName:
        '( SELECT cbl.C_BPartner_Location_ID FROM C_BPartner_Location cbl INNER JOIN C_Location cl on cl.C_Location_ID = cbl.C_Location_ID ' +
        whereClause +
        ' ) PartnerLocationRequest',
      filterModel: {}
    }
  };
}

export function getInvoiceZPayComRequest(c_bpartner_id: number, pay_com: string, c_invoice_id: number) {
  return {
    windowId: -1,
    parent_constraint: undefined,
    compiereRequest: {
      windowType: CompiereDataGridType.TABLE,
      tableName: 'C_Invoice',
      filterModel: {
        C_BPartner_ID: {
          filterType: CompiereDataGridFilterType.SET,
          operators: [OperatorFilterType.EQUALS],
          values: [c_bpartner_id]
        },
        Z_PaymentCommunication: {
          filterType: CompiereDataGridFilterType.SET,
          operators: [OperatorFilterType.EQUALS],
          values: [pay_com]
        },
        C_Invoice_ID: {
          filterType: CompiereDataGridFilterType.SET,
          operators: [OperatorFilterType.NOT_EQUALS],
          values: [c_invoice_id ? c_invoice_id : 0]
        }
      }
    }
  };
}
//#endregion DATAGRID REQUEST

export function getNullValueInData(data: any, columnNames: string[]) {
  let nullValue = null;
  columnNames.forEach((columnName) => {
    const value = data[columnName];
    if (
      value == null ||
      value == undefined ||
      value == '' ||
      (value.trim && value.trim() == '') ||
      (columnName === 'AD_Org_ID' && value.id <= 0)
    ) {
      nullValue = columnName;
      return;
    }
  });
  return nullValue;
}
export function isIBANValid(value: string): boolean {
  return IBAN.isValid(value);
}
export function getLineCustomDesignItems() {
  return [
    {
      vcr: 'vcrsCharge',
      type: CustomDesignItemType.FIELD,
      columnName: 'C_Charge_ID',
      cssClass: 'group-input',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrsProduct',
      type: CustomDesignItemType.FIELD,
      columnName: 'M_Product_ID',
      cssClass: 'group-input',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrsQty',
      type: CustomDesignItemType.FIELD,
      columnName: 'QtyEntered',
      cssClass: 'group-input',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrsPriceEntered',
      type: CustomDesignItemType.FIELD,
      columnName: 'PriceEntered',
      cssClass: 'group-input',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrsPriceList',
      type: CustomDesignItemType.FIELD,
      columnName: 'PriceList',
      cssClass: 'group-input',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrsTax',
      type: CustomDesignItemType.FIELD,
      columnName: 'C_Tax_ID',
      cssClass: 'group-input',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrsTaxAmt',
      type: CustomDesignItemType.FIELD,
      columnName: 'TaxAmt',
      cssClass: 'group-input',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrsLineNetAmt',
      type: CustomDesignItemType.FIELD,
      columnName: 'LineNetAmt',
      cssClass: 'group-input',
      isLabelDisplay: false
    },
    // hidden fields for callout
    {
      vcr: 'vcrsOthers',
      type: CustomDesignItemType.FIELD,
      columnName: 'IsSOTrx',
      cssClass: 'group-input'
    },
    {
      vcr: 'vcrsOthers',
      type: CustomDesignItemType.FIELD,
      columnName: 'PriceActual',
      cssClass: 'group-input',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrsOthers',
      type: CustomDesignItemType.FIELD,
      columnName: 'Description',
      cssClass: 'group-input',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrsOthers',
      type: CustomDesignItemType.FIELD,
      columnName: 'QtyInvoiced',
      cssClass: 'group-input',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrsOthers',
      type: CustomDesignItemType.FIELD,
      columnName: 'PriceLimit',
      cssClass: 'group-input',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrsOthers',
      type: CustomDesignItemType.FIELD,
      columnName: 'EnforcePriceLimit',
      cssClass: 'group-input',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrsOthers',
      type: CustomDesignItemType.FIELD,
      columnName: 'TVAEncaissement',
      cssClass: 'group-input',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrsOthers',
      type: CustomDesignItemType.FIELD,
      columnName: 'C_UOM_ID',
      cssClass: 'group-input',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrsOthers',
      type: CustomDesignItemType.FIELD,
      columnName: 'LineTotalAmt',
      cssClass: 'group-input',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrsOthers',
      type: CustomDesignItemType.FIELD,
      columnName: 'C_InvoiceLine_ID',
      cssClass: 'group-input',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrsOthers',
      type: CustomDesignItemType.FIELD,
      columnName: 'IsDropShip',
      cssClass: 'group-input',
      isLabelDisplay: false
    }
  ];
}
export function getHeaderCustomDesignItems() {
  return [
    {
      vcr: 'vcrCreateFrom',
      type: CustomDesignItemType.FIELD,
      columnName: 'CreateFrom',
      cssClass: 'createFrom',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrBPartner',
      type: CustomDesignItemType.FIELD,
      columnName: 'C_BPartner_ID',
      cssClass: 'group-input',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrBPBankAccount',
      type: CustomDesignItemType.FIELD,
      columnName: 'C_BP_BankAccount_ID',
      cssClass: 'group-input',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrValidator',
      type: CustomDesignItemType.FIELD,
      columnName: 'XX_Validator_ID',
      cssClass: 'group-input',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrBVR',
      type: CustomDesignItemType.FIELD,
      columnName: 'XX_BVR',
      cssClass: 'group-input',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrPriceListHeader',
      type: CustomDesignItemType.FIELD,
      columnName: 'M_PriceList_ID',
      cssClass: 'group-input',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrPaymentTerm',
      type: CustomDesignItemType.FIELD,
      columnName: 'C_PaymentTerm_ID',
      cssClass: 'group-input',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrBPartnerLocation',
      type: CustomDesignItemType.FIELD,
      columnName: 'C_BPartner_Location_ID',
      cssClass: 'group-input',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrOrder',
      type: CustomDesignItemType.FIELD,
      columnName: 'C_Order_ID',
      cssClass: 'group-input',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrZ_PaymentCommunication',
      type: CustomDesignItemType.FIELD,
      columnName: 'Z_PaymentCommunication',
      cssClass: 'group-input',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrDateInvoiced',
      type: CustomDesignItemType.FIELD,
      columnName: 'DateInvoiced',
      cssClass: 'group-input',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrGrandTotal',
      type: CustomDesignItemType.FIELD,
      columnName: 'GrandTotal',
      cssClass: 'group-input',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrSettings',
      type: CustomDesignItemType.FIELD,
      columnName: 'AD_Client_ID',
      cssClass: 'group-input'
    },
    {
      vcr: 'vcrSettings',
      type: CustomDesignItemType.FIELD,
      columnName: 'IsSOTrx',
      cssClass: 'group-input'
    },
    {
      vcr: 'vcrSettings',
      type: CustomDesignItemType.FIELD,
      columnName: 'C_Invoice_ID',
      cssClass: 'group-input'
    },
    {
      vcr: 'vcrSettings',
      type: CustomDesignItemType.FIELD,
      columnName: 'AD_Org_ID',
      cssClass: 'group-input'
    },
    {
      vcr: 'vcrSettings',
      type: CustomDesignItemType.FIELD,
      columnName: 'DocumentNo',
      cssClass: 'group-input'
    },
    {
      vcr: 'vcrSettings',
      type: CustomDesignItemType.FIELD,
      columnName: 'DateAcct',
      cssClass: 'group-input'
    },
    {
      vcr: 'vcrSettings',
      type: CustomDesignItemType.FIELD,
      columnName: 'DateOrdered',
      cssClass: 'group-input'
    },
    {
      vcr: 'vcrSettings',
      type: CustomDesignItemType.FIELD,
      columnName: 'C_DocTypeTarget_ID',
      cssClass: 'group-input'
    },
    {
      vcr: 'vcrSettings',
      type: CustomDesignItemType.FIELD,
      columnName: 'C_DocType_ID',
      cssClass: 'group-input'
    },
    {
      vcr: 'vcrSettings',
      type: CustomDesignItemType.FIELD,
      columnName: 'DocAction',
      cssClass: 'group-input'
    },
    {
      vcr: 'vcrSettings',
      type: CustomDesignItemType.FIELD,
      columnName: 'AD_User_ID',
      cssClass: 'group-input'
    },
    {
      vcr: 'vcrSettings',
      type: CustomDesignItemType.FIELD,
      columnName: 'SalesRep_ID',
      cssClass: 'group-input'
    },
    {
      vcr: 'vcrSettings',
      type: CustomDesignItemType.FIELD,
      columnName: 'IsReturnTrx',
      cssClass: 'group-input'
    },
    {
      vcr: 'vcrSettings',
      type: CustomDesignItemType.FIELD,
      columnName: 'DocStatus',
      cssClass: 'group-input'
    },
    {
      vcr: 'vcrSettings',
      type: CustomDesignItemType.FIELD,
      columnName: 'C_Currency_ID',
      cssClass: 'group-input'
    },
    {
      vcr: 'vcrSettings',
      type: CustomDesignItemType.FIELD,
      columnName: 'PaymentRule',
      cssClass: 'group-input'
    },
    {
      vcr: 'vcrProcessInvoice',
      type: CustomDesignItemType.FIELD,
      columnName: 'Processing',
      cssClass: 'group-input'
    }
  ];
}
