import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfig } from '@iupics-config/app.config';
import { CacheManagerService } from '@iupics-manager/managers/cache-manager/cache-manager.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { AbstractDynamicComponent } from '@iupics-manager/models/abstract-dynamic-component';
import { IupicsEvent } from '@iupics-manager/models/iupics-event';
import { Observable } from 'rxjs';
import { SpecificWindowUiComponent } from '../specific-window-ui/specific-window-ui.component';

@Component({
  selector: 'iu-loginas-ui',
  templateUrl: './loginas-ui.component.html',
  styleUrls: ['./loginas-ui.component.scss']
})
export class LoginasUiComponent extends AbstractDynamicComponent implements OnInit {
  usersToBeSelected$: Observable<any>;
  @ViewChild('vcrSpecific', { read: ViewContainerRef, static: true })
  vcrSpecific: ViewContainerRef;

  constructor(
    private connectorService: SecurityManagerService,
    private uiCreatorService: UICreatorService,
    private router: Router,
    private config: AppConfig
  ) {
    super();
  }

  ngOnInit() {
    this.createSpecificWindow(this.config.getConstant('LoginAsFormComponent#FormID'));
  }

  createSpecificWindow(formId: number, sourceComponent?: any, vcr = this.vcrSpecific, closeModal?: Function) {
    this.subscriptions.push(
      this.uiCreatorService.getSpecificWindow(formId).subscribe((specificWindow) => {
        const component = CacheManagerService.iupics_specific_window.get(specificWindow.angularClass);
        this.vcrSpecific.clear();
        const componentRef = this.vcrSpecific.createComponent(component);
        (<SpecificWindowUiComponent>componentRef.instance).name = specificWindow.name;
        (<SpecificWindowUiComponent>componentRef.instance).title = specificWindow.title;
        (<SpecificWindowUiComponent>componentRef.instance).description = specificWindow.description;
        (<SpecificWindowUiComponent>componentRef.instance).help = specificWindow.help;
        (<SpecificWindowUiComponent>componentRef.instance).componentRef = componentRef;
        (<SpecificWindowUiComponent>componentRef.instance).isModal = true;
        (<SpecificWindowUiComponent>componentRef.instance).formId = formId;
        (<SpecificWindowUiComponent>componentRef.instance).vcrwindow = vcr;
        (<SpecificWindowUiComponent>componentRef.instance).parentComponent = this;
        (<SpecificWindowUiComponent>componentRef.instance).index = vcr.length - 1;
        (<SpecificWindowUiComponent>componentRef.instance).sourceComponentData =
          sourceComponent && sourceComponent.itemData ? sourceComponent.itemData : sourceComponent;
        (<SpecificWindowUiComponent>componentRef.instance).sourceComponent = sourceComponent;
        (<SpecificWindowUiComponent>componentRef.instance).closeModalEmitter.subscribe(() => {});
      })
    );
  }

  onChildUpdate(event: IupicsEvent) {}
  onSiblingUpdate(event: IupicsEvent) {}
  onRemoveComponent(event: IupicsEvent) {}
}
