<iu-workspace-ui
  (contextmenu)="preventContextMenu($event)"
  (press)="preventContextMenu($any($event))"
  (validateLogoutEmitter)="validateLogoutEmitter.emit()"
  [urlParams]="urlParams"
  iuKeybinding
  [iuKeybinding-active]="true"
  [iuKeybinding-badge-function]="showKeyBindLegend"
></iu-workspace-ui>

<iu-prime-context-menu
  [baseZIndex]="10000"
  [global]="false"
  [model]="menuItems"
  (contextmenu)="preventContextMenu($event)"
  (press)="preventContextMenu($any($event))"
>
</iu-prime-context-menu>
<p-toast>
  <ng-template let-message pTemplate="message">
    <iu-notification
      *ngIf="message.data && message.data.type === 'Notification'"
      [isStandalone]="true"
      [item]="message.data.notification"
    ></iu-notification>
  </ng-template>
</p-toast>
<iu-info-dialog></iu-info-dialog>
<iu-modal-ui
  *ngIf="isLoginModalDisplayed()"
  [title]="'loginAs.title'"
  [hasCloseBtn]="false"
  (closeModalEmitter)="hideLoginModal()"
  [angularStyle]="{ 'iu-modal': { overflow: 'visible' } }"
>
  <iu-loginas-ui></iu-loginas-ui>
</iu-modal-ui>

<p-toast key="quick-toast" position="bottom-center" styleClass="quick-toast"></p-toast>
