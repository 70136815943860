import { Component, Input, OnInit } from '@angular/core';
import { AvatarInfos } from '@iupics-manager/models/user-account';

@Component({
  selector: 'iu-tag-list-ui',
  templateUrl: './tag-list-item-ui.component.html',
  styleUrls: ['./tag-list-item-ui.component.scss']
})
export class TagListItemUiComponent implements OnInit {
  @Input() value: string;
  @Input() pictureUrl: string;
  @Input() userId: number;
  @Input() noMatch: boolean;

  avatarInfos: AvatarInfos;

  constructor() {}

  ngOnInit(): void {
    if (!this.noMatch) {
      this.setAvatarInfos();
    }
  }

  setAvatarInfos() {
    if (this.userId && this.value) {
      this.avatarInfos = {
        id: this.userId,
        fullname: this.value,
        pictureURL: this.pictureUrl
      };
    }
  }
}
