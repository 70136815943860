<!-- TODO remove after 4.1.0 std -->
<div *ngIf="opened && currentUserAccount" #userProfile class="profileView">
  <!-- <i
    class="fa fa-2x fa-cog user-profile-gear-icon"
    pTooltip="{{ 'profil.update' | translate }}"
    tooltipPosition="right"
    (click)="openUpdateProfil($event)"
    data-cy="modify-profile"
    aria-hidden="true"
  ></i> -->
  <button
    pButton
    class="p-button p-button-default p-button-secondary p-button-icon-only user-profile-gear-icon no-border no-background"
    pTooltip="{{ 'profil.update' | translate }}"
    tooltipPosition="right"
    (click)="openUpdateProfil($event)"
    data-cy="modify-profile"
    icon="fa fa-2x fa-cog"
  ></button>
  <div data-cy="profile-panel" class="p-col-row profileContainer">
    <div class="profil-header">
      <div class="ui-row account">
        <div style="width: 100%; text-align: left">
          <span
            (mouseover)="setTokenExpirationInterval($event)"
            class="account-name"
            [life]="3000"
            [hideDelay]="250"    
            pTooltip="{{ 'profil.sessionExpiration' | translate }} {{ tokenExpiration }}"
            tooltipPosition="right"
          >
            {{ currentUserAccount.fullname }}
          </span>
          <span class="account-role" data-cy="role-selected">{{ selectedRole.roleUI.name }} </span>
          <span class="account-email">{{ currentUserAccount.email }} ({{ currentUserAccount.login }}) </span>
        </div>
      </div>
    </div>

    <div *ngIf="isUpdateProfil" style="width: 100%; text-align: center"><span class="separator"> </span></div>

    <ng-container
      *ngTemplateOutlet="
        dropdownList;
        context: {
          $implicit: 'role',
          isDisplay: roleIsDisplay,
          selected: selectedRole,
          list: roles$ | async,
          searchKey: 'searchRole',
          updateFn: 'changeRole'
        }
      "
    ></ng-container>

    <ng-container *ngIf="currentUserAccount.current_role.role_id > 0">
      <ng-container
        *ngTemplateOutlet="
          dropdownList;
          context: {
            $implicit: 'organisation',
            isDisplay: organisationIsDisplay,
            selected: selectedOrganisation,
            list: organisations$ | async,
            searchKey: 'searchOrganisation',
            updateFn: 'updateOrganisation'
          }
        "
      ></ng-container>
    </ng-container>

    <ng-container *ngIf="currentUserAccount.current_role.role_id > 0">
      <ng-container
        *ngTemplateOutlet="
          dropdownList;
          context: {
            $implicit: 'warehouse',
            isDisplay: warehouseIsDisplay,
            selected: selectedWarehouse,
            list: warehouses$ | async,
            searchKey: 'searchWarehouse',
            updateFn: 'updateWarehouse'
          }
        "
      ></ng-container>
    </ng-container>

    <div class="ui-row clear apiz-p-grid actions">
      <button
        pButton
        class="p-col-12 p-button p-button-secondary"
        (click)="showUserContext($event)"
        data-cy="user-context-btn"
        [label]="'profil.showUserContext' | translate"
      ></button>
      <button
        pButton
        class="p-col-12 p-button p-button-secondary"
        [label]="'profil.keycloakManagementBtn' | translate"
        (click)="goToKeycloakManageProfile()"
      ></button>
      <button
        pButton
        *ngIf="!isUpdateProfil"
        class="p-col-12 p-button p-button-alert user-profile-logoff-btn"
        (click)="logout($event)"
        data-cy="logout-btn"
        [label]="'profil.logoff' | translate"
      ></button>
      <div class="split-button" *ngIf="selectedRole.roleUI.role_id === 0 && !isUpdateProfil">
        <div class="split-button-toggle-button" [ngClass]="{ 'split-button-open': isSplitButtonOpen }">
          <button
            pButton
            data-cy="sys-admin-reload-cache-server"
            class="p-col-10 p-button p-button-warning"
            (click)="reloadConfiguration($event, false)"
            [label]="'profil.reload-server' | translate"
          ></button>
          <button
            pButton
            data-cy="sys-admin-split-button-open"
            class="p-col-2 p-button p-button-warning"
            (click)="isSplitButtonOpen = !isSplitButtonOpen"
            [icon]="isSplitButtonOpen ? 'icon-open' : 'icon-next'"
          ></button>
        </div>
        <div *ngIf="isSplitButtonOpen" class="split-button-list">
          <button
            pButton
            data-cy="sys-admin-reload-cache-client"
            class="p-col-12 p-button p-button-warning"
            (click)="reloadConfiguration($event, true)"
            [label]="'profil.reload-client' | translate"
          ></button>
        </div>
      </div>
      <iu-app-version-panel></iu-app-version-panel>
    </div>
  </div>
</div>
<p-sidebar
  [(visible)]="isUpdateProfil"
  appendTo="body"
  position="right"
  (contextmenu)="$event.preventDefault()"
  [styleClass]="(isMobile || isMobileWidth ? 'iu-fullscreen-sidebar' : 'p-sidebar-md') + ' user-profile-sidebar'"
>
  <div *ngIf="isUpdateProfil" class="user-profile-edition-zone" data-cy="update-panel">
    <div class="user-profile-edition-zone-content">
      <iu-prime-fieldset [toggleable]="false" [collapsed]="false" [legend]="'profil.my-profile' | translate">
        <div class="user-profile-fieldset">
          <div class="apiz-p-grid">
            <iu-autocomplete-ui
              #languagesSelector
              data-cy="language-selector"
              [label]="'profil.language' | translate"
              [isStandalone]="true"
              columnName="Profile-AD_Language"
              [data]="languages"
              cssGrid="p-col-12"
              cssClass="p-col-12"
            ></iu-autocomplete-ui>
          </div>
          <div class="apiz-p-grid">
            <iu-autocomplete-ui
              #printerNameField
              [label]="'profil.printerName' | translate"
              [isStandalone]="true"
              columnName="Profile-printerName"
              [fieldValue]="this.printerName"
              [data]="printerNames"
              cssGrid="p-col-12"
              cssClass="p-col-12"
            ></iu-autocomplete-ui>
          </div>
        </div>
      </iu-prime-fieldset>
      <iu-prime-fieldset [toggleable]="false" [collapsed]="false" [legend]="'profil.my-settings' | translate">
        <div class="apiz-p-grid">
          <iu-calendar-ui
            class="defaultDateCalendar p-col-12"
            label="{{ 'profil.date' | translate }}"
            [fieldValue]="currentDefaultDate"
            [isStandalone]="true"
            [locale]="currentUserAccount.default_language.iso_code"
            cssGrid="p-col-12"
            (fieldValueModified)="changeDefaultDate($any($event))"
          ></iu-calendar-ui>
          <iu-input-switch-ui
            #autoCommitField
            label="{{ 'profil.autoCommitPref' | translate }}"
            [fieldValue]="this.isAutoCommit"
            cssGrid="p-col-12"
            style="text-align: left"
          >
          </iu-input-switch-ui>
          <iu-input-switch-ui
            #advancedShowField
            label="{{ 'profil.oneuserpref' | translate }}"
            [fieldValue]="this.isShowAdvanced"
            cssGrid="p-col-12"
            style="text-align: left"
          >
          </iu-input-switch-ui>
          <iu-input-switch-ui
            #acctShowField
            label="{{ 'profil.twouserpref' | translate }}"
            [fieldValue]="this.isShowAcct"
            cssGrid="p-col-12"
            style="text-align: left"
            columnName="Profile-AcctNote"
          >
          </iu-input-switch-ui>
          <iu-input-switch-ui
            #trlShowField
            label="{{ 'profil.threeuserpref' | translate }}"
            [fieldValue]="this.isShowTrl"
            cssGrid="p-col-12"
            style="text-align: left"
          >
          </iu-input-switch-ui>
          <iu-input-switch-ui
            #previewField
            label="{{ 'profil.fouruserpref' | translate }}"
            [fieldValue]="this.isPrintPreview"
            cssGrid="p-col-12"
            style="text-align: left"
          ></iu-input-switch-ui>
          <iu-autocomplete-ui
            #notificationReminderTimeoutField
            data-cy="autocomplete-notifTimeout"
            label="{{ 'profil.fiveuserpref' | translate }}"
            [isStandalone]="true"
            columnName="Profile-NotificationTimeout"
            [data]="NotificationDelay"
            [fieldValue]="this.notificationReminderTimeout"
            cssGrid="p-col-12"
            cssClass="p-col-12"
          ></iu-autocomplete-ui>
        </div>
      </iu-prime-fieldset>
      <iu-prime-fieldset [toggleable]="false" [collapsed]="false" [legend]="'profil.appearence' | translate">
        <div class="apiz-p-grid">
          <iu-autocomplete-ui
            #themeSelector
            data-cy="autocomplete-themes"
            label="{{ 'profil.theme' | translate }}"
            [isStandalone]="true"
            columnName="Profile-UIThemes"
            [data]="UIThemes"
            [fieldValue]="this.currentUITheme"
            (fieldValueModified)="changeTheme($event)"
            cssGrid="p-col-12"
            cssClass="p-col-12"
          ></iu-autocomplete-ui>
          <!-- {AD_Role_ID: 100, AD_User_ID:100} -->
        </div>
      </iu-prime-fieldset>
      <div class="user-profile-edition-zone-buttons">
        <button
          class="p-button p-button-secondary"
          data-cy="cancel-update-profile"
          (click)="isUpdateProfil = false; $event.stopPropagation()"
        >
          {{ 'generic.cancel' | translate }}
        </button>
        <button class="p-button p-button-primary" data-cy="update-profile" (click)="validateProfile(); $event.stopPropagation()">
          {{ 'profil.valid' | translate }}
        </button>
        <button class="p-button p-button-alert" (click)="resetDashboard($event)">
          {{ 'profil.reset-dashboard' | translate }}
        </button>
      </div>
    </div>
  </div>
</p-sidebar>

<ng-template
  #dropdownList
  let-key
  let-isDisplay="isDisplay"
  let-selected="selected"
  let-list="list"
  let-searchKey="searchKey"
  let-updateFn="updateFn"
>
  <ul class="user-profile-dropdown">
    <li #organisationList [ngClass]="['user-profile-dropdown-content', isDisplay ? 'opened' : 'closed']">
      <a class="user-profile-dropdown-header" [attr.data-cy]="'display-' + key" (click)="toggleDropdown(key)">
        <span>{{ 'profil.' + key | translate }} : {{ selected?.displayValue }}</span>
        <i [ngClass]="[isDisplay ? 'icon-up' : 'icon-open']" aria-hidden="true"></i>
      </a>
      <ul class="user-profile-dropdown-list" [attr.data-cy]="key + '-list'">
        <li *ngIf="list?.length > 10" class="user-profile-dropdown-item user-profile-dropdown-search">
          <iu-input-text-ui
            [isStandalone]="true"
            [isKeyUpTriggerable]="true"
            [placeHolder]="'generic.search' | translate"
            [(fieldValue)]="this[searchKey]"
          ></iu-input-text-ui>
        </li>
        <li
          *ngFor="let item of list | objectArrayFilter : [this[searchKey], 'displayValue']"
          [ngClass]="[item.id === selected?.id ? 'user-profile-dropdown-item-active' : '', 'user-profile-dropdown-item']"
          (click)="this[updateFn](item)"
        >
          <div>
            <span *ngIf="item.id === selected?.id"><i class="icon-next" aria-hidden="true"></i></span>
            <a>{{ item.displayValue }}</a>
          </div>
        </li>
      </ul>
    </li>
  </ul>
</ng-template>
