import { Injectable } from '@angular/core';
import { CompiereDataGridRequestJSON, DataStoreKey } from '@compiere-ws/models/compiere-data-json';
import { CompiereTab, TabCompiereJSON } from '@compiere-ws/models/window-json';
import { CompiereExportDataService } from '@compiere-ws/services/compiere-export-data/compiere-export-data.service';
import { PrintReportService } from '@compiere-ws/services/compiere-print-report/print-report.service';
import { PoService } from '@compiere-ws/services/po/po.service';
import { ProcessParams } from '@iupics-components/specific/window/process-ui/process-ui.component';
import { Global } from '@iupics-manager/models/global-var';
import { IupicsMessage } from '@iupics-manager/models/iupics-message';
import { TranslateService } from '@ngx-translate/core';
import { IupicsMenuType } from '@web-desktop/models/menu-item-ui';
import { cloneDeep, has } from 'lodash';
import * as moment from 'moment';
import { Observable, of, Subscription, zip } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import * as shajs from 'sha.js';
import { MessageManagerService } from '../message/message-manager.service';
import { NotificationManagerService } from '../notification-manager/notification-manager.service';
import { UICreatorService } from '../ui-creator/ui-creator.service';
@Injectable({
  providedIn: 'root'
})
export class PrintReportManager {
  constructor(
    private printReportService: PrintReportService,
    private uiCreator: UICreatorService,
    private messageManager: MessageManagerService,
    private translate: TranslateService,
    private po: PoService,
    private notificationService: NotificationManagerService,
    private exportDatagridService: CompiereExportDataService
  ) {}

  printReport2(dsKey: DataStoreKey): Observable<any> {
    return this.uiCreator.getCompiereTab(dsKey.tabId).pipe(
      switchMap((compiereTab: CompiereTab) => {
        return this.printReportService.printReport({
          m_process_id: compiereTab.tab.AD_Process_ID,
          table_id: compiereTab.tab.AD_Table_ID,
          record_ID: dsKey.recordId.split(',')[1]
        });
      })
    );
  }

  downloadReport(urlDownload: string) {
    const unSubObj = this.printReportService.downloadReport(urlDownload).subscribe((response) => {
      Global.downloadFile(response);
      unSubObj.unsubscribe();
    });
  }

  printReport(dsKey: DataStoreKey, sessionId, ctx: any = {}): Observable<any> {
    return this.uiCreator.getCompiereTab(dsKey.tabId).pipe(
      switchMap((compiereTab: CompiereTab) => {
        const channel_id = shajs('sha256')
          .update(sessionId + '_' + compiereTab.tab.AD_Window_ID + '_' + moment().valueOf())
          .digest('hex');
        const record_id =
          dsKey.recordId.split(',').length > 1
            ? dsKey.recordId.split(',').length > 1
              ? dsKey.recordId.split(',')[1]
              : null
            : null;
        const columnsKeys = [];   
        const recordId = (dsKey.recordId as string).split(',');
        if (recordId.length > 1) {
          let obj = {};
          for (let i = 0; i < recordId.length; i++) {
            obj[recordId[i]] = recordId[i+1];
            i++;
          }
          columnsKeys.push(obj);
        }    
        const paramsMap = this.generateParamsMap(compiereTab.tab, channel_id, [parseInt(record_id, 10)], null);
        // paramsMap.ad_tab_id = 0;
        paramsMap.tableName = compiereTab.tab.TableName;
        paramsMap.record_id = parseInt(record_id, 10);
        paramsMap.windowCtx = cloneDeep(ctx);
        // ajout des infos pour l'ouverture via notif {
        paramsMap.windowCtx['sourceInfo'] = {
          sourceType: IupicsMenuType.WINDOW,
          sourceTypeId: compiereTab.tab.AD_Window_ID,
          sourceRecordId: paramsMap.record_id + '',
          sourceTableNameTargeted: paramsMap.tableName,
          params: paramsMap.params
        };
        if (has(ctx, 'DocumentNo')) {
          paramsMap.windowCtx['sourceInfo']['apizPrintReport_DocumentNo'] = ctx['DocumentNo'];
        }

        if (columnsKeys) {
          paramsMap.params['columnsKeys'] = columnsKeys;
        }
        return this.uiCreator.executeProcess(paramsMap).pipe(
          switchMap((response) => {
            try {
              return of(channel_id);
            } catch (e) {
              return of(null);
            }
          })
        );
      })
    );
  }

  printAndDownloadReport(dsKey: DataStoreKey, sessionId, ctx: any = {}, callBack?: Function): Subscription {
    return this.printReport(dsKey, sessionId, ctx).subscribe((channelId) => {
      if (channelId) {
        this.messageManager.newMessage(
          new IupicsMessage(
            this.translate.instant('exportData.messageTitle'),
            this.translate.instant('exportData.exportDataStarted'),
            'success'
          )
        );
        const notifSub$ = this.notificationService.syncWithNotification(channelId).subscribe((notification) => {
          if (notification.fileLinks) {
            notification.fileLinks.forEach((file) => {
              const downloadSub$ = this.printReportService.downloadReport(file.path).subscribe({
                next: (resp) => {
                  Global.downloadFile(resp);
                  if (callBack) {
                    callBack();
                  }
                  downloadSub$.unsubscribe();
                  notifSub$.unsubscribe();
                },
                error: () => {
                  downloadSub$.unsubscribe();
                  notifSub$.unsubscribe();
                }
              });
            });
          } else {
            notifSub$.unsubscribe();
          }
        });
      } else {
        this.messageManager.newMessage(
          new IupicsMessage(
            this.translate.instant('exportData.messageTitle'),
            this.translate.instant('exportData.exportDataFail'),
            'error'
          )
        );
      }
    });
  }

  printReportWithChoice(printParams: PrintParams, callBack?: Function): Observable<any> {
    const { ids, sessionId, tabId, printFormatId, fileTypeId, whereclause, datagridRequest, columnsKeys, ctx } = printParams;
    return zip(this.uiCreator.getCompiereTab(tabId), this.po.get<any>('AD_PrintFormat', parseInt(printFormatId, 0))).pipe(
      switchMap(([compiereTab, printFormat]) => {
        const channel_id = shajs('sha256')
          .update(sessionId + '_' + compiereTab.tab.AD_Window_ID + '_' + moment().valueOf())
          .digest('hex');
        const paramsMap = this.generateParamsMap(compiereTab.tab, channel_id, ids, whereclause);
        if (printFormat) {
          paramsMap.ad_process_id = printFormat.AD_Process_ID ? printFormat.AD_Process_ID : 0;
          paramsMap.params['AD_PrintFormat_ID'] = printFormat.AD_PrintFormat_ID ? printFormat.AD_PrintFormat_ID : 0;
          paramsMap.params['FileFormat'] = fileTypeId;
        }
        if (datagridRequest) {
          paramsMap.params['datagridRequest'] = datagridRequest;
        }
        if (columnsKeys) {
          paramsMap.params['columnsKeys'] = columnsKeys;
        }
        // paramsMap.ad_tab_id = 0;
        paramsMap.tableName = compiereTab.tab.TableName;
        paramsMap.windowCtx = cloneDeep(ctx);
        // ajout des infos pour l'ouverture via notif {
        paramsMap.windowCtx['sourceInfo'] = {
          sourceType: IupicsMenuType.WINDOW,
          sourceTypeId: compiereTab.tab.AD_Window_ID,
          sourceRecordId: ids && ids.length === 1 ? ids[0] + '' : null,
          sourceTableNameTargeted: paramsMap.tableName,
          params: paramsMap.params
        };
        if (has(ctx, 'DocumentNo')) {
          paramsMap.windowCtx['sourceInfo']['apizPrintReport_DocumentNo'] = ctx['DocumentNo'];
        }
        return this.exportDatagridService.exportDataGridUsingPrintFormat(paramsMap).pipe(
          switchMap((response) => {
            try {
              const notifSub$ = this.notificationService.syncWithNotification(channel_id).subscribe((notification) => {
                if (notification.fileLinks) {
                  notification.fileLinks.forEach((file) => {
                    const downloadSub$ = this.printReportService.downloadReport(file.path).subscribe({
                      next: (resp) => {
                        Global.downloadFile(resp);
                        if (callBack) {
                          callBack();
                        }
                        downloadSub$.unsubscribe();
                        notifSub$.unsubscribe();
                      },
                      error: () => {
                        downloadSub$.unsubscribe();
                        notifSub$.unsubscribe();
                      }
                    });
                  });
                } else {
                  notifSub$.unsubscribe();
                }
              });
              return of(channel_id);
            } catch (e) {
              return of(null);
            }
          })
        );
      })
    );
  }
  generateParamsMap(tab: TabCompiereJSON, channel_id: string, ids: number[], whereclause: string) {
    let idStr = ids.join(',');
    const paramsMap: ProcessParams = {
      ad_process_id: tab.AD_Process_ID,
      ad_tab_id: null,
      className: undefined,
      record_id: undefined,
      tableName: undefined,
      tables: undefined,
      params: {},
      windowCtx: null
    };
    paramsMap.params['query'] = idStr;
    paramsMap.params['AD_Table_ID'] = tab.AD_Table_ID;
    paramsMap.params['channel_id'] = channel_id;
    paramsMap.ad_tab_id = tab.AD_Tab_ID;
    paramsMap.params['FileFormat'] = 'PDF';
    paramsMap.params['whereclause'] = whereclause;
    paramsMap.params['AD_PrintFormat_ID'] = 0;
    return paramsMap;
  }

  getCompiereTab(tabId: number): Observable<any> {
    return this.uiCreator.getCompiereTab(tabId);
  }
}
export interface PrintParams {
  ids: number[];
  sessionId: number;
  tabId: number;
  printFormatId: any;
  fileTypeId: string;
  whereclause: string;
  datagridRequest?: CompiereDataGridRequestJSON;
  columnsKeys?: { [key: string]: any }[],
  ctx: any;
}
