import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { CompiereLanguage } from '@compiere-ws/models/compiere-language-json';
import { ProcessInProgressService } from '@compiere-ws/services/process-in-progress/process-in-progress.service';
import { SocketService } from '@compiere-ws/services/socket/socket.service';
import { ValuePreferencesService } from '@compiere-ws/services/value-preference/value-preference.service';
import { IAutocomplete } from '@iupics-components/models/autocomplete-interfaces';
import { InfoDialogType } from '@iupics-components/specific/window/info-dialog/info-dialog.component';
import { ProcessParams } from '@iupics-components/specific/window/process-ui/process-ui.component';
import { AutocompleteUiComponent } from '@iupics-components/standard/fields/autocomplete-ui/autocomplete-ui.component';
import { InputSwitchUiComponent } from '@iupics-components/standard/fields/input-switch-ui/input-switch-ui.component';
import { InputTextUiComponent } from '@iupics-components/standard/fields/input-text-ui/input-text-ui.component';
import { AppConfig } from '@iupics-config/app.config';
import { CacheManagerService } from '@iupics-manager/managers/cache-manager/cache-manager.service';
import { MessageManagerService } from '@iupics-manager/managers/message/message-manager.service';
import { NotificationManagerService } from '@iupics-manager/managers/notification-manager/notification-manager.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { Global } from '@iupics-manager/models/global-var';
import { IupicsMessage } from '@iupics-manager/models/iupics-message';
import { RoleUI, UserAccount } from '@iupics-manager/models/user-account';
import { TranslateService } from '@ngx-translate/core';
import { LanguageSelectionService } from '@web-desktop/components/menu-top/controllers/language-selection/language-selection.service';
import { ThemeService } from '@web-desktop/controllers/theme.service';
import { UserPreference } from '@web-desktop/models/user-preference';
import jwtDecode from 'jwt-decode';
import { KeycloakService } from 'keycloak-angular';
import * as moment from 'moment';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Observable, of, Subscription } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as shajs from 'sha.js';
@Component({
  selector: 'iu-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit, OnDestroy {
  languages: { items: CompiereLanguage[] } = { items: [] };
  printerNames = { items: [] };
  opened = false;
  isUpdateProfil = false;
  currentLanguage: CompiereLanguage;
  previousLanguage: CompiereLanguage;
  currentDefaultDate: Date;
  subscriptions: any[] = [];
  @Output()
  logoutEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  changeRoleEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  openEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Input()
  nbTabs = 0;
  @Input() overlayPanel: UserProfileOverlayPanel;

  isPrintPreview: boolean;
  isShowAcct: boolean;
  isShowAdvanced: boolean;
  isShowTrl: boolean;
  isAutoCommit: boolean;
  currentUITheme;
  UIThemes: { items: {}[] };
  NotificationDelay: { items: {}[] } = {
    items: [
      { displayValue: '2', id: 2 },
      { displayValue: '5', id: 5 },
      { displayValue: '10', id: 10 },
      { displayValue: '15', id: 15 },
      { displayValue: '20', id: 20 },
      { displayValue: '30', id: 30 }
    ]
  };
  printerName: Object;
  notificationReminderTimeout: any;
  valSubscr: Subscription;
  canSubscr: Subscription;
  @ViewChild('languagesSelector')
  set languageSelection(languagesSelector: AutocompleteUiComponent) {
    if (languagesSelector) {
      if (this.languageSelectionService.getCurrentLanguage() !== undefined) {
        this.currentLanguage = this.languageSelectionService.getCurrentLanguage();
        languagesSelector.selectByIdentifier('iso_code', this.currentLanguage.iso_code);
        this.previousLanguage = this.currentLanguage;
      }
    }
  }

  @ViewChild('languagesSelector')
  languagesSelector: AutocompleteUiComponent;

  @ViewChild('themeSelector')
  themeSelector: AutocompleteUiComponent;

  @ViewChild('printerNameField')
  printerNameField: InputTextUiComponent;

  @ViewChild('autoCommitField')
  autoCommitField: InputSwitchUiComponent;

  @ViewChild('advancedShowField')
  advancedShowField: InputSwitchUiComponent;

  @ViewChild('acctShowField')
  acctShowField: InputSwitchUiComponent;

  @ViewChild('trlShowField')
  trlShowField: InputSwitchUiComponent;

  @ViewChild('previewField')
  previewField: InputSwitchUiComponent;

  @ViewChild('notificationReminderTimeoutField')
  notificationReminderTimeoutField: AutocompleteUiComponent;

  roleIsDisplay: boolean = false;
  selectedRole: RoleCtxAutocomplete;
  roles$: Observable<RoleCtxAutocomplete[]>;
  searchRole = '';

  organisationIsDisplay: boolean = false;
  selectedOrganisation: IAutocomplete;
  organisations$: Observable<IAutocomplete[]>;
  searchOrganisation = '';

  warehouseIsDisplay: boolean = false;
  selectedWarehouse: IAutocomplete;
  warehouses$: Observable<IAutocomplete[]>;
  searchWarehouse = '';

  currentUserAccount: UserAccount;
  isMobile = false;
  isMobileWidth = false;

  tokenExpiration: string;

  // on peut le mettre dans un composant custo
  isSplitButtonOpen = false;

  constructor(
    private uiCreatorService: UICreatorService,
    public connectorService: SecurityManagerService,
    private translateService: TranslateService,
    private languageSelectionService: LanguageSelectionService,
    private socketService: SocketService,
    private messageManager: MessageManagerService,
    private config: AppConfig,
    private notificationManager: NotificationManagerService,
    private themeService: ThemeService,
    private progressService: ProcessInProgressService,
    private cacheService: CacheManagerService,
    private readonly keycloakService: KeycloakService,
    private readonly valuePref: ValuePreferencesService
  ) {}

  ngOnInit() {
    this.isMobile = Global.isMobile();
    this.isMobileWidth = Global.isMobileWidth();
    this.currentDefaultDate = moment(this.connectorService.getIupicsUserContext['#Date']).toDate();
    this.opened = Global.isLoginPageShow;
    this.currentUserAccount = this.connectorService.getIupicsUserAccount();
    this.languageSelectionService.getLanguages().subscribe((languages) => {
      this.languages.items = languages;
    });
    this.uiCreatorService.getCupsPrinters().subscribe((cups) => {
      cups.forEach((printer) => {
        this.printerNames.items.push({ id: printer.name, displayValue: printer.name });
      });
    });
    this.socketService.initSocket();
    // ? Pour faire des recharger config sans ennuyer ceux sur apiz-dev
    // ? et ceux en localhost, decommenté cette ligne et remplacer le trigramme
    // SocketService.RELOAD_CONFIG_CHANNEL += '_private_xmz';

    (async () => {
      for await (const reload of this.socketService.enableReloadConfigChannel()) {
        if (reload) {
          let timer = this.config.getConstant('reloadingTimeout');
          timer = this.displayReloading(timer);
          setInterval(() => {
            timer = this.displayReloading(timer);
          }, 1000);
        }
      }
    })();

    this.overlayPanel.overridedHide = this.overlayPanel.hide;
    this.overlayPanel.hide = () => {
      if (!this.isUpdateProfil) {
        this.overlayPanel.overridedHide();
      }
    };

    this.overlayPanel.onHide.subscribe((_) => {
      this.isUpdateProfil = false;
    });
    this.loadRoles();
    this.loadOrganisations();
    this.loadWarehouses();
  }

  private displayReloading(timer: number) {
    this.messageManager.newMessage(
      new IupicsMessage(
        this.translateService.instant('generic.warning'),
        this.translateService.instant('userProfile.refreshSession', {
          timer: timer--
        }),
        'message'
      )
    );
    if (timer <= 0) {
      location.reload(); // mis à true pour prendre les derniere modifs coté serveur et non plus du cache
    }
    return timer;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
    this.socketService.disableReloadConfigChannel();
  }

  selectCurrentRole(selectedRole: RoleCtxAutocomplete) {
    const previousRole = this.selectedRole;
    this.selectedRole = selectedRole;
    // on désactive le channel du role précédent dans le centre des notifications et on active le nouveau
    this.notificationManager.changeRoleChannel(previousRole.id, this.selectedRole.id);
  }

  display() {
    this.currentUserAccount = this.connectorService.getIupicsUserAccount();
    this.initUserProfileSettings();
    this.opened = true;
  }

  hide() {
    this.initUserProfileSettings();
    this.overlayPanel.hide();
    this.opened = false;
  }

  private initUserProfileSettings(): void {
    this.roleIsDisplay = false;
    this.organisationIsDisplay = false;
    this.warehouseIsDisplay = false;
    this.isUpdateProfil = false;
    this.isSplitButtonOpen = false;
  }

  logout(event) {
    this.uiCreatorService.resetCachingData();
    this.cacheService.clearCache();
    this.logoutEmitter.emit(event);
  }

  valideRole() {
    this.subscriptions.push(
      this.connectorService.changeRole(this.selectedRole.roleUI).subscribe((iupicsUserAccount) => {
        this.changeRoleEmitter.emit(this.selectedRole.roleUI);
        this.notificationManager.roleChanged();
        this.progressService.updateSocket();
      })
    );
  }

  changeRole(selectedRole: RoleCtxAutocomplete) {
    if (selectedRole.id !== this.selectedRole?.id) {
      if (this.nbTabs > 0) {
        if ((this.valSubscr && !this.valSubscr.closed) || (this.canSubscr && !this.canSubscr.closed)) {
          Global.infoDialog.cancelDialog();
        }
        Global.infoDialog.message = {
          summary: this.translateService.instant('infodialog.dialogs.changerole.title'),
          detail: this.translateService.instant('infodialog.dialogs.changerole.message')
        };
        Global.infoDialog.dialogType = InfoDialogType.CONFIRM;
        Global.infoDialog.showInfoDialog();
        this.valSubscr = Global.infoDialog.confirm.subscribe(() => {
          this.selectCurrentRole(selectedRole);
          this.valideRole();
          this.valSubscr.unsubscribe();
          this.canSubscr.unsubscribe();
        });

        this.canSubscr = Global.infoDialog.cancel.subscribe(() => {
          this.canSubscr.unsubscribe();
          this.valSubscr.unsubscribe();
        });
      } else {
        this.selectCurrentRole(selectedRole);
        this.valideRole();
        this.hide();
      }
    }
  }

  validateProfile() {
    this.previousLanguage = this.currentLanguage;
    this.currentLanguage = this.languagesSelector.fieldValue;

    if (this.currentLanguage.iso_code !== this.connectorService.getIupicsUserAccount().default_language.iso_code) {
      this.connectorService.getIupicsUserAccount().default_language = this.currentLanguage;
      if (this.nbTabs > 0) {
        Global.infoDialog.message = {
          summary: this.translateService.instant('infodialog.dialogs.changelanguage.title'),
          detail: this.translateService.instant('infodialog.dialogs.changelanguage.message')
        };
        Global.infoDialog.dialogType = InfoDialogType.CONFIRM;
        Global.infoDialog.showInfoDialog();

        const valSubscr = Global.infoDialog.confirm.subscribe(() => {
          this.languageSelectionService.changeLanguage(this.currentLanguage);
          this.savePreference(true);
          this.hide();
          valSubscr.unsubscribe();
          canSubscr.unsubscribe();
        });

        const canSubscr = Global.infoDialog.cancel.subscribe(() => {
          this.currentLanguage = this.previousLanguage;
          this.connectorService.getIupicsUserAccount().default_language = this.currentLanguage;
          canSubscr.unsubscribe();
          valSubscr.unsubscribe();
        });
      } else {
        this.languageSelectionService.changeLanguage(this.currentLanguage);
        this.savePreference(true);
        this.hide();
      }
    } else {
      this.savePreference();
      this.hide();
    }
  }

  private savePreference(validateRole = false) {
    let userPref: UserPreference;
    userPref = {
      '#UITheme': this.themeSelector?.fieldValue ? this.themeSelector.fieldValue.key : this.currentUITheme.key,
      PrinterName:
        this.printerNameField && this.printerNameField.fieldValue && this.printerNameField.fieldValue.id
          ? this.printerNameField.fieldValue.id
          : null,
      AutoCommit: this.autoCommitField.fieldValue === 'Y',
      '#ShowAdvanced': this.advancedShowField.fieldValue === 'Y',
      '#ShowAcct': this.acctShowField.fieldValue === 'Y',
      '#ShowTrl': this.trlShowField.fieldValue === 'Y',
      '#PrintPreview': this.previewField.fieldValue === 'Y',
      NotificationReminderTimeout: this.notificationReminderTimeoutField.fieldValue
        ? this.notificationReminderTimeoutField.fieldValue.id
        : null
    };

    const prefSubsc = this.connectorService.savePref(userPref).subscribe((res) => {
      if (res) {
        const ctx = this.connectorService.getIupicsUserContext();
        ctx['AutoCommit'] = userPref.AutoCommit ? 'Y' : 'N';
        ctx['#PrintPreview'] = userPref['#PrintPreview'] ? 'Y' : 'N';
        ctx['#ShowAcct'] = userPref['#ShowAcct'] ? 'Y' : 'N';
        ctx['#ShowAdvanced'] = userPref['#ShowAdvanced'] ? 'Y' : 'N';
        ctx['#ShowTrl'] = userPref['#ShowTrl'] ? 'Y' : 'N';
        ctx['#Printer'] = userPref['PrinterName'];
        ctx['#UITheme'] = userPref['#UITheme'];
        ctx['#AD_Language'] = this.currentLanguage.iso_code;
        ctx['NotificationReminderTimeout'] = userPref.NotificationReminderTimeout;
        this.isUpdateProfil = false;
        if (validateRole) {
          this.valideRole();
        }
        prefSubsc.unsubscribe();
      }
    });
  }

  displayRoles() {}

  reloadConfiguration(event: MouseEvent, withRefresh: boolean) {
    event.stopPropagation();
    if (this.selectedRole.roleUI.role_id === 0) {
      this.connectorService.resetCache().subscribe(() => {
        if (withRefresh) {
          this.socketService.broadcast(SocketService.RELOAD_CONFIG_CHANNEL, true);
        } else {
          this.messageManager.newMessage(
            new IupicsMessage(
              this.translateService.instant('generic.success'),
              this.translateService.instant('profil.cache-success'),
              'success'
            )
          );
        }
      });
    }
  }

  openUpdateProfil(event: Event) {
    const ctx = this.connectorService.getIupicsUserContext();
    this.isAutoCommit = ctx['AutoCommit'];
    this.isPrintPreview = ctx['#PrintPreview'];
    this.isShowAcct = ctx['#ShowAcct'];
    this.isShowAdvanced = ctx['#ShowAdvanced'];
    this.isShowTrl = ctx['#ShowTrl'];
    this.printerName = { id: ctx['#Printer'], displayValue: ctx['#Printer'] };
    let timeout = ctx['NotificationReminderTimeout'];
    if (!timeout) {
      timeout = this.config.getConstant('notificationReminderTimeout');
    }
    this.notificationReminderTimeout = { id: timeout, displayValue: timeout + '' };

    this.UIThemes = this.themeService.getAvailableThemes();
    this.themeService.setActiveTheme(ctx['#UITheme']);
    this.currentUITheme = this.themeService.getActiveTheme();

    this.isUpdateProfil = true;

    event.stopPropagation();
  }

  changeDefaultDate(date: Date) {
    if (date) {
      this.connectorService.setIupicsUserContextDate(date);
      this.currentDefaultDate = date;
    }
  }

  changeTheme(event) {
    if (event && event['key']) {
      this.themeService.setActiveTheme(event['key']);
    }
    this.currentUITheme = this.themeService.getActiveTheme();
    this.themeSelector.autoComplete.value = this.currentUITheme;
    this.themeSelector.autoComplete.updateInputField();
  }

  showUserContext(event) {
    event.stopPropagation();
    Global.workspace.showContextPanel(this.connectorService.getIupicsUserContext());
  }

  resetDashboard(e: Event) {
    e.stopPropagation();
    const ad_process_id = this.config.getConstant('UserProfileComponent#reset_dashboard_process_id');
    const AD_User_ID = this.currentUserAccount.id;
    const AD_Role_ID = this.currentUserAccount.current_role.role_id;
    const channel_id = shajs('sha256')
      .update(this.currentUserAccount.session_id + '_' + moment().valueOf())
      .digest('hex');
    const processParams: ProcessParams = {
      ad_process_id,
      ad_tab_id: null,
      className: null,
      params: { AD_User_ID, AD_Role_ID, channel_id },
      record_id: null,
      tableName: null,
      tables: null,
      windowCtx: this.connectorService.getIupicsUserContext()
    };
    const sub = this.uiCreatorService
      .executeProcess(processParams)
      .pipe(
        map(() => 'Y'),
        catchError(() => of(undefined))
      )
      .subscribe((res) => {
        if (res) {
          const progressSub = this.progressService.watchProcessInProgress().subscribe((pings) => {
            const me = this.connectorService.getIupicsUserAccount();
            const ping = pings.find((p) => {
              return p.AD_User_ID.id === me.id && p.AD_Process_ID.id === ad_process_id;
            });
            if (ping && ping.Status === 'finish') {
              this.socketService.closeDataChannel(channel_id);
              progressSub.unsubscribe();
              sub.unsubscribe();
              location.reload();
            }
          });
        }
      });
  }

  async setTokenExpirationInterval({ target }: MouseEvent) {
    const decodedToken = <any>jwtDecode(await this.keycloakService.getToken());
    const expirationLimit = decodedToken?.auth_time * 1000 + decodedToken?.ssoSessionMaxLifespan * 1000;
    const refreshExpiration = () => (this.tokenExpiration = this.getTokenExpirationFormated(expirationLimit));
    refreshExpiration();

    const interval = setInterval(refreshExpiration, 100);
    (<HTMLElement>target).onmouseout = () => clearInterval(interval);
  }
  private getTokenExpirationFormated(value: number): string {
    let str = '';
    // Find the distance between now and the count down date
    const distance = value - Date.now();
    if (distance <= 1000) {
      return this.translateService.instant('profil.expired');
    }

    // Time calculations for days, hours, minutes and seconds
    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    if (days > 0) {
      str += `${days} ${this.translateService.instant('profil.timeUnits.day')} `;
    }
    const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    if (hours > 0) {
      str += `${hours} ${this.translateService.instant('profil.timeUnits.hour')} `;
    }
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    if (minutes > 0) {
      str += `${minutes} ${this.translateService.instant('profil.timeUnits.minute')} `;
    }
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);
    if (seconds) {
      str += `${seconds} ${this.translateService.instant('profil.timeUnits.second')} `;
    }
    return str.trim();
  }

  goToKeycloakManageProfile() {
    window.open(this.keycloakService.getKeycloakInstance().createAccountUrl(), '_blank');
  }

  private loadRoles() {
    const roles: RoleCtxAutocomplete[] = this.currentUserAccount.roles.map((role) => ({
      id: role.role_id,
      displayValue: role.name,
      roleUI: role
    }));
    this.selectedRole = roles.find((r) => r.roleUI.role_id === this.currentUserAccount.current_role.role_id);
    this.roles$ = of(roles);
  }

  private loadOrganisations() {
    this.organisations$ = this.connectorService.getOrganisations().pipe(
      map((organisations) => {
        const ctx = this.connectorService.getIupicsUserContext();
        const orgID = ctx['#AD_Org_ID'];
        this.selectedOrganisation = organisations.find((o) => o.id === orgID);
        return organisations;
      })
    );
  }

  private loadWarehouses() {
    this.warehouses$ = this.connectorService.getWarehouses().pipe(
      map((warehouses) => {
        const ctx = this.connectorService.getIupicsUserContext();
        const warehouseID = ctx['#M_Warehouse_ID'];
        this.selectedWarehouse = warehouses.find((w) => w.id === warehouseID);
        return warehouses;
      })
    );
  }

  public updateOrganisation(selectedOrganisation: IAutocomplete) {
    if (selectedOrganisation.id !== this.selectedOrganisation?.id) {
      const ctx = this.connectorService.getIupicsUserContext();
      const ad_Client_ID = isNaN(parseInt(ctx['#AD_Client_ID'], 10)) ? 0 : parseInt(ctx['#AD_Client_ID'], 10);
      const ad_User_ID = isNaN(parseInt(ctx['##AD_User_ID'], 10)) ? 0 : parseInt(ctx['##AD_User_ID'], 10);
      this.valuePref
        .saveValuePreference(
          {
            attribute: 'AD_Org_Default',
            value: selectedOrganisation.id,
            ad_Client_ID,
            ad_Org_ID: 0,
            ad_User_ID,
            ad_Window_ID: 0,
            ad_Form_ID: 0,
            ad_Process_ID: 0
          },
          false
        )
        .pipe(switchMap((_) => this.connectorService.updateRemoteCtx()))
        .subscribe((_ctx) => {
          this.selectedOrganisation = selectedOrganisation;
          this.loadWarehouses();
        });
    }
  }

  public updateWarehouse(selectedWarehouse: IAutocomplete) {
    if (selectedWarehouse.id !== this.selectedWarehouse?.id) {
      const ctx = this.connectorService.getIupicsUserContext();
      const ad_Client_ID = isNaN(parseInt(ctx['#AD_Client_ID'], 10)) ? 0 : parseInt(ctx['#AD_Client_ID'], 10);
      const ad_User_ID = isNaN(parseInt(ctx['##AD_User_ID'], 10)) ? 0 : parseInt(ctx['##AD_User_ID'], 10);
      this.valuePref
        .saveValuePreference(
          {
            attribute: 'M_Warehouse_Default',
            value: selectedWarehouse.id,
            ad_Client_ID,
            ad_Org_ID: 0,
            ad_User_ID,
            ad_Window_ID: 0,
            ad_Form_ID: 0,
            ad_Process_ID: 0
          },
          false
        )
        .pipe(switchMap((_) => this.connectorService.updateRemoteCtx()))
        .subscribe((_ctx) => {
          this.selectedWarehouse = selectedWarehouse;
        });
    }
  }

  public toggleDropdown(key: 'role' | 'organisation' | 'warehouse') {
    const savedIsDisplay = this[`${key}IsDisplay`];
    this.roleIsDisplay = false;
    this.organisationIsDisplay = false;
    this.warehouseIsDisplay = false;
    this[`${key}IsDisplay`] = !savedIsDisplay;
  }
}

interface UserProfileOverlayPanel extends OverlayPanel {
  overridedHide: () => any;
}

interface RoleCtxAutocomplete extends IAutocomplete {
  roleUI: RoleUI;
}
