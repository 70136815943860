import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  ComponentRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation
} from '@angular/core';
import { DataStore } from '@compiere-ws/models/compiere-data-json';
import { CompiereExportDataService } from '@compiere-ws/services/compiere-export-data/compiere-export-data.service';
import { CompiereProcessService } from '@compiere-ws/services/compiere-process/compiere-process.service';
import { ProcessInProgressService } from '@compiere-ws/services/process-in-progress/process-in-progress.service';
import { SocketService } from '@compiere-ws/services/socket/socket.service';
import { ProcessLogsUiComponent } from '@iupics-components/message/process-logs-ui/process-logs-ui.component';
import { IAutocomplete, PrintFormatAutocomplete } from '@iupics-components/models/autocomplete-interfaces';
import { CalendarUiComponent } from '@iupics-components/standard/fields/calendar-ui/calendar-ui.component';
import { InputFileUiComponent } from '@iupics-components/standard/fields/input-file-ui/input-file-ui.component';
import { EditViewUiComponent } from '@iupics-components/standard/layouts/edit-view-ui/edit-view-ui.component';
import { EditViewUtils } from '@iupics-components/standard/layouts/edit-view-ui/utils/edit-view.utils';
import { ModalUiComponent } from '@iupics-components/standard/layouts/modal-ui/modal-ui.component';
import { AppConfig } from '@iupics-config/app.config';
import { CacheManagerService } from '@iupics-manager/managers/cache-manager/cache-manager.service';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { MessageManagerService } from '@iupics-manager/managers/message/message-manager.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { UICreatorUtils } from '@iupics-manager/managers/ui-creator/utils/ui-creator.utils';
import { WindowFactoryService } from '@iupics-manager/managers/ui-creator/window-factory/window-factory.service';
import { AbstractDataContainer } from '@iupics-manager/models/abstract-datacontainer';
import { AbstractDynamicComponent } from '@iupics-manager/models/abstract-dynamic-component';
import { DynamicComponent } from '@iupics-manager/models/dynamic-component';
import { Global } from '@iupics-manager/models/global-var';
import { IupicsField, IupicsProcess } from '@iupics-manager/models/iupics-data';
import { IupicsEvent, IupicsTypeEvent } from '@iupics-manager/models/iupics-event';
import { IupicsMessage } from '@iupics-manager/models/iupics-message';
import { Utils } from '@iupics-util/tools/util';
import { TranslateService } from '@ngx-translate/core';
import { IupicsMenuType } from '@web-desktop/models/menu-item-ui';
import { cloneDeep, has } from 'lodash';
import * as moment from 'moment';
import { MenuItem } from 'primeng/api';
import { of, Subscription, zip } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import * as shajs from 'sha.js';
import { SpecificWindowUiComponent } from '../specific-window-ui/specific-window-ui.component';

@Component({
  selector: 'iu-process-ui',
  templateUrl: './process-ui.component.html',
  styleUrls: ['./process-ui.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProcessUiComponent extends AbstractDynamicComponent implements OnInit, OnDestroy {
  constructor(
    private store: DataStoreService,
    private windowFactory: WindowFactoryService,
    private connectorService: SecurityManagerService,
    private exportDataService: CompiereExportDataService,
    private uiCreator: UICreatorService,
    private processService: CompiereProcessService,
    private translateService: TranslateService,
    private socketService: SocketService,
    private messageManager: MessageManagerService,
    private progressService: ProcessInProgressService,
    private config: AppConfig,
    private cacheService: CacheManagerService
  ) {
    super();
  }
  @ViewChild('logContainer') private processLogs: ProcessLogsUiComponent;
  @ViewChild('vcrSpecific', { read: ViewContainerRef, static: true })
  vcrSpecific: ViewContainerRef;
  @ViewChild('specificModal', { read: ModalUiComponent, static: true })
  specificModal: ModalUiComponent;
  @Input()
  isDisplay = true;
  @Input()
  description: string;
  @Input()
  help: string;
  @Input()
  collapsed = false;
  @Input()
  activeTab;
  closeOnEscape = false;
  isProcessing = true;
  paramsMap: Map<string, any> = new Map<string, any>();
  private dataContainers: AbstractDataContainer[] = [];
  dataStore: DataStore;
  displaySearch = false;
  searchLinkedComponent;
  steps: ProcessStep[] = [];
  activeIndex = 0;
  @Output()
  emitter: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('fileTypesAutocomplete')
  fileTypesAutocomplete: AbstractDataContainer;
  @ViewChild('printFormatsAutocomplete')
  printFormatsAutocomplete: AbstractDataContainer;

  id: string;
  name: string;
  appName: string;
  componentRef: ComponentRef<any>;
  bounds: HTMLElement;
  inBounds: boolean;
  height: number;
  width = 635;
  iconClass: string;
  @Input()
  windowId: number;
  @Input()
  parentComponent: any;
  @Input() sourceComponentData: any;
  vcrwindow: ViewContainerRef;
  index: number;
  processItem: { tabId: number; label: string };
  windowType = IupicsMenuType.PROCESS;
  heightCss = '100%';
  countParams = 0;
  @Input()
  isModal = false;
  @Output()
  closeModalEmitter = new EventEmitter<any>();

  exportDataOptions;

  channel_id: any;
  isReport = false;
  defaultFileType: IAutocomplete = { id: 'PDF', displayValue: 'PDF' };
  hasFormat = true;

  private progressSub: Subscription;

  private hasJasper = false;
  customFormModalBodyCss = undefined;
  specificWindowTitle: string;
  displayFormUI = false;
  searchPanelValidation: string;

  isHelpShown = false;

  private iupicsProcess: IupicsProcess;

  cmStepperEventHandler = (event: { originalEvent: MouseEvent; item: MenuItem }) => {
    Global.workspace.urlParams.id = 1000324;
    Global.workspace.urlParams.type = IupicsMenuType.FORM;
    Global.workspace.handleUrlParams();
  };

  ngOnInit() {
    if (this.activeTab) {
      Global.workspace.linkedComponentToTabMap[this.activeTab.id] = this;
    }
    this.steps = [{ label: this.translateService.instant('process.steps.noAction'), id: 0 }];
    if (this.activeTab) {
      const paramsMap: Map<string, any> = this.activeTab.processParamsMap;
      if (paramsMap) {
        this.paramsMap = paramsMap;
      }
    }
    const iupicsProcess: IupicsProcess = CacheManagerService.process_Params_caching.get(this.windowId);
    if (iupicsProcess) {
      this.hasJasper = iupicsProcess.process.ClassName && iupicsProcess.process.ClassName.endsWith('RusReportStarter');
      this.hasFormat =
        (!iupicsProcess.process.ClassName || !iupicsProcess.process.ClassName.endsWith('RusReportStarter')) &&
        iupicsProcess.process.IsReport;
      this.isReport = this.hasJasper || this.hasFormat || iupicsProcess.process.IsReport;
      this.triggerShowProcess(iupicsProcess);
    } else {
      this.processService.getProcess(this.windowId).subscribe({
        next: (process) => {
          this.hasJasper = process.process.ClassName && process.process.ClassName.endsWith('RusReportStarter');
          this.hasFormat =
            (!process.process.ClassName || !process.process.ClassName.endsWith('RusReportStarter')) && process.process.IsReport;
          this.isReport = this.hasJasper || this.hasFormat || process.process.IsReport;
          const iupicsProcess: IupicsProcess = {
            name: process.process.Name,
            description: process.process.Description,
            help: process.process.Help?.trim(),
            fields: [],
            process: process.process,
            fileFormats: process.fileFormats,
            printFormats: process.printFormats
          };
          const fields: IupicsField[] = [];
          const processWS = process.params;
          processWS.forEach((element) => {
            const field = UICreatorUtils.transformField(element, undefined, undefined, undefined, true);
            if (field) {
              field.data.nbCol = 1;
              field.data.colspan = 2;
              fields.push(field);
            }
            if (field.isRange) {
              field.data.colspan = 1;
              field.data.label += ' ' + this.translateService.instant('rangedField.from');
              const fieldTo = UICreatorUtils.transformField(element, undefined, undefined, undefined, true);
              fieldTo.isRange = false;
              fieldTo.data.fromRange = true;
              fieldTo.data.columnName += '_To';
              fieldTo.data.isSameLine = true;
              fieldTo.data.nbCol = 2;
              fieldTo.data.colspan = 1;

              fieldTo.data.label = this.translateService.instant('rangedField.to');
              fields.push(fieldTo);
            }

            this.countParams++;
          });
          //load in cache
          const arr = [];
          fields.forEach((item) => {
            if (item.processId && item.processId != this.windowId) {
              this.cacheService.loadProcessInCache(item.processId);
            } else if (item.formId) {
              arr.push(this.uiCreator.getSpecificWindow(item.formId));
            }
          });
          if (arr.length > 0) {
            zip(...arr).subscribe();
          }
          iupicsProcess.fields = fields;
          CacheManagerService.process_Params_caching.set(this.windowId, iupicsProcess);

          this.triggerShowProcess(iupicsProcess);
        },
        error: (error: HttpErrorResponse) => {
          if (error.status === 422) {
            const msg = this.sourceComponentData
              ? `Vous n'avez pas accès au processus ${this.sourceComponentData.data.label} [${this.sourceComponentData.data.columnName}]`
              : `Vous n'avez pas accès à ce processus`;
            throw new IupicsMessage(
              'Erreur',
              msg,
              'error',
              error.error.trace.map((stack: any) => `${stack.className}#${stack.methodName}:${stack.lineNumber}`).join('\n')
            );
          }
        }
      });
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.progressSub) {
      this.progressSub.unsubscribe();
    }
    this.socketService.closeDataChannel(this.channel_id);
  }

  /**
   * Demande à l'UICreator d'ajouter les champs dans notre composant
   * @param iupicsProcess
   */
  triggerShowProcess(iupicsProcess: IupicsProcess) {
    this.iupicsProcess = iupicsProcess;
    this.exportDataOptions = { printFormats: [], fileTypes: [] };
    if (iupicsProcess) {
      if (iupicsProcess.printFormats) {
        this.exportDataOptions['printFormats'] = {
          items: iupicsProcess.printFormats,
          IsParam: false,
          isMandatory: true
        };
      }
      if (iupicsProcess.fileFormats && (this.hasJasper || this.isReport)) {
        if (this.defaultFileType && !iupicsProcess.fileFormats.find((ff) => ff.id === this.defaultFileType.id)) {
          this.defaultFileType = iupicsProcess.fileFormats[0];
        }
        this.exportDataOptions['fileTypes'] = {
          items: iupicsProcess.printFormats[0]?.fileFormats || iupicsProcess.fileFormats,
          IsParam: false,
          isMandatory: true
        };
      }
    }
    this.isReport = this.activeTab ? this.activeTab.menu_type === 'Report' : this.isReport;
    this.dataStore = this.store.newProcessData(
      this.windowId,
      this.parentComponent instanceof EditViewUiComponent
        ? (<EditViewUiComponent>this.parentComponent).editTabs[0].dataStored
        : undefined
    );
    if (this.activeTab) {
      this.activeTab.isLoading = false;
    }
    this.processItem = { tabId: -1, label: iupicsProcess.name };
    this.description = iupicsProcess.description;
    this.help = iupicsProcess.help;
    const item: DynamicComponent = {
      container: this,
      DOMParentComponent: this,
      component: 'GridUiComponent',
      cssClass: 'p-col-12',
      isCssOnComponent: false,
      tabId: this.windowId,
      gridPaginator: false,
      parentProcess: this
    };
    this.windowFactory.newEventHandler({
      type: IupicsTypeEvent.showProcess,
      item: item
    });
    this.isProcessing = false;
  }

  windowResize(event): void {
    if (event.height < this.height) {
      this.heightCss = '500px';
    } else {
      this.heightCss = '700px';
    }
    this.height = event.height;

    this.width = event.width;
    this.emitter.emit({ height: event.height, width: this.width });
  }

  executeProcess(event: Event) {
    if (this.dataContainers.length < this.countParams) {
      return;
    }
    event.stopPropagation();
    this.clearLogs();
    const paramsMap: ProcessParams = {
      ad_process_id: null,
      className: null,
      record_id: null,
      tableName: null,
      tables: null,
      params: null,
      ad_tab_id: null,
      windowCtx: this.getCurrentContext()
    };
    this.isProcessing = true;
    const fieldsMandatory = this.isConstraintMandatoryRespected();
    if (fieldsMandatory.length > 0) {
      this.messageManager.newMessage(
        new IupicsMessage(
          this.translateService.instant('generic.warning'),
          this.translateService.instant('process.fillMandatoryField') + ' : [ ' + fieldsMandatory + ' ]'
        )
      );
      this.isProcessing = false;
    } else {
      paramsMap.params = {};
      this.dataContainers.forEach((dataContainer) => {
        if (dataContainer instanceof InputFileUiComponent && (<InputFileUiComponent>dataContainer).uploadedFiles.length > 0) {
          this.dataStore.data[dataContainer.data.columnName] = (<InputFileUiComponent>dataContainer).uploadedFiles[0].src;
        }
        if (dataContainer.isDateField) {
          const date = this.dataStore.data[dataContainer.data.columnName];
          if (date) {
            const newValue = moment(date).format('YYYY-MM-DDTHH:mm:ss.SSS');
            paramsMap.params[dataContainer.data.columnName] = newValue.substring(0, 26) + newValue.substring(27, newValue.length);
          } else {
            paramsMap.params[dataContainer.data.columnName] = null;
          }
        } else if (
          this.dataStore.data[dataContainer.data.columnName] === undefined ||
          this.dataStore.data[dataContainer.data.columnName] === null
        ) {
          paramsMap.params[dataContainer.data.columnName] = null;
        } else {
          paramsMap.params[dataContainer.data.columnName] = this.dataStore.data[dataContainer.data.columnName];
        }
      });
      this.socketService.closeDataChannel(this.channel_id);
      const channel_id = shajs('sha256')
        .update(this.connectorService.getIupicsUserAccount().session_id + '_' + this.windowId + '_' + moment().valueOf())
        .digest('hex');
      this.channel_id = channel_id;
      let recordId = null;
      let sourceTableNameTargeted = null;
      if (this.parentComponent) {
        if (this.parentComponent.editTabs && this.parentComponent.editTabs[0] && this.parentComponent.editTabs[0].data) {
          // EditView Case
          paramsMap.tableName = this.parentComponent.editTabs[0].data['TableName'];
          recordId = this.parentComponent.currentDataStoreKey.recordId.split(',')[1];
          sourceTableNameTargeted = this.parentComponent.editTabs[0].data['TableName'];
          if (recordId !== null || recordId !== undefined) {
            if (isNaN(recordId)) {
              let colID = this.parentComponent.currentDataStoreKey.recordId.split(',')[0];
              if (!colID.endsWith('_ID')) {
                colID += '_ID';
              }
              paramsMap.record_id = this.dataStore.data[colID];
            } else {
              paramsMap.record_id = recordId;
            }
          }
        } else if (this.parentComponent.dataStore) {
          // Form Case
          recordId = this.parentComponent.dataStore.data['Record_ID'];
          paramsMap.record_id = has(recordId, 'id') ? recordId.id : recordId;
        }
      }

      paramsMap.ad_process_id = this.windowId;
      if (this.isReport) {
        paramsMap.params['FileFormat'] = this.fileTypesAutocomplete.fieldValue.id;
        if (this.hasFormat && this.exportDataOptions && this.exportDataOptions.printFormats.items.length > 0) {
          if (this.printFormatsAutocomplete.fieldValue) {
            paramsMap.params['AD_PrintFormat_ID'] = parseInt(this.printFormatsAutocomplete.fieldValue.id, 0);
          }
        }
      }
      paramsMap.params['channel_id'] = channel_id;
      this.socketService.closeDataChannel(this.channel_id);
      (async () => {
        for await (const log of this.socketService.openDataChannel<any>(this.channel_id)) {
          this.processLogs.addLog(log);
        }
      })();
      if (
        this.parentComponent &&
        this.parentComponent.container &&
        this.parentComponent.container.activeTab &&
        this.parentComponent.container.activeTab.action_id
      ) {
        // ajout des infos pour l'ouverture via notif
        paramsMap.windowCtx = Object.assign(paramsMap.windowCtx, {
          sourceInfo: {
            sourceType: IupicsMenuType.WINDOW,
            sourceTypeId: this.parentComponent.container.activeTab.action_id,
            sourceRecordId: recordId,
            sourceTableNameTargeted: sourceTableNameTargeted,
            params: paramsMap.params
          }
        });
      }
      const obs = this.uiCreator.executeProcess(paramsMap).pipe(
        map((_) => {
          // pas de reponse quand 200
          return 'Y';
        }),
        catchError((error) => of(undefined))
      );
      const obs2 = this.cacheService.getProcess(this.windowId).pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => of(undefined))
      );
      this.steps = [
        { label: this.translateService.instant('process.steps.execute'), id: 0 },
        { label: this.translateService.instant('process.steps.inProgress'), id: 1 },
        { label: this.translateService.instant('process.steps.executed'), id: 2 }
      ];
      this.activeIndex = 0;
      this.subscriptions.push(
        zip(obs, obs2).subscribe((values) => {
          if (this.parentComponent !== undefined) {
            (this.parentComponent as EditViewUiComponent).isProcessLaunch = true;
          }
          // ajout du process dans la file du processRunner réussi?
          if (values[0]) {
            this.activeIndex = 1;
            // récupération de l'état du process réussi?
            // todo: retirer ce qui correspond a values[1] autant ici que dans cypress
            if (values[1]) {
              if (this.progressSub) {
                this.progressSub.unsubscribe();
              }
              this.progressSub = this.progressService.watchProcessInProgress().subscribe((pings) => {
                if (this.activeIndex === 1) {
                  const me = this.connectorService.getIupicsUserAccount();
                  const ping = pings.find((p) => {
                    return p.AD_User_ID.id === me.id && p.AD_Process_ID.id === this.windowId;
                  });
                  if (ping && ping.Status === 'finish') {
                    this.isProcessing = false;
                    this.activeIndex = 2;
                    this.socketService.closeDataChannel(this.channel_id);
                    this.progressSub.unsubscribe();
                  }
                }
              });
            } else {
              this.isProcessing = false;
              this.steps = [{ label: this.translateService.instant('process.steps.noState'), id: 0 }];
              this.activeIndex = 0;
            }
          } else {
            this.isProcessing = false;
            this.steps = [{ label: this.translateService.instant('process.steps.notExecuted'), id: 0 }];
            this.activeIndex = 0;
          }
        })
      );
    }
  }

  isConstraintMandatoryRespected(): string[] {
    const isOk = [];
    if (this.dataContainers) {
      let i = 0;
      while (isOk && i < this.dataContainers.length) {
        if (
          !this.dataContainers[i].isAccordion &&
          this.dataContainers[i].displayCss !== 'none' &&
          this.dataContainers[i].data.isMandatory &&
          (this.dataContainers[i].fieldValue === undefined ||
            this.dataContainers[i].fieldValue === null ||
            this.dataContainers[i].fieldValue === '')
        ) {
          isOk.push(this.dataContainers[i].label);
        }
        i++;
      }
    }
    return isOk;
  }

  updateContainerZone(n: number) {}

  onChildUpdate(event: IupicsEvent): void {}
  onSiblingUpdate(event: IupicsEvent) {}
  onRemoveComponent(event: IupicsEvent) {}

  onPrintFormatChange(event: PrintFormatAutocomplete) {
    if (event.fileFormats?.length) {
      this.fileTypesAutocomplete.data.items = event.fileFormats;
    } else {
      this.fileTypesAutocomplete.data.items = this.iupicsProcess.fileFormats;
    }
  }

  addDataContainerToEditTab(dataContainer: AbstractDataContainer) {
    // binding it to get the parent context
    if (!this.parentComponent) {
      dataContainer.notifyUrlChange = this.notifyUrlChange.bind(this);
    }
    this.dataContainers.push(dataContainer);
  }
  notifyUrlChange() {
    // update url to fit fields current state
    const paramsMap: Map<string, any> = new Map<string, any>();
    this.DOMChildrenComponent.forEach((child) => {
      child.DOMChildrenComponent.forEach((child2) => {
        child2.DOMChildrenComponent.forEach((child3) => {
          if (
            (<AbstractDataContainer>child3).fieldValue &&
            (!(<AbstractDataContainer>child3).data || (<AbstractDataContainer>child3).data.fieldType !== 'password')
          ) {
            if (child3 instanceof CalendarUiComponent) {
              const value = <Date>(<AbstractDataContainer>child3).fieldValue;
              paramsMap.set(child3.data.columnName, value.getTime());
            } else {
              paramsMap.set(child3.data.columnName, (<AbstractDataContainer>child3).fieldValue);
            }
          }
        });
      });
    });
    Utils.onTabChange(this.activeTab, null, paramsMap);
  }

  showSearchPanel(linkedComponent) {
    let url = null;
    if (linkedComponent.data.urlSearch) {
      url = linkedComponent.data.urlSearch;
    } else if (linkedComponent.data && linkedComponent.data.details && linkedComponent.data.details.entityID) {
      url = '/Field/Search?id=' + linkedComponent.data.details.entityID + '&type=0';
    }
    if (url) {
      this.subscriptions.push(
        this.uiCreator.getFieldsData(url).subscribe((data) => {
          this.searchPanelValidation = linkedComponent.data.details.keyColumn.split('.')[0] + ".IsActive='Y'";
          linkedComponent.data.searchColumns = data;
          this.displaySearch = true;
          this.searchLinkedComponent = linkedComponent;
        })
      );
    }
  }

  clearLogs() {
    this.processLogs.clearLogs();
  }

  showCMChecker(item: ProcessStep): boolean {
    if (item.id === 1) {
      return true;
    }
    return false;
  }
  getWindowContext() {
    return this.getCurrentContext();
  }
  /**
   * récupération du contexte complet du composant
   * @param dataStore nouveau datastore à prendre en compte
   */
  getCurrentContext(dataStore?: DataStore) {
    let specificParent;
    let editViewParent;
    if (this.parentComponent && this.parentComponent.editTabs && this.parentComponent.editTabs[0]) {
      editViewParent = this.parentComponent;
    } else if (this.parentComponent instanceof SpecificWindowUiComponent) {
      specificParent = this.parentComponent;
    }
    /*merge avec le contexte du parent */
    if (specificParent) {
      const specificParentContext = specificParent.getCurrentContext();
      return EditViewUtils.mergeCurrentDataDeepCopy(
        cloneDeep(dataStore ? dataStore : this.dataStore.data),
        specificParentContext
      );
    } else {
      return EditViewUtils.getCurrentContext(
        editViewParent,
        dataStore ? dataStore : this.dataStore,
        this.connectorService.getIupicsUserContext()
      );
    }
  }
  /*
   * emailEditor: { display: { key: 'displayEmailEditor', value: displayEmailEditor }}
   * joinFilesPanel: { display: { key: 'displayJoinFilesPanel', value: displayJoinFilesPanel }}
   * processModal: { display: { key: 'displayProcessUI', value: displayProcessUI }, id: { key: 'processId', value: processId } }
   * formModal: { display: { key: 'displayFormUI', value: displayFormUI }, id: { key: 'formId', value: formId } }
   * sourceComponent : déterminer si autocomplete ou button
   */
  updateModalDisplay(display: { key: string; value: boolean; sourceComponent?: any }, id?: { key: string; value: number }) {
    this.customFormModalBodyCss =
      display.key && display.key === 'displayFormUI'
        ? { 'iu-modal-body': { overflow: 'auto', height: 'calc(100% - 25px)' } }
        : undefined;
    this[display.key] = display.value;
    if (id && display.value === true) {
      this[id.key] = id.value;
      if (id.key !== 'processId') {
        this.createSpecificWindow(id.value, display.sourceComponent);
      }
    } else {
      this.customFormModalBodyCss = undefined;
    }
  }
  createSpecificWindow(formId: number, sourceComponent: any) {
    this.subscriptions.push(
      this.uiCreator.getSpecificWindow(formId).subscribe((specificWindow) => {
        let component;
        if (specificWindow.angularClass && specificWindow.angularClass.length > 0 && specificWindow.angularClass !== 'default') {
          component = CacheManagerService.iupics_specific_window.get(specificWindow.angularClass);
        }
        if (!component) {
          component = CacheManagerService.iupics_specific_window.get('default');
        }
        this.vcrSpecific.clear();
        const componentRef = this.vcrSpecific.createComponent(component);
        this.specificWindowTitle = specificWindow.name;
        (<SpecificWindowUiComponent>componentRef.instance).name = specificWindow.name;
        (<SpecificWindowUiComponent>componentRef.instance).title = specificWindow.title;
        (<SpecificWindowUiComponent>componentRef.instance).description = specificWindow.description;
        (<SpecificWindowUiComponent>componentRef.instance).help = specificWindow.help;
        // (<SpecificWindowUiComponent>componentRef.instance).iconClass = specificWindow.iconClass;
        (<SpecificWindowUiComponent>componentRef.instance).componentRef = componentRef;
        // (<SpecificWindowUiComponent>componentRef.instance).id = specificWindow.id;
        (<SpecificWindowUiComponent>componentRef.instance).isModal = true;
        (<SpecificWindowUiComponent>componentRef.instance).formId = formId;
        (<SpecificWindowUiComponent>componentRef.instance).vcrwindow = this.vcrSpecific;
        (<SpecificWindowUiComponent>componentRef.instance).parentComponent = this;
        (<SpecificWindowUiComponent>componentRef.instance).sourceModal = this.specificModal;
        (<SpecificWindowUiComponent>componentRef.instance).index = this.vcrSpecific.length - 1;
        (<SpecificWindowUiComponent>componentRef.instance).sourceComponentData =
          sourceComponent && sourceComponent.itemData ? sourceComponent.itemData : sourceComponent;
        (<SpecificWindowUiComponent>componentRef.instance).sourceComponent =
          sourceComponent; /**dupliqué si pas d'itemdata mais trop de refactoring sur scout */
        (<SpecificWindowUiComponent>componentRef.instance).closeModalEmitter.subscribe(() =>
          this.updateModalDisplay({ key: 'displayFormUI', value: false, sourceComponent: this })
        );
      })
    );
  }
}
export interface ProcessParams {
  className: string;
  ad_process_id: number;
  tableName: string;
  record_id: number;
  params: any;
  tables: any[];
  ad_tab_id: number;
  windowCtx: any;
}
export interface ProcessStep {
  label: string;
  id: number;
}
